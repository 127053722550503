<template>
  <b-form-group :label="label" :label-for="data_value" :label-cols="3" :horizontal="true">
    <b-input-group>
      <b-form-input :list="data_value + '-list'" v-model="val" :id="data_value" v-on:input="ClickEnter($event, data, data_value, options)"></b-form-input>
      <b-input-group-append>
        <b-button variant="primary" @click="DeleteInput(data, data_value, data_value)"><i class="icon-trash"></i></b-button>
      </b-input-group-append>
      <b-form-datalist :id="data_value + '-list'">
        <option v-for="x, x_ind in options" :value="x.label" />
      </b-form-datalist>
    </b-input-group>
    <b-form-valid-feedback></b-form-valid-feedback>
    <b-form-invalid-feedback>Lütfen {{ label }} seçiniz</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'WisdomSelect',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {},
  created: function () {},
  props: {
    options: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    data_value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      val: ''
    };
  },
  methods: {
    DeleteInput (data, x, element_id) {
      this.val = '';
      data[x] = '';
    },
    ClickEnter: function (val, data, x, options) {
      if (val) {
        for (let i in options) {
          if (options[i].label === val) {
            data[x] = options[i];
            return;
          }
        }
      }
      data[x] = '';
    }
  }
};

</script>

