<template>
  <div>
    <b-card header-tag="header" footer-tag="footer" no-body key="nav-give_permission" style="margin-bottom: 0px;">
      <!-- {{ p_schemaGroupManagementList }} -->
      <br>
      <template v-for="(schema_group_list, schema_group_type) in p_schemaGroupManagementList">
        <ul>
          <li>
            {{ schema_group_type === 'wdm' ? 'WDM Şablon Grupları' : 'WDM Anatomi Şablon Grupları' }}
            <b-button @click="f_showAddEditSchemaGroup(schema_group_type)" variant="success" size="sm" style="margin-right: 3px;" title="Yeni Grup Ekle"> <i class="fa fa-plus"></i></b-button>
          </li>
          <template v-for="(schema_group, schema_group_ind) in schema_group_list.list">
            <ul>
              <li>
                <b-button :disabled="Object.keys(schema_group.list).length !== 0" @click="f_deleteUndeleteSchemaGroup(schema_group_type, schema_group_ind)" variant="danger" size="sm" style="margin-right: 3px;" title="Sil"> <i class="fa fa-trash"></i></b-button>
                <b-button @click="f_showAddEditSchemaGroup(schema_group_type, schema_group_ind)" variant="primary" size="sm" style="margin-right: 3px;" title="Ad Değiştir"> <i class="fa fa-pencil"></i></b-button>
                <span :style="f_calculateGroupStyle(schema_group)">{{ schema_group.label }}</span>
              </li>
            </ul>
          </template>
        </ul>
      </template>
    </b-card>
    :<b-modal title="$t('wdm16.13229')" centered class="modal-success" v-model="d_showNewSchemaGroup" @ok="d_showNewSchemaGroup = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="3">
          <strong>Grup Başlığı</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_addEditSchemaGroupTitle" v-model="d_addEditSchemaGroupTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_addEditSchemaGroupTitle !== ''" variant="success" @click="f_addNewSchemaGroup()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import GlobalService from '@/services/global';
import moment from 'moment';
import {
  default as Modal
} from '@/components/widgets/Modal';
import SchemaService from "@/services/schema";
import {
  default as WisdomDataModal
} from "@/components/widgets/WisdomDataModal";
// /* global $ */

export default {
  name: 'WdmSchemaGroupList',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang',
    help: 'help'
  }),
  props: {
    p_schema_group_owner: {
      type: String,
      required: true
    },
    p_wdm: {
      type: String,
      required: true
    },
    p_wdm_owner_type: {
      type: String,
      required: true
    },
    p_username: {
      type: String,
      required: true
    },
    p_hospital_group: {
      type: String,
      required: true
    },
    p_hospital_id: {
      type: String,
      required: true
    },
    p_schemaGroupManagementList: {
      type: Object,
      required: true,
      default: () => {}
    },
    p_schemaGroupKeyList: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getWdmAndAnatomySchemaGroups();
  },
  mounted () {},
  data () {
    return {
      user: {},
      d_addEditSchemaGroupType: '',
      d_addEditSchemaGroupIndex: '',
      d_addEditSchemaGroupTitle: '',
      d_showNewSchemaGroup: false
    };
  },
  methods: {
    f_calculateGroupStyle: function (schema_group) {
      if (!schema_group.status) {
        return "color: black; font-weight:bold;";
      } else {
        if (schema_group.status === 'new') {
          return "color: green; font-weight:bold;";
        } else if (schema_group.status === 'delete') {
          return "color: red; font-weight:bold;";
        }
      }
    },
    f_addNewSchemaGroup: function () {
      if (this.d_addEditSchemaGroupIndex === '') {
        this.p_schemaGroupManagementList[this.d_addEditSchemaGroupType].list.push({'list': {}, 'value': 'new', 'status': 'new', 'label': this.d_addEditSchemaGroupTitle});
      } else {
        this.p_schemaGroupManagementList[this.d_addEditSchemaGroupType].list[this.d_addEditSchemaGroupIndex].label = this.d_addEditSchemaGroupTitle;
      }
      this.d_showNewSchemaGroup = false;
    },
    f_showAddEditSchemaGroup: function (schema_group_type, schema_group_ind='') {
      this.d_addEditSchemaGroupIndex = schema_group_ind;
      this.d_addEditSchemaGroupType = schema_group_type;
      if (schema_group_ind !== '') {
        this.d_addEditSchemaGroupTitle = this.p_schemaGroupManagementList[schema_group_type].list[schema_group_ind].label;
      } else {
        this.d_addEditSchemaGroupTitle = '';
      }
      this.d_showNewSchemaGroup = true;
    },
    f_deleteUndeleteSchemaGroup: function (schema_group_type, schema_group_ind) {
      if (!this.p_schemaGroupManagementList[schema_group_type].list[schema_group_ind].status) {
        this.p_schemaGroupManagementList[schema_group_type].list[schema_group_ind].status = 'delete'
      } else {
        let status = this.p_schemaGroupManagementList[schema_group_type].list[schema_group_ind].status;
        let value = this.p_schemaGroupManagementList[schema_group_type].list[schema_group_ind].value;
        if (value === 'new') {
          if (status === 'new') {
            this.$delete(this.p_schemaGroupManagementList[schema_group_type].list, schema_group_ind);
          }
        } else {
          if (status === 'active') {
            this.p_schemaGroupManagementList[schema_group_type].list[schema_group_ind].status = 'delete';
          } else {
            this.p_schemaGroupManagementList[schema_group_type].list[schema_group_ind].status = 'active';
          }
        }
      }
      this.$forceUpdate();
    },
    f_getWdmAndAnatomySchemaGroups: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WDM ve Anatomi şablon grupları getiriliyor' } });
      // this.p_schemaGroupManagementList = { 'wdm': {}, 'wdm_anatomy': {} };
      this.p_schemaGroupKeyList.bucket = '';
      this.p_schemaGroupKeyList.wdm = '';
      this.p_schemaGroupKeyList.wdm_anatomy = '';
      let key_list = [];
      if (this.p_schema_group_owner === 'wisdom') {
        this.p_schemaGroupKeyList.wdm = 'schema_group_wisdom_wdm_' + this.p_wdm_owner_type + '_' + this.p_wdm;
        this.p_schemaGroupKeyList.wdm_anatomy = 'schema_group_wisdom_wdm_anatomy_' + this.p_wdm_owner_type + '_' + this.p_wdm;
        this.p_schemaGroupKeyList.bucket = 'option';
      } else if (this.p_schema_group_owner === 'user') {
        this.p_schemaGroupKeyList.wdm = 'schema_group_user_wdm_' + this.p_wdm_owner_type + '_' + this.p_wdm + '_' + this.p_username;
        this.p_schemaGroupKeyList.wdm_anatomy = 'schema_group_user_wdm_anatomy_' + this.p_wdm_owner_type + '_' + this.p_wdm + '_' + this.p_username;
        this.p_schemaGroupKeyList.bucket = 'hospital';
      } else if (this.p_schema_group_owner === 'hospital') {
        this.p_schemaGroupKeyList.wdm = 'schema_group_hospital_wdm_' + this.p_wdm_owner_type + '_' + this.p_wdm + '_' + this.p_hospital_group + '_' + this.p_hospital_id;
        this.p_schemaGroupKeyList.wdm_anatomy = 'schema_group_hospital_wdm_anatomy_' + this.p_wdm_owner_type + '_' + this.p_wdm + '_' + this.p_hospital_group + '_' + this.p_hospital_id;
        this.p_schemaGroupKeyList.bucket = 'hospital';
      }
      key_list.push(this.p_schemaGroupKeyList.wdm);
      key_list.push(this.p_schemaGroupKeyList.wdm_anatomy);
      GlobalService.get_multiple_cb_documents({ 'key_list': key_list, 'bucket': this.p_schemaGroupKeyList.bucket })
        .then(resp => {
          if (resp.data.status === 'success') {
            if (resp.data.result[this.p_schemaGroupKeyList.wdm]) {
              this.p_schemaGroupManagementList.wdm = JSON.parse(JSON.stringify(resp.data.result[this.p_schemaGroupKeyList.wdm]));
            }
            if (resp.data.result[this.p_schemaGroupKeyList.wdm_anatomy]) {
              this.p_schemaGroupManagementList.wdm_anatomy = JSON.parse(JSON.stringify(resp.data.result[this.p_schemaGroupKeyList.wdm_anatomy]));
            }
          }
          if (Object.keys(this.p_schemaGroupManagementList.wdm).length === 0) {
            this.p_schemaGroupManagementList.wdm.type = 'wdm_schema_group';
            this.p_schemaGroupManagementList.wdm.owner_type = this.p_schema_group_owner;
            this.p_schemaGroupManagementList.wdm.wisdom_data_model = { 'wdm': this.p_wdm, 'owner_type': this.p_wdm_owner_type, 'hospital_group': '', 'hospital_id': '' };
            if (this.p_schema_group_owner === 'hospital') {
              this.p_schemaGroupManagementList.wdm.wisdom_data_model.hospital_group = this.p_hospital_group;
              this.p_schemaGroupManagementList.wdm.wisdom_data_model.hospital_id = this.p_hospital_id;
            }
            if (this.p_schema_group_owner === 'wisdom') {
              this.p_schemaGroupManagementList.wdm.owner = 'wisdom';
            } else {
              this.p_schemaGroupManagementList.wdm.owner = this.p_username;
            }
            this.p_schemaGroupManagementList.wdm.list = [];
          if (Object.keys(this.p_schemaGroupManagementList.wdm_anatomy).length === 0) {
            this.p_schemaGroupManagementList.wdm_anatomy.type = 'wdm_anatomy_schema_group';
            this.p_schemaGroupManagementList.wdm_anatomy.owner_type = this.p_schema_group_owner;
            this.p_schemaGroupManagementList.wdm_anatomy.wisdom_data_model = { 'wdm': this.p_wdm, 'owner_type': this.p_wdm_owner_type, 'hospital_group': '', 'hospital_id': '' };
            if (this.p_schema_group_owner === 'hospital') {
              this.p_schemaGroupManagementList.wdm_anatomy.wisdom_data_model.hospital_group = this.p_hospital_group;
              this.p_schemaGroupManagementList.wdm_anatomy.wisdom_data_model.hospital_id = this.p_hospital_id;
            }
            if (this.p_schema_group_owner === 'wisdom') {
              this.p_schemaGroupManagementList.wdm_anatomy.owner = 'wisdom';
            } else {
              this.p_schemaGroupManagementList.wdm_anatomy.owner = this.p_username;
            }
            this.p_schemaGroupManagementList.wdm_anatomy.list = [];
          }
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.$forceUpdate();
        });
    }
  },
  watch: {},
  components: {
    Modal,
    vSelect,
    WisdomDataModal
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  }
};

</script>

<style></style>

