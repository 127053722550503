<template>
  <div style="padding: 10px; font-size: 20px;">
    <table class="table table-success">
      <tr>
        <td><b>Tarih</b></td>
        <td>
          <datepicker class="form-control" v-model="data.date"></datepicker>
        </td>
        <td></td>
      </tr>      
      <tr>
        <td><b>Kreatinin</b></td>
        <td>
          <cleave v-if="show_mgdl" type="input" class="input form-control" name="creatinin" :options="{ numeral: true, numeralDecimalMark: '.00', delimiter: '.' }" v-model="data.results.Crea"></cleave>
          <cleave v-else class="input form-control" name="creatinin_umol" :options="{ numeral: true, numeralDecimalMark: '.00', delimiter: '.' }" v-model="data.results.Crea_umol"></cleave>
        </td>
        <td>
          <button v-if='show_mgdl' type="button" class="btn btn-secondary" @click="show_mgdl = false">mg/dl</button>
          <button v-else type="button" class="btn btn-secondary" @click="show_mgdl = true">umol/L</button>
        </td>
      </tr>              
    </table>
  </div>
</template>


<script>
import Cleave from 'vue-cleave';
import Datepicker from 'wisdom-vue-datepicker';
import { mapGetters } from 'vuex';

export default {
  name: 'ChemotherapyLaboratory',
  mounted () {},
  computed: {
    ...mapGetters({
      patient: 'patient_selected'
    })
  },
  props: {
    data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      show_mgdl: true
    };
  },
  created () {},
  methods: {

  },
  watch: {
    data: { handler (newValue) { // bir obje içerisindeki değişimi watch ediyoruz.
    },
      deep: true
    }
  },
  components: {
    Cleave,
    Datepicker
  }
};
</script>

<style type="text/css">
.pain {
  width: 100%!important;
  height: 500px!important;
}
.ol-viewport {
  height: 500px!important;
}
#back {
  cursor:pointer;
  cursor:hand;
}
.back-button {
  top: 65px;
  left: .5em;
}
.ol-touch .back-button {
  top: 80px;
}
.home-button {
  top: 100px;
  left: .5em;
}
.ol-touch .home-button {
  top: 120px;
}
.imaging-review{
  margin: 5px;
  border-radius: 10px;
}
.imaging-review:hover{
  background-color: #3498db;
}
.wrapper {
  position: relative;
}
#progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: width 250ms;
} 
#wait-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: width 250ms;
}
.imaging_loading{
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 10%;
  left: 45%;
  color: white;
}
</style>
