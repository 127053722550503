<template>
  <div>
    <!-- Breadcrumb -->
    <div v-if="!device.isMobile && !is_treatment_list && patient.selected && treatment_data.selected_protocol && treatment_data.selected_protocol.drugs && treatment_data.selected_protocol.drugs.length > 0" style="position: fixed; z-index: 1000; top: 300px; right: 0px;">
      <b-button variant="success" @click="save_treatment()" title="Tedaviyi Kaydet"> <i class="fa fa-save"></i></b-button>
    </div>
    <b-card header-tag="header" footer-tag="footer">
      <div slot="footer" v-if="!is_treatment_list">
        <b-row>
          <b-col cols="2"></b-col>
          <b-col cols="4"></b-col>
          <b-col cols="6">
            <b-button v-if="patient.selected && treatment_data.selected_protocol && treatment_data.selected_protocol.drugs" class="pull-right" variant="primary" @click="save_treatment()"><i class="fa fa-save"></i>Tedaviyi kaydet</b-button>
          </b-col>
        </b-row>
      </div>
      <div v-if="patient.selected && !is_treatment_list">
        <b-nav tabs>
          <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_treatment-list-view') !== -1">
            <router-link :to="'/patient/chemotherapy'"> <strong> {{ $t('wdm16.4013') }} </strong> </router-link>
          </b-nav-item>
          <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_treatment-add-edit-view') !== -1" active>
            <router-link :to="'/patient/treatment/add_edit'" :exact="true">{{ $t('wdm16.4029') }} </router-link>
          </b-nav-item>
          <b-nav-item-dropdown text="Veri Ekle" class="ml-auto" v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_treatment-add-edit-view') !== -1" right>
            <b-dropdown-item @click="f_newWdm('diagnosis')">Tanı Ekle</b-dropdown-item>
            <b-dropdown-item @click="f_newWdm('medical_examination')">Muayene Bilgisi Ekle</b-dropdown-item>
            <b-dropdown-item @click="f_newWdm('laboratory')">Laboratuar Ekle</b-dropdown-item>
          </b-nav-item-dropdown>
          <!--           <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('patient_bmi-view') !== -1">
            <router-link :to="'/patient/treatment/bmi_data'" :exact="true"> {{ $t('wdm16.3819') }} </router-link>
          </b-nav-item> -->
          <!--           <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('patient_laboratory-view') !== -1">
            <router-link :to="'/patient/treatment/laboratory'" :exact="true"> {{ $t('wdm16.3912') }} </router-link>
          </b-nav-item>
 -->
        </b-nav>
      </div>
      <br>
      <b-row :style="is_treatment_list ? 'display: none' : ''" v-if="patient.selected">
        <b-col sm="12" md="12">
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '1')">?</b-badge>
          <bmi-gfr-widget :patient_data="patient_data" :bmi_gfr_change="bmi_gfr_change"></bmi-gfr-widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" id="treatment_new">
          <b-card class="card-accent-primary" header-tag="header" no-body>
            <b-card-header header-bg-variant="primary" class="p-1">
              {{ $t('wdm16.3864') }}
            </b-card-header>
            <div slot="footer" v-if="!is_treatment_list">
              <b-row>
                <b-col cols="2"></b-col>
                <b-col cols="4"></b-col>
                <b-col cols="6" v-if="patient.selected && treatment_data.selected_protocol && treatment_data.selected_protocol.drugs">
                  <div class="pull-right">
                    <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '2')">?</b-badge>
                    <b-button variant="primary" @click="save_treatment()">Tedaviyi kaydet</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- ---------------------------------------------------- -->
            <div>
              <b-row v-if="!is_treatment_list && patient.selected">
                <b-col cols="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '3')">?</b-badge>
                  Tanı
                  <template v-if="other_info_func('select_diagnosis')">
                    <b-badge style="font-size: 12px; margin: 3px; white-space: normal; color: #23282c;" id="diagnosis-info" variant="danger">
                      Uyarı
                    </b-badge>
                    <b-popover target="diagnosis-info" triggers="hover focus">
                      <template slot="title">Uyarı</template>
                      <b-row v-for="(info, info_ind) in pro_inf_list.others['select_diagnosis']" :key="'pro_inf_list.others_select_diagnosis_' + info_ind">
                        <b-col cols="3"> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </b-col>
                        <b-col cols="9"> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </b-col>
                      </b-row>
                    </b-popover>
                  </template>
                </b-col>
                <b-col cols="9">
                  <b-input-group>
                    <v-select style="width: 90%;" id="diagnosis" v-model="treatment_data.diagnosis" :options="diagnosis_list" required></v-select>
                    <b-input-group-append>
                      <b-button @click="f_newWdm('diagnosis')" variant="success"><i class="icon-plus"></i></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <!-- ---------------------------------------------------- -->
              <b-row>
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '4')">?</b-badge>
                  Protokol filtreleme alanı
                </b-col>
                <b-col sm="9" lg="9">
                  <b-container>
                    <b-row v-if="!protocol_edit_mode">
                      <b-col cols="12">
                        <b-form-group label="Protokol Türü" label-for="d_scopeType" :label-cols="3">
                          <b-form-select id="d_scopeType" :plain="true" v-model="d_scopeType">
                            <option value="my_protocols">Benim Protokollerim</option>
                            <!-- <option value="shared">Hastane Protokolleri</option> -->
                            <option value="public">WisdomEra Protokolleri</option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- <b-row v-if="!protocol_edit_mode">
                      <b-col sm="3" lg="3">
                        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '6')">?</b-badge>
                        Görünürlük
                      </b-col>
                      <b-col sm="9" lg="9">
                        <b-form-checkbox-group id="scope" name="scope" :plain="true">
                          <b-form-checkbox :plain="true" value="public" v-model="protocol_scope"><img src="../../../../static/img/favicon_mini.png"> Literatür (public)</b-form-checkbox>
                          <b-form-checkbox :plain="true" value="private" v-model="protocol_scope"><span class="icon-user" style="color: black; font-weight: bold;"></span> {{ $t('wdm16.3756') }}</b-form-checkbox>
                          <b-form-checkbox :plain="true" value="shared" v-model="protocol_scope"><span class="icon-people" style="color: blue; font-weight: bold;"></span> {{ $t('wdm16.3878') }}</b-form-checkbox>
                          <b-form-checkbox :plain="true" value="true" v-model="protocol_favorite"><span class="icon-heart" style="color: red; font-weight: bold;"></span> {{ $t('wdm16.3778') }}</b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-col>
                    </b-row> -->
                    <b-row v-if="!protocol_edit_mode">
                      <b-col sm="3" lg="3">
                        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '5')">?</b-badge>
                        Protokol filtreleme alanı
                      </b-col>
                      <b-col sm="9" lg="9">
                        <v-select name="protocol_group" ref="selected_group" v-model="treatment_data.protocol_group_select" :on-change="group_select" :options="protocol_group"></v-select>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="treatment_data.protocol_group_select && protocol_edit_mode">
                      <b-col sm="12" lg="12">
                        {{ treatment_data.protocol_group_select.label }}
                      </b-col>
                    </b-row>
                    <b-row v-if="!protocol_edit_mode">
                      <b-col sm="3" lg="3">
                        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '7')">?</b-badge>
                        ilaç1, ilaç2 şeklinde filtreleme
                      </b-col>
                      <b-col sm="9" lg="9">
                        <input type="text" class="form-control" name="search_protocol" v-model="search_protocol" placeholder="pak, sis şeklinde araya virgül konularak protokolleri filtreleyebilirsiniz.">
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
              <b-row v-if="!protocol_edit_mode">
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '8')">?</b-badge>
                  Protokol Seçimi
                </b-col>
                <b-col sm="9" lg="9">
                  <v-select name="protocol" v-model="protocol_selection" ref="protocol_selection" :on-change="protocol_select" :options="calculate_protocol_options()">
                    <template slot="option" slot-scope="option">
                      <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                        <!-- Herhangi bir protokol favorite ise mutlaka başında heart ikonu görünüyor. -->
                        <span v-if="option.scope === 'public'" style="float: left;"> <img src="../../../../static/img/favicon_mini.png">&nbsp </span>
                        <span v-else-if="option.scope === 'shared'" class="icon-people" style="color: blue; font-weight: bold; float: left;">&nbsp</span>
                        <span v-else class="icon-user" style="color: black; font-weight: bold; float: left;">&nbsp</span>
                        <!-- <span v-if="user.settings && user.settings.favorite_protocols.indexOf(option.value) !== -1" class="icon-heart" style="color: red; font-weight: bold;"></span> -->
                        <span> {{ option.label }} </span>
                      </div>
                    </template>
                  </v-select>
                </b-col>
              </b-row>
              <b-form-group v-if="protocol_edit_mode">
                {{ treatment_data.name }}
              </b-form-group>
              <b-row v-if="!is_treatment_list && patient.selected">
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '9')">?</b-badge>
                  Tedavi tipi
                  <template v-if="other_info_func('select_chemotherapy_type')">
                    <b-badge style="font-size: 12px; margin: 3px; white-space: normal; color: #23282c;" id="select_chemotherapy_type" variant="danger">
                      Uyarı
                    </b-badge>
                    <b-popover target="select_chemotherapy_type" triggers="hover focus">
                      <template slot="title">Uyarı</template>
                      <b-row v-for="(info, info_ind) in pro_inf_list.others['select_chemotherapy_type']" :key="'pro_inf_list.others_select_chemotherapy_type_' + info_ind">
                        <b-col cols="3"> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </b-col>
                        <b-col cols="9"> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </b-col>
                      </b-row>
                    </b-popover>
                  </template>
                </b-col>
                <b-col sm="9" lg="9">
                  <v-select id="reason" v-model="treatment_data.reason" :options="treatment_reasons"></v-select>
                </b-col>
              </b-row>
              <b-row v-if="!is_treatment_list && patient.selected">
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '10')">?</b-badge>
                  Tedavi ünitesi
                  <template v-if="other_info_func('select_therapy_unit')">
                    <b-badge style="font-size: 12px; margin: 3px; white-space: normal; color: #23282c;" id="select_therapy_unit" variant="danger">
                      Uyarı
                    </b-badge>
                    <b-popover target="select_therapy_unit" triggers="hover focus">
                      <template slot="title">Uyarı</template>
                      <b-row v-for="(info, info_ind) in pro_inf_list.others['select_therapy_unit']" :key="'pro_inf_list.others_select_therapy_unit_' + info_ind">
                        <b-col cols="3"> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </b-col>
                        <b-col cols="9"> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </b-col>
                      </b-row>
                    </b-popover>
                  </template>
                </b-col>
                <b-col sm="9" lg="9">
                  <v-select id="therapy_apply_unit" v-model="selected_therapy_unit" :options="therapy_apply_unit"></v-select>
                </b-col>
              </b-row>
              <b-row v-if="patient.selected">
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '11')">?</b-badge>
                  Başlama tarihi
                  <template v-if="other_info_func('select_treatment_starting_date')">
                    <b-badge style="font-size: 12px; margin: 3px; white-space: normal; color: #23282c;" id="select_treatment_starting_date" variant="danger">
                      Uyarı
                    </b-badge>
                    <b-popover target="select_treatment_starting_date" triggers="hover focus">
                      <template slot="title">Uyarı</template>
                      <b-row v-for="(info, info_ind) in pro_inf_list.others['select_treatment_starting_date']" :key="'pro_inf_list.others_select_treatment_starting_date_' + info_ind">
                        <b-col cols="3"> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </b-col>
                        <b-col cols="9"> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </b-col>
                      </b-row>
                    </b-popover>
                  </template>
                </b-col>
                <b-col sm="9" lg="9">
                  <b-form-input type="date" id="started_at" v-model="treatment_data.started_at"></b-form-input>
                </b-col>
              </b-row>
              <b-row v-if="patient.selected">
                <b-col sm="3" lg="3">
                  Kaçıncı Basamak Tedavi
                </b-col>
                <b-col sm="9" lg="9">
                  <v-select id="treatment_data.line" v-model="treatment_data.line" :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']"></v-select>
                </b-col>
              </b-row>
              <b-row v-if="!is_treatment_list && patient.selected">
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '12')">?</b-badge>
                  Bitiş tarihi
                </b-col>
                <b-col sm="9" lg="9">
                  {{ calculate_ending_date() }} {{ DateFormat(ending_date) }}
                </b-col>
              </b-row>
              <b-row v-if="!is_treatment_list && patient.selected">
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '13')">?</b-badge>
                  {{ $t('wdm16.3883') }}
                </b-col>
                <b-col sm="9" lg="9">
                  {{ total_days }} gün <span v-if="total_days">( {{ CalculateMonth(total_days) }} ) </span> {{ CalculateTotalDays() }}
                </b-col>
              </b-row>
              <b-row v-if="patient.selected">
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '14')">?</b-badge>
                  SUT analizi yap
                </b-col>
                <b-col sm="9" lg="9">
                  <input type="checkbox" v-model="analyze_with_insurance" name="analyze_with_insurance">
                </b-col>
              </b-row>
              <b-row v-if="!is_treatment_list && patient.selected">
                <b-col sm="3" lg="3">
                  <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '15')">?</b-badge>
                  Endikasyon dışı onay alındıktan sonra uygulanacaktır
                </b-col>
                <b-col sm="9" lg="9">
                  <input type="checkbox" v-model="indication_approval" name="indication_approval">
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="protocol_data && protocol_data.drugs">
        <b-col cols="12" id="treatment_new">
          <b-card class="card-accent-primary" v-if="!is_treatment_list && treatment_data.selected_protocol && treatment_data.selected_protocol.drugs" header-tag="header" no-body>
            <b-card-header header-bg-variant="primary" class="p-1">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '16')">?</b-badge>
              {{ $t('wdm16.3828') }}
            </b-card-header>
            <h5 style="color: green;">{{ treatment_data.name }}</h5>
          </b-card>
          <b-card class="card-accent-primary" no-body header-tag="header" v-if="patient.selected && treatment_data.selected_protocol && treatment_data.selected_protocol.drugs">
            <b-card-header header-bg-variant="primary" class="p-1">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '17')">?</b-badge>
              Protokol notlarım
            </b-card-header>
            <b-row>
              <b-col cols="12">
                <b-button variant="success" size="sm" icon @click="add_protocol_note()" title="Protokole yeni not satırı ekle"><i class="fa fa-plus-circle fa-1x"></i> Protokole not ekle </b-button>
              </b-col>
            </b-row>
            <b-col v-for="(note, note_ind) in treatment_data.notes" :key="'treatment_data.notes_' + note_ind">
              <table class="table">
                <tr>
                  <td style="width: 7%;">{{ DateFormat(note.date_at) }}</td>
                  <td style="width: 7%;">{{ note.user }}</td>
                  <td>
                    <textarea class="input form-control" name="protocol_name" v-model="note.note" placeholder="Name" style="width: 100%;" :placeholder="$t('wdm16.6157')"></textarea>
                  </td>
                  <td style="width: 7%;">
                    <div class="pull-right">
                      <b-button variant="success" size="sm" icon @click="delete_protocol_note(note_ind)" title="Remove this note from protocol">
                        <i class="fa fa-minus-circle fa-1x"></i>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-card>
          <selected-protocol :treatment_data="treatment_data" :protocol_data="treatment_data.selected_protocol" :pro_inf_list="pro_inf_list" :patient_data="patient_data" :mother_children="mother_children" :is_treatment_list="is_treatment_list"></selected-protocol>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="show_protocol_view" @close="show_protocol_view = false" large="true">
      <h3 slot="header">Protokol şeması</h3>
      <div slot="body">
        <protocol-viewer :data="treatment_data.protocol" :indication_approval="{}" :protocol_notes="[]" :insurance_report="{}" :insurance_data="{}" :patient_data="{}" :diagnosis="{}" :report_icon="'report'"></protocol-viewer>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="show_protocol_view = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="show_preview_protocol" @close="show_preview_protocol = false" large="true">
      <h3 slot="header">Protokol şeması</h3>
      <div slot="body">
        <protocol-viewer :data="treatment_data.selected_protocol" :indication_approval="{}" :protocol_notes="[]" :insurance_report="{}" :insurance_data="{}" :diagnosis="{}" :patient="'patient report'" :patient_data="{}"></protocol-viewer>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="show_preview_protocol = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="confirm_modal" @close="confirm_modal = false" large="true">
      <h3 slot="header"> Analiz ekranı </h3>
      <div slot="body">
        <confirm-form :data="confirm_data"></confirm-form>
      </div>
      <div slot="footer">
        <button v-if="confirm_data.permission" type="button" class="btn btn-success" @click="InsuranceAnalyze(confirm_data.func_name)">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="confirm_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <!-- sut sut sut sut sut sut sut sut  -->
    <modal v-if="show_insurance_modal" @close="show_insurance_modal = false" :width="'1000'">
      <h3 slot="header">SUT Analiz Sonuçları ve Kural Seçim Ekranı</h3>
      <div slot="body">
        <table class="table">
          <tr>
            <td> <b> İlaç </b></td>
            <td>
              <strong>{{ insurance_data_list.list[insurance_rule_index].label }}</strong>
              <i>[ Güncelleme Tarihi: {{ DateFormat(insurance_data_list.list[insurance_rule_index].date) }}]</i>
            </td>
          </tr>
          <tr>
            <td> <b> Kurallar </b></td>
            <td>
              <template v-for="(rule, rule_ind) in insurance_data_list.list[insurance_rule_index].data.general.rules.list">
                  <b-row style="padding: 5px; border-bottom: solid 1px #78b9aa; margin: 0px;">
                    <b-col sm="12" md="1">
                      {{ rule_ind + 1 }} )
                    </b-col>
                    <b-col sm="12" md="9">
                      <b-row>
                        <b-col sm="12" md="4"><strong>Bilgi: </strong></b-col>
                        <b-col sm="12" md="8">{{ rule.information.val }}</b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="4"><strong>Rapor Süresi: (ay) </strong></b-col>
                        <b-col sm="12" md="8">{{ rule.report_time_months.val }}</b-col>
                      </b-row>
                    </b-col>
                    <b-col sm="12" md="2">
                      <c-switch class="mx-1" color="success" v-model="insurance_data_list.list[insurance_rule_index].selected_rule" :value="rule_ind" variant="pill" />
                    </b-col>
                  </b-row>
              </template>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="pull-right">
                <div class="pagination">
                  <ul>
                    <li class="prev">
                      <button @click="back_next_more('back_one')">
                        < </button> </li> <li class="prev">
                          <button @click="back_next_more('next_one')"> > </button>
                    </li>
                    <li class="prev">
                      <h5 style="padding-top: 3px;"> {{ insurance_rule_index + 1 }} / {{ insurance_data_list.list.length }} </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="show_insurance_modal = false" style="margin-right: 10px;">{{ $t('wdm16.4053') }}</b-button>
        <b-button variant="success" @click="show_confirm_modal('treatment_add')" style="margin-right: 10px;">Tedaviyi Kaydet</b-button>
      </div>
    </modal>
    <!-- sut ...................................................  -->

    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'750'">
      <h3 slot="header"> {{ option_data[data_type].name.label ? option_data[data_type].name.label : data_type }} ekleme sayfası </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <!--         <modal v-if="show_protocol_builder" @close="show_protocol_builder = false" :width="'1000'">
            <h3 slot="header"> Protokol sisteme kaydetme ekranı </h3>
            <div slot="body">
              <protocol-builder :data="protocol_data" ref="confirm_form"></protocol-builder>
            </div>
            <div slot="footer">
                <button type="button" class="btn btn-success" v-on:click="show_confirm_modal('protocol_add')">{{ $t('wdm16.4061') }}</button>
                <button type="button" class="btn btn-danger" @click="show_protocol_builder = false">{{ $t('wdm16.4053') }}</button>
            </div>
        </modal> -->
    <modal v-if="show_protocol_new" @close="show_protocol_new = false" :width="'1500'">
      <h3 slot="header"> Protokol sisteme kaydetme ekranı </h3>
      <div slot="body">
        <protocol-new :hide_save_button="true" :from_add_edit="true" :mother_children="mother_children" :data="protocol_data" ref="confirm_form"></protocol-new>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" @click="$refs.confirm_form.show_confirm_modal('protocol_add')">Save</button>
        <button type="button" class="btn btn-danger" @click="show_protocol_new = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>
<script>
import { ClsSearch } from '@/services/public/search';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  default as router
} from '@/router';
import TreatmentService from '@/services/treatment';
import InsuranceService from '@/services/insurance';
import GlobalService from '@/services/global';
import ProtocolService from '@/services/public/protocol';
import { Switch as cSwitch } from '@coreui/vue'
import {
  default as BmiGfrWidget
} from '@/components/widgets/BmiGfrWidget';
import {
  default as SelectedProtocol
} from '@/components/widgets/SelectedProtocol';
// import {
//   default as ProtocolNew
// } from '@/views/user/chemotherapy/protocol_new';
// import BmiService from '@/services/physicalexam_bmi';
// import LaboratoryService from '@/services/laboratory';
import {
  default as BmiForm
} from '@/components/widgets/BmiForm';
import {
  default as TreatmentAddEditDrug
} from '@/components/widgets/TreatmentAddEditDrug';
import {
  default as ChemotherapyLaboratory
} from '@/components/widgets/ChemotherapyLaboratory';
import {
  default as ConfirmForm
} from '@/components/widgets/ConfirmForm';
import WisdomDataService from '@/services/wisdom_data';
// import DiagnosisService from '@/services/diagnosis';

import { DateFunctions } from '@/services/public/date_functions';
import { DosageCalculator, GeneralFunc } from '@/services/public/functions';

import Datepicker from 'wisdom-vue-datepicker';
import { tr } from '../../../../node_modules/flatpickr/dist/l10n/tr';
import {
  default as ProtocolViewer
} from '@/components/widgets/ProtocolViewer';
import {
  default as LabEditForm
} from '@/components/widgets/LabEditForm';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as WisdomSelect
} from '@/components/widgets/WisdomSelect';
import {
  default as Modal
} from '@/components/widgets/Modal';
import vSelect from 'vue-select';
import moment from 'moment';
import MedicineService from '@/services/medicines';
import OptionService from '@/services/option';
import store from '@/store';

export default {
  name: 'patient_treatment',
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      lang: 'lang',
      device: 'device',
      help: 'help'
    })
  },
  props: {
    is_new_protocol: {
      type: Boolean,
      required: false
    },
    new_protocol_change: {
      type: Boolean,
      required: false
    },
    is_treatment_list: {
      type: Boolean,
      required: false
    },
    add_edit_treatment_data: {
      type: Object,
      required: false
    },
    selected_protocol_for_protocol_new: {
      type: Object,
      required: false
    },
    external_patient_data: {
      type: Object,
      required: false
    },
    add_edit_drug_canbe_saved: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      store: store,
      select_options: { protocol_selected: '' },
      GeneralFunc: GeneralFunc,
      mother_children: { new_protocol: false, add_edit_drug: 0 },
      show_protocol_new: false,
      protocol_data: { scope: '', name: '', protocol_group_list: [], drugs: [] },
      bmi_gfr_change: { status: false },
      datepicker_langs: { tr: tr, en: {} },
      data_type: '',
      search_protocol: '',
      detailed_view: true,
      real_protocol_data: {},
      protocol_selection: '',
      page_mounting: 0,
      creatinin_in_system: false,
      dosage_adjustment_percent: 0,
      indication_approval: false,
      ending_date: '',
      total_days: 0,
      analyze_with_insurance: true,
      insurance_analyzing: 'analyze_with_insurance',
      selected_therapy_unit: { label: 'Kemoterapi ünitesi', value: 'chemotherapy_unit' },
      therapy_apply_unit: [
        { label: 'Ayaktan tedavi', value: 'outpatient_unit' },
        { label: 'Kemoterapi ünitesi', value: 'chemotherapy_unit' },
        { label: 'Yatarak tedavi ünitesi', value: 'inpatient_unit' }
      ],
      insurance_analyze_only: false,
      insurance_rule_index: 0,
      insurance_data: [],
      insurance_data_list: { 'list': [], 'wdm_list': {} },
      show_insurance_modal: false,
      show_preview_protocol: false,
      confirm_data: { func_name: '', info: [], permission: true },
      confirm_modal: false,
      types: ['before', 'after', 'during'],
      show_mgdl: true,
      new_drug: {},
      pro_inf_list: { others: {}, drugs: {} },
      search_all_drugs: false,
      calculation_data: {},
      treatment_name: '',
      unit_options: require('@/options/unit').options,
      dosage_form_options: require('@/options/dosage_form').options,
      frequency: require('@/options/frequency').options,
      laboratory_tests: require('@/options/laboratory').options,
      favorite_drugs: require('@/options/favorite_drugs').options,
      fluid_options: require('@/options/fluids').options,
      info_options: require('@/options/info').options,
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      options: [],
      computed_name: '',
      count: 0,
      approvals: { 'doctor': { 'status': 'waiting for doctor approval', 'data': [] }, 'nurse': { 'status': 'waiting for medication request', 'data': [] }, 'pharmacy': { 'status': 'waiting for pharmacy approval', 'data': [] }, 'preparation': { 'status': 'waiting for medication preparation', 'data': [] }, 'match': { 'status': 'waiting for correct matching of the patient and the drug', 'data': [] }, 'administration': { 'status': 'Medication is ready and waiting for administration', 'data': [] }, 'status': 'no_operation_yet', 'drug_permission': 'doctor' },
      selected_drugs: [],
      show_cm: true,
      show_kg: true,
      group_control: false,
      protocol_group: require('@/options/protocol_group').options,
      treatment_reasons: require('@/options/treatment_reason').options,
      protocol_options: [],
      protocol_scope: ['shared', 'public', 'private'],
      protocol_favorite: 0,
      selected_group: false,
      selected_protocol: false,
      show_protocol_view: false,
      show_bmi_modal: false,
      show_laboratory_modal: false,
      columns: ['name', 'date', 'state', 'process'],
      treatment_data: { 'started_at': '', 'name': '', 'selected_weight_for_gfr': '', 'selected_weight': '', 'selected_gfr_formule': '', 'selected_protocol': {}, 'protocol_cycles': { 'drugs': {} }, 'last_status': 'no_operation_yet' },
      treatment_template: { 'started_at': '', 'protocol_id': false, 'protocol_name': false, 'drugs': [], 'application_days': [] },
      patient_data: {},
      laboratory_data: { 'results': {} },
      size_mode: 0,
      protocol_group_select: false,
      diagnosis_data: {},
      show_yeni_bmi_modal: false,
      user: {},
      protocol_edit_mode: false,
      wisdom_data: {},
      show_wisdom_data_modal: false,
      option_data: {},
      hospitalData: {},
      diagnosis_list: [],
      d_myProtocolList: [],
      d_scopeType: 'public'
    };
  },
  beforeCreate: function() {},
  created: function() {
    if (!this.is_treatment_list) {
      this.diagnosis_list = JSON.parse(JSON.stringify(this.patient.diagnosis));
    }
    if (router.currentRoute.query && router.currentRoute.query.t_uuid) {
      this.group_control = true;
      this.protocol_edit_mode = true;
      TreatmentService.get_treatment(this.patient.id, router.currentRoute.query.t_uuid)
        .then(resp => {
          this.treatment_data = Object.assign({}, this.treatment_data, resp.data);
          // this.create_selected_protocol();
          this.real_protocol_data = JSON.parse(JSON.stringify(this.treatment_data.selected_protocol));
          this.calculate_info_list_new('all_drugs');
          for (let i in this.therapy_apply_unit) {
            if (this.treatment_data.therapy_unit === this.therapy_apply_unit[i].value) {
              this.selected_therapy_unit = this.therapy_apply_unit[i];
            }
          }
        });
    } else {
      if (!this.treatment_data.diagnosis) {
        if (this.diagnosis_list.length > 0) {
          this.treatment_data.diagnosis = this.diagnosis_list[0];
        }
      }
    }
    this.treatment_data.started_at = moment(new Date().toISOString()).format('YYYY-MM-DD');
    let user = localStorage.getItem('user');
    this.user = JSON.parse(user);
    this.hospitalData = JSON.parse(localStorage.getItem('hospital_data'));
    let user_list = [];
    user_list.push(this.user.username);
    // this.get_user_favorite_protocols(user_list, 'active_user');
    this.options = this.favorite_drugs;
    if (this.external_patient_data) {
      this.patient_data = JSON.parse(JSON.stringify(this.external_patient_data))
    } else {
      this.patient_data = JSON.parse(JSON.stringify(this.patient));
    }
    ProtocolService.get_my_protocol_list()
      .then(resp => {
        if (resp.data.status === "success") {
          this.d_myProtocolList = resp.data.result;
          if (this.d_myProtocolList.length > 0 && !this.protocol_edit_mode) {
            this.d_scopeType = 'my_protocols';
          }
        }
      });
  },
  mounted: function () {
    // console.log(this.$options);
    this.$options.sockets.onmessage = function (message) {
      let socket_data = JSON.parse(message.data);
      if (socket_data.type && ['patient_diagnosis_list'].indexOf(socket_data.type) !== -1) {
        if (this.patient.selected && this.patient.id && this.patient.id === socket_data.patient_id) {
          store.commit('patient_diagnosis_list', JSON.parse(JSON.stringify(socket_data.data)));
          this.diagnosis_list = JSON.parse(JSON.stringify(socket_data.data));
        }
      }
    };
    this.protocol_options = JSON.parse(localStorage.getItem('chemotherapy_protocols'));
    this.route_rule_func();
    if (this.is_new_protocol) {
      this.treatment_data.selected_protocol = this.selected_protocol_for_protocol_new;
      this.calculate_info_list_new('all_drugs');
    }

  },
  methods: {
    DeleteInput (data, x, element_id) {
      // console.log(document.getElementById(element_id).value);
      // console.log('x', x);
      this.$delete(data, x);
      document.getElementById('diagnosis-list').value = '';
      document.getElementById('diagnosis-with-list').value = '';
      data[x] = '';
      this.$forceUpdate();
    },
    ClickEnter: function (val, data, x, options) {
      if (val) {
        for (let i in options) {
          if (options[i].label === val) {
            data[x] = options[i];
            return;
          }
        }
      }
      data[x] = '';
    },
    create_selected_protocol: function () {
      let data = {};
      let medicine_data = {};
      data.patient_id = this.patient.id;
      MedicineService.search(data)
        .then(resp => {
          let drug_list = [];
          for (let i in resp.data.data) {
            if (drug_list.indexOf(resp.data.data[i].drug.value) === -1) {
              drug_list.push(resp.data.data[i].drug.value);
            }
          }
          for (let k in drug_list) {
            for (let i in resp.data.data) {
              if (drug_list[k] === resp.data.data[i].drug.value) {
                if (this.treatment_data.id && resp.data.data[i].chemotherapy_id && (resp.data.data[i].chemotherapy_id === this.treatment_data.id)) {
                  if (medicine_data[drug_list[k]] === undefined) {
                    medicine_data[drug_list[k]] = [];
                  }
                  medicine_data[drug_list[k]].push(resp.data.data[i]);
                }
              }
            }
          }
          let x = 0;
          for (let drug_key in this.treatment_data.protocol_cycles.drugs) {
            if (this.treatment_data.protocol_cycles.drugs[drug_key].cycles[0]) {
              if (this.treatment_data.protocol_cycles.drugs[drug_key].cycles[0].days[0]) {
                x += 1;
                let drug = {};
                let day_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[0].days[0];
                drug.after = [];
                drug.before = [];
                drug.calculated_dosage = day_data.data.calculated_dosage;
                drug.day = [];
                drug.delay = this.treatment_data.protocol_cycles.drugs[drug_key].delay;
                drug.dosage = day_data.data.dosage;
                drug.dosage_form = day_data.data.dosage_form;
                drug.drug = this.treatment_data.protocol_cycles.drugs[drug_key].drug;
                drug.drug_mix = [];
                drug.drug_note = day_data.data.note_list.length > 0 ? day_data.data.note_list[0] : [];
                drug.drug_order = x;
                drug.duration = day_data.data.duration;
                drug.duration_wait = day_data.data.duration_wait;
                drug.during = [];
                drug.fluid = day_data.data.fluid;
                drug.fluid_value = day_data.data.fluid_value;
                drug.frequency = day_data.data.frequency;
                drug.meal_relation = day_data.data.meal_relation ? day_data.data.meal_relation : '';
                drug.model = this.treatment_data.protocol_cycles.drugs[drug_key].type === 'radiotherapy' ? 'radiotherapy' : 'chemotherapy';
                drug.period = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[0].period;
                drug.planned_dosage = day_data.data.planned_dosage;
                drug.premedications = { laboratory: { list: [] }, message_to_patient: { list: [] }, note: { list: [] } };
                drug.recipe_data = {};
                drug.repeat = this.treatment_data.protocol_cycles.drugs[drug_key].cycles.length;
                drug.type = this.treatment_data.protocol_cycles.drugs[drug_key].type;
                drug.unit = day_data.data.unit;
                for (let day_index in this.treatment_data.protocol_cycles.drugs[drug_key].cycles[0].days) {
                  drug.day.push(this.treatment_data.protocol_cycles.drugs[drug_key].cycles[0].days[day_index].day);
                }
                let prem_types = ['before', 'after', 'during'];
                for (let i in prem_types) {
                  for (let prem in day_data.data[prem_types[i]]) {
                    let prem_drug = {};
                    // console.log(prem_drug);
                    // console.log(prem);
                  }
                }
              }
            }
          }
          if (medicine_data && Object.keys(medicine_data).length > 0) {
            for (let drug_key in medicine_data) {
              for (let drug_index in medicine_data[drug_key]) {
                if (medicine_data[drug_key][drug_index].cycles.length > 0) {

                } else {

                }
              }
            }
          }
        });
    },
    mother_children_add_edit: function () {
      if (this.mother_children.add_edit_drug === 0) {
        this.mother_children.add_edit_drug = 1;
      } else {
        this.mother_children.add_edit_drug = 0;
      }
    },
    save_treatment: function () {
      this.mother_children_add_edit();
      this.confirm_data_func('treatment_add');
    },
    prepare_new_protocol: function () {
      let drug_list = JSON.parse(JSON.stringify(this.treatment_data.selected_protocol.drugs));
      for (let i in drug_list) {
        this.$delete(drug_list[i], 'calculated_dosage');
      }
      this.protocol_data.drugs = drug_list;
      this.protocol_data.abbr_name = '';
      this.protocol_data.owner_type = 'user';
      this.protocol_data.owner = { 'value': this.user.username, 'label': this.user.username };
      this.protocol_data.name = this.treatment_name;
      this.show_protocol_new = true;
    },
    route_rule_func: function () {
      if (this.$route.query.rule_engine && this.$route.params && this.$route.params.chemo_data && this.$route.params.chemo_data.general) {
        if (this.$route.params.chemo_data.general.reason) {
          let reason = this.$route.params.chemo_data.general.reason;
          reason.value = reason.label;
          this.$delete(reason, 'label');
          this.treatment_data.reason = reason;
        }
        if (this.$route.params.chemo_data.general.therapy_unit) {
          let therapy_unit = this.$route.params.chemo_data.general.therapy_unit;
          therapy_unit.value = therapy_unit.label;
          this.$delete(therapy_unit, 'label');
          this.selected_therapy_unit = therapy_unit;
        }
        if (this.$route.params.chemo_data.general.select_protocol) {
          let selected_pro = this.$route.params.chemo_data.general.select_protocol;
          this.protocol_selection = selected_pro;
          this.protocol_select(this.protocol_selection);
        }
      }
    },
    calculate_protocol_options: function () {
      let protocol_list = [];
      if (this.search_protocol) {
        protocol_list = JSON.parse(JSON.stringify(this.protocol_options));
        let split_search = this.search_protocol.split(',');
        for (let i in split_search) {
          split_search[i] = split_search[i].replace(/ /g, "");
          protocol_list = ClsSearch.list(split_search[i], protocol_list, protocol_list, ['label']);
        }
      } else {
        protocol_list = JSON.parse(JSON.stringify(this.protocol_options));
      }
      // for (let i in this.protocol_options) {
      //   if (this.search_with_text(this.protocol_options[i].label)) {
      //     this.protocol_options[i].label = this.protocol_options[i].label.replace('i̇', 'i');
      //     protocol_list.push(this.protocol_options[i]);
      //   }
      // }
      return protocol_list;
    },
    search_with_text: function (protocol_name) {
      // protocol_name = protocol_name.toLowerCase().replace('i̇', 'i');
      if (this.search_protocol) {
        this.search_protocol = this.search_protocol.replace(' ', '');
        if (this.search_protocol.indexOf(',') !== -1) {
          let search_list = this.search_protocol.split(',');
          let x = 0;
          for (let i in search_list) {
            if (protocol_name.toLocaleLowerCase('TR').indexOf(search_list[i].toLocaleLowerCase('TR')) !== -1) {
              x += 1;
            }
          }
          if (x === search_list.length) {
            return true;
          }
        } else {
          if (protocol_name.toLocaleLowerCase('TR').indexOf(this.search_protocol.toLocaleLowerCase('TR')) !== -1) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    get_user_favorite_protocols: function (user_list, which_users) {
      ProtocolService.get_favorite_protocols(user_list)
        .then(resp => {
          if (which_users === 'active_user' && resp.data.result) {
            if (resp.data.result[this.user.username].favorite_protocols) {
              this.user.settings.favorite_protocols = resp.data.result[this.user.username].favorite_protocols;
            }
            this.$forceUpdate();
          }
        });
    },
    ControlCreatininRecord: function () {
      for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
        if (this.treatment_data.selected_protocol.drugs[drg_ind].unit) {
          if (this.treatment_data.selected_protocol.drugs[drg_ind].unit.value === 'auc' && this.patient_data.last_laboratory['2'] === undefined) {
            return true;
          }
        }
      }
      return false;
    },
    ApplyDosageChange: function () {
      for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
        if (this.treatment_data.selected_protocol.drugs[drg_ind].type === 'protocol_premedication') {
          continue;
        }
        if (this.treatment_data.selected_protocol.drugs[drg_ind].main_dosage === undefined) {
          this.treatment_data.selected_protocol.drugs[drg_ind].main_dosage = this.treatment_data.selected_protocol.drugs[drg_ind].dosage;
        }
        let dosage = this.treatment_data.selected_protocol.drugs[drg_ind].main_dosage;
        let dose_calc = parseFloat(dosage) * (100 + parseInt(this.dosage_adjustment_percent)) / 100;
        if (dose_calc > 10) {
          this.treatment_data.selected_protocol.drugs[drg_ind].dosage = dose_calc.toFixed(0);
        } else {
          this.treatment_data.selected_protocol.drugs[drg_ind].dosage = dose_calc;
        }
      }
      this.calculate_all_dosages();
      this.$forceUpdate();
    },
    ResetDosageChange: function () {
      this.dosage_adjustment_percent = 0;
      for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
        if (this.treatment_data.selected_protocol.drugs[drg_ind].main_dosage) {
          this.treatment_data.selected_protocol.drugs[drg_ind].dosage = this.treatment_data.selected_protocol.drugs[drg_ind].main_dosage;
        }
      }
      this.calculate_all_dosages();
      this.$forceUpdate();
    },
    ChangeDosage: function (percent) {
      this.dosage_adjustment_percent = percent;
      this.$forceUpdate();
    },
    record_type_of_repeats_analyze: function () {
      let data = this.treatment_data.selected_protocol.drugs;
      for (let i in data) {
        if (data[i].model !== 'recipe') {
          continue;
        }
        if (data[i].recipe_data.status === undefined) {
          data[i].recipe_data = { is_scattered_model: false, scattered_day_dosage_model: [], cycles: [], record_type_of_repeats: 'one_record', status: 'continuing', patient: {}, data_structure: 'continuously', operation_type: 'new_drug_prescribed', started_at: '', ending_at: '' };
        }
        if (!data[i].recipe_data.record_type_of_repeats) {
          data[i].recipe_data.record_type_of_repeats = 'one_record';
        }
        if (data[i].repeat === '') {
          data[i].recipe_data.record_type_of_repeats = 'one_record';
        }
        if (data[i].recipe_data.record_type_of_repeats === 'new_cycles' && !data[i].recipe_data.is_scattered_model) {
          data[i].recipe_data.cycles = [];
          for (let k = 0; k < parseInt(data[i].repeat); k++) {
            data[i].recipe_data.cycles.push({ day: data[i].day, started_at: '', ending_at: '', dosage: data[i].dosage, unit: data[i].unit, calculated_dosage: data[i].calculated_dosage, planned_dosage: data[i].planned_dosage, patient: [], frequency: data[i].frequency, period: data[i].period });
          }
        } else if (data[i].recipe_data.record_type_of_repeats === 'new_cycles' && data[i].recipe_data.is_scattered_model) {
          for (let k = 0; k < parseInt(data.repeat); k++) {
            data[i].recipe_data.cycles.push({ scattered_day_dosage_model: data[i].recipe_data.scattered_day_dosage_model });
          }
        } else {
          data[i].recipe_data.cycles = [];
        }
      }
      this.$forceUpdate();
      this.data_structure_analyze();
    },
    /*    CalculateRecipeEndingDate: function (drg_ind) {
          let data = this.treatment_data.selected_protocol.drugs[drg_ind];
          console.log('data.recipe_data.ending_at');
          this.data_structure_analyze();
          if (data.recipe_data.data_structure === 'ending_without_cycles') {
            if (data.recipe_data.ending_at) {
              let days = DateFunctions.day_dif(this.treatment_data.started_at, data.recipe_data.ending_at);
              data.repeat = Math.floor(days / parseInt(data.period));
            }
          }
        }, */
    ChangeDrugModel: function (drg_ind) {
      let drg_data = this.treatment_data.selected_protocol.drugs[drg_ind];
      if (drg_data.model === 'recipe') {
        drg_data.recipe_data = { is_scattered_model: false, scattered_day_dosage_model: [], cycles: [], record_type_of_repeats: 'one_record', status: 'continuing', patient: {}, data_structure: 'continuously', operation_type: 'new_drug_prescribed', started_at: '', ending_at: '' };
        this.data_structure_analyze();
      } else {
        this.$delete(drg_data, 'recipe_data');
      }
      this.$forceUpdate();
    },
    data_structure_analyze: function () {
      let data = this.treatment_data.selected_protocol.drugs;
      for (let i in data) {
        if (data[i].model === 'recipe') {
          if (data[i].repeat && data[i].recipe_data.cycles.length === 0 && data[i].recipe_data.scattered_day_dosage_model.length === 0) {
            data[i].recipe_data.data_structure = 'ending_without_cycles';
          } else if (data[i].recipe_data.cycles.length > 0 && data[i].recipe_data.scattered_day_dosage_model.length === 0) {
            data[i].recipe_data.data_structure = 'ending_with_cycles';
          } else if (data[i].recipe_data.scattered_day_dosage_model.length > 0 && data[i].recipe_data.cycles.length === 0) {
            data[i].recipe_data.data_structure = 'ending_with_scattered';
          } else if (data[i].recipe_data.scattered_day_dosage_model.length > 0 && data[i].recipe_data.cycles.length > 0) {
            data[i].recipe_data.data_structure = 'ending_with_scattered_cycles';
          } else if ((parseInt(data[i].repeat) === 0 || data[i].repeat === '') && data[i].recipe_data.ending_at === '' && data[i].recipe_data.scattered_day_dosage_model.length === 0) {
            data[i].recipe_data.data_structure = 'continuously';
          }
          this.calculate_recipe_dates();
        }
      }
    },
    calculate_recipe_dates: function () {
      if (!this.treatment_data.started_at) {
        return;
      }
      let drg_data = this.treatment_data.selected_protocol.drugs;
      for (let i in drg_data) {
        if (drg_data[i].model === 'recipe') {
          if (drg_data[i].recipe_data.data_structure === 'ending_with_cycles') {
            for (let k = 0; k < drg_data[i].recipe_data.cycles.length; k++) {
              let calc_date = new Date(this.treatment_data.started_at);
              calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].delay));
              drg_data[i].recipe_data.started_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
              if (k === 0) {
                drg_data[i].recipe_data.cycles[0].started_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
                calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].period));
                drg_data[i].recipe_data.cycles[0].ending_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
                if (k === drg_data[i].recipe_data.cycles.length - 1) {
                  drg_data[i].recipe_data.ending_at = drg_data[i].recipe_data.cycles[k].ending_at;
                }
              } else {
                calc_date.setDate(calc_date.getDate() + (parseInt(drg_data[i].period) * k));
                drg_data[i].recipe_data.cycles[k].started_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
                calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].period));
                drg_data[i].recipe_data.cycles[k].ending_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
                if (k === drg_data[i].recipe_data.cycles.length - 1) {
                  drg_data[i].recipe_data.ending_at = drg_data[i].recipe_data.cycles[k].ending_at;
                }
              }
            }
          } else if (this.treatment_data.started_at && (drg_data[i].recipe_data.data_structure === 'continuously' || drg_data[i].recipe_data.data_structure === 'ending_without_cycles')) {
            let calc_date = new Date(this.treatment_data.started_at);
            calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].delay));
            drg_data[i].recipe_data.started_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
            if (drg_data[i].repeat) {
              let period = parseInt(drg_data[i].period);
              calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].repeat) * period);
              drg_data[i].recipe_data.ending_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
            } else {
              drg_data[i].recipe_data.ending_at = '';
            }
          }
        }
      }
      this.$forceUpdate();
    },
    CalculateMonth: function (total_days) {
      if (total_days % 30 === 0) {
        return total_days / 30 + ' ay';
      } else {
        return Math.round(total_days / 30) + ' ay + ' + (total_days % 30) + ' gün';
      }
    },
    calculate_ending_date: function () {
      if (!this.treatment_data.started_at) {
        return;
      }
      let calc_date = '';
      let ending_at = '';
      for (let i in this.treatment_data.selected_protocol.drugs) {
        if (this.treatment_data.selected_protocol.drugs[i].model === 'recipe' && this.treatment_data.selected_protocol.drugs[i].recipe_data.ending_at) {
          if (ending_at === '') {
            ending_at = this.treatment_data.selected_protocol.drugs[i].recipe_data.ending_at;
          } else {
            if (DateFunctions.day_dif(this.treatment_data.selected_protocol.drugs[i].recipe_data.ending_at, ending_at) < 0) {
              ending_at = this.treatment_data.selected_protocol.drugs[i].recipe_data.ending_at;
            }
          }
        }
      }
      for (let i in this.treatment_data.protocol_cycles.drugs) {
        if (this.treatment_data.protocol_cycles.drugs[i].cycles.length !== 0) {
          let data = this.treatment_data.protocol_cycles.drugs[i].cycles;
          calc_date = this.treatment_data.protocol_cycles.drugs[i].cycles[data.length - 1].days[data[data.length - 1].days.length - 1].date_started;
        }
        if (ending_at === '') {
          ending_at = calc_date;
        } else {
          if (DateFunctions.day_dif(calc_date, ending_at) < 0) {
            ending_at = calc_date;
          }
        }
      }
      this.ending_date = ending_at;
    },
    CalculateTotalDays: function () {
      if (this.treatment_data.started_at && this.ending_date) {
        this.total_days = DateFunctions.day_dif(this.treatment_data.started_at, this.ending_date);
      }
    },
    back_next_more: function (val) {
      if (val === 'next_one' && this.insurance_rule_index !== this.insurance_data_list.list.length - 1) {
        this.insurance_rule_index += 1;
      } else if (val === 'back_one' && this.insurance_rule_index !== 0) {
        this.insurance_rule_index -= 1;
      }
    },
    InsuranceAnalyze_old: function (func_name) {
      this.confirm_modal = false;
      if (this.analyze_with_insurance) {
        this.insurance_analyze_only = false;
        this.insurance_data = [];
        let data = { drug_list: [] };
        for (let drg in this.treatment_data.selected_protocol.drugs) {
          if (this.treatment_data.selected_protocol.drugs[drg].drug && data.drug_list.indexOf(this.treatment_data.selected_protocol.drugs[drg].drug.value) === -1) {
            data.drug_list.push(this.treatment_data.selected_protocol.drugs[drg].drug.value);
          }
        }
        if (data.drug_list.length > 0) {
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaçlara ait SUT analizi yapılıyor.' } });
          InsuranceService.analyze_insurance(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              if (resp.data.data.results.length > 0) {
                this.insurance_data = resp.data.data.results;
                for (let i in this.insurance_data) {
                  this.insurance_data[i].selected_other_requirements = [];
                }
                this.show_insurance_modal = true;
              } else {
                this.Confirm_Functions('treatment_add');
              }
            });
        }
      } else {
        this.Confirm_Functions('treatment_add');
      }
    },
    InsuranceAnalyze: function (func_name) {
      this.confirm_modal = false;
      if (this.analyze_with_insurance) {
        this.insurance_data_list = { 'list': [], 'wdm_list': {} };
        this.insurance_analyze_only = false;
        let data = { 'bucket': 'option', 'key_list': [] };
        for (let drg in this.treatment_data.selected_protocol.drugs) {
          if (this.treatment_data.selected_protocol.drugs[drg].drug && data.key_list.indexOf('wdm15_related_with_wdm2_' + this.treatment_data.selected_protocol.drugs[drg].drug.value) === -1) {
            data.key_list.push('wdm15_related_with_wdm2_' + this.treatment_data.selected_protocol.drugs[drg].drug.value);
          }
        }
        if (data.key_list.length > 0) {
          GlobalService.get_cb_documents_with_key_list(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                if (resp.data.result.list.length > 0) {
                  this.insurance_data_list = resp.data.result;
                  this.show_insurance_modal = true;
                } else {
                  this.Confirm_Functions('treatment_add');
                }
              } else {
                this.Confirm_Functions('treatment_add');
              }
            });
        }
      } else {
        this.Confirm_Functions('treatment_add');
      }
    },
    Confirm_Functions: function (func_name) {
      if (func_name === 'treatment_add') {
        let periods = [];
        let str = '';
        for (let i in this.treatment_data.selected_protocol.drugs) {
          str += this.treatment_data.selected_protocol.drugs[i].drug.label + ' (' + this.treatment_data.selected_protocol.drugs[i].dosage + ') Period:' + this.treatment_data.selected_protocol.drugs[i].period + '\n';
          if (periods.indexOf(this.treatment_data.selected_protocol.drugs[i].period) === -1) {
            periods.push(this.treatment_data.selected_protocol.drugs[i].period);
          }
        }
        if (periods.length === 1) {
          this.complete_treatment_save();
        } else if (periods.length > 1) {
          if (confirm('İlaçlarınızın periyotları birbirinden farklı olarak tespit edilmiştir.\n' + str + 'Kayıt işlemine devam etmek istiyor musunuz?')) {
            this.complete_treatment_save();
          }
        }
      }
    },
    complete_treatment_save: function () {
      this.calculate_cycles();
      this.record_type_of_repeats_analyze();
      if (this.is_treatment_list) {
        this.add_edit_drug_canbe_saved.value = true;
        return;
      }
      this.treatment_data.therapy_unit = this.selected_therapy_unit.value;
      this.treatment_data.protocol_note = this.treatment_data.selected_protocol.protocol_note;
      if (this.indication_approval) {
        this.treatment_data.indication_approval = { status: 'yes', started_at: '', ending_at: '', note: '' };
      } else {
        this.treatment_data.indication_approval = { status: 'no', started_at: '', ending_at: '', note: '' };
      }

      this.treatment_data.selected_weight_for_gfr = this.selected_weight_for_gfr;
      this.treatment_data.selected_weight = this.selected_weight;
      this.treatment_data.selected_gfr_formule = this.selected_gfr_formule;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tedavi kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      TreatmentService.save(this.treatment_data)
        .then(resp => {
          // this.patient.chemo_list.push({'id': resp.data.result['id'], 'name': resp.data.result['name'], 'status': resp.data.result['status'], 'last_status': resp.data.result['last_status'], 'started_at': resp.data.result['started_at'], 'created_by': resp.data.result['created_by']});
          // store.commit('patient_treatment_list', JSON.parse(JSON.stringify(this.patient.chemo_list)));
          // this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          router.push({ path: '/patient/chemotherapy', query: { patient_id: this.patient.id } });
        });
    },
    show_confirm_modal: function (func_name) {
      if (this.analyze_with_insurance) {
        if (this.insurance_data_list.list.length > 0) {
          for (let i in this.insurance_data_list.list) {
            console.log(this.insurance_data_list.list[i].selected_rule);
            if (this.insurance_data_list.list[i].selected_rule === false || this.insurance_data_list.list[i].selected_rule === undefined) {
              alert('Lütfen sut işlemlerini tamamlayınız');
              return;
            }
          }
          // analiz tamamlandı ve tüm ilaçlar için sut uygun halde dolduruldu ise.
          for (let i in this.insurance_data_list.list) {
            let rule_ind = this.insurance_data_list.list[i].selected_rule;
            this.treatment_data.insurance_data[this.insurance_data_list.list[i].data.general.drug.val.value] = {
              'rules': this.insurance_data_list.list[i].data.general.rules.list[rule_ind].information.val
            };
          }
          if (this.is_treatment_list) {
            this.add_edit_treatment_data.insurance_data = this.treatment_data.insurance_data;
          }
        }
        this.show_insurance_modal = false;
        this.Confirm_Functions('treatment_add');
      }
    },
    confirm_data_func: function (func_name) {
      this.confirm_data.info = [];
      this.confirm_data.func_name = func_name;
      if (func_name === 'treatment_add') { // ilgili her bir fonksiyon için yaptığımız analizleri burayda info içine koyup gönderiyoruz.
        let det = false;
        let data = this.pro_inf_list.drugs;
        for (let drg_ind in data) {
          for (let inf_ind in this.info_options) {
            if (data[drg_ind].info_list[inf_ind] !== undefined) {
              for (let inf_type in this.info_options[inf_ind]) {
                if (data[drg_ind].info_list[inf_ind][inf_type] !== undefined) {
                  let validation = data[drg_ind].info_list[inf_ind][inf_type].validation;
                  let info = this.$t('wdm16.' + this.variable_langs_id_list.info[data[drg_ind].info_list[inf_ind][inf_type].info]);
                  let drg_name = this.$t('wdm16.4101');
                  if (this.treatment_data.selected_protocol.drugs[drg_ind].drug) {
                    drg_name = this.treatment_data.selected_protocol.drugs[drg_ind].drug.label;
                  }
                  this.confirm_data.info.push('[ ' + this.$t('wdm16.' + this.variable_langs_id_list.info[validation]) + ' ] ' + this.$t('wdm16.4257') + '= "' + drg_name + '"; ' + ' (' + info + ')');
                  if (validation === 'required') {
                    det = true;
                  }
                }
              }
            }
            for (let i in this.types) {
              for (let pr_ind in data[drg_ind][this.types[i]]) {
                let prem_data = data[drg_ind][this.types[i]][pr_ind];
                if (prem_data.info_list[inf_ind] !== undefined) {
                  for (let inf_type in this.info_options[inf_ind]) {
                    if (prem_data.info_list[inf_ind][inf_type] !== undefined) {
                      let validation = prem_data.info_list[inf_ind][inf_type].validation;
                      let info = this.$t('wdm16.' + this.variable_langs_id_list.info[prem_data.info_list[inf_ind][inf_type].info]);
                      let drg_name = this.$t('wdm16.4101');
                      if (this.treatment_data.selected_protocol.drugs[drg_ind][this.types[i]][pr_ind].drug) {
                        drg_name = this.treatment_data.selected_protocol.drugs[drg_ind][this.types[i]][pr_ind].drug.label;
                      }
                      this.confirm_data.info.push('[ ' + this.$t('wdm16.' + this.variable_langs_id_list.info[validation]) + ' ] ' + this.$t('wdm16.4257') + '= "' + drg_name + '"; ' + ' (' + info + ')');
                      if (validation === 'required') {
                        det = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        let data_others = this.pro_inf_list.others;
        for (let inf_type in data_others) {
          for (let inf_ind in data_others[inf_type]) {
            let validation = data_others[inf_type][inf_ind].validation;
            let info = this.$t('wdm16.' + this.variable_langs_id_list.info[data_others[inf_type][inf_ind].info]);
            if (this.treatment_data.selected_protocol.id && inf_type === 'select_protocol_group') { // protokol düzenleme modunda grup sormayacağız.
            } else {
              this.confirm_data.info.push('[ ' + this.$t('wdm16.' + this.variable_langs_id_list.info[validation]) + ' ] ' + ' (' + info + ')');
            }
            if (validation === 'required') {
              det = true;
            }
          }
        }
        if (this.user.settings && this.user.settings.oncologyModule && this.user.settings.oncologyModule.use === '1') {
          if (this.user.settings.oncologyModule.settings.drugApprovalDosage && this.user.settings.oncologyModule.settings.drugApprovalDosage.use === '1') {
            for (let i in this.treatment_data.selected_protocol.drugs) {
              if (this.treatment_data.selected_protocol.drugs[i].planned_dosage) {
                let drug_value = this.treatment_data.selected_protocol.drugs[i].drug.value;
                if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value]) {
                  if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max && this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.use === '1') {
                    if (parseFloat(this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.val) < parseFloat(this.treatment_data.selected_protocol.drugs[i].planned_dosage)) {
                      this.confirm_data.info.push(this.treatment_data.selected_protocol.drugs[i].drug.label + ' => Maximum dikkat değeri = ' + this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.val + ' | Belirlediğiniz değer = ' + this.treatment_data.selected_protocol.drugs[i].planned_dosage);
                    }
                  }
                  if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min && this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min.use === '1') {
                    if (parseFloat(this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min.val) > parseFloat(this.treatment_data.selected_protocol.drugs[i].planned_dosage)) {
                      this.confirm_data.info.push(this.treatment_data.selected_protocol.drugs[i].drug.label + ' => Minimum dikkat değeri = ' + this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min.val + ' | Belirlediğiniz değer = ' + this.treatment_data.selected_protocol.drugs[i].planned_dosage);
                    }
                  }
                }
              }
            }
          }
        }
        if (this.confirm_data.info.length === 0) {
          this.confirm_data.info.push(this.$t('wdm16.6230'));
        }
        if (det === true) {
          this.confirm_data.permission = false; // yani istediğimiz zaman save butonunu aktifliğini bu şekilde pasif yapacağız.
        } else {
          this.confirm_data.permission = true; // yani istediğimiz zaman save butonunu aktifliğini bu şekilde pasif yapacağız.
        }
      }
      this.confirm_modal = true;
    },
    other_info_func: function (subject) {
      let res = false;
      if (subject === 'input_protocol_name') {
        if (this.treatment_data.name.length === 0) {
          res = true;
        }
      } else if (subject === 'select_diagnosis') {
        if (!this.treatment_data.diagnosis) {
          res = true;
        }
      } else if (subject === 'select_chemotherapy_type') {
        if (!this.treatment_data.reason) {
          res = true;
        }
      } else if (subject === 'select_therapy_unit') {
        if (!this.selected_therapy_unit) {
          res = true;
        }
      } else if (subject === 'select_treatment_starting_date') {
        if (!this.treatment_data.started_at) {
          res = true;
        }
      } else if (subject === 'select_protocol_visibility') {
        if (this.protocol_scope.length === 0) {
          res = true;
        }
      }
      if (res === true) {
        this.pro_inf_list.others[subject] = this.info_options[subject];
      } else {
        this.$delete(this.pro_inf_list.others, subject);
      }
      return res;
    },
    calculate_info_list_new: function (subject, drg_ind = 'none', prem_ind = 'none', mix_ind = 'none', drug_type = 'none') {
      if (subject === 'all_drugs') {
        this.pro_inf_list = { others: {}, drugs: {} };
        for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
          this.calculate_drug_info(drg_ind);
        }
      } else if (subject === 'new_drug') {
        this.calculate_drug_info(drg_ind);
      } else if (subject === 'delete_drug') {
        this.$delete(this.pro_inf_list.drugs, drg_ind);
      } else if (subject === 'add_premedication') {
        let pr_ind = '';
        if (Object.keys(this.pro_inf_list.drugs[drg_ind][drug_type]).length === 0) {
          pr_ind = 0;
        } else {
          pr_ind = Object.keys(this.pro_inf_list.drugs[drg_ind][drug_type]).length;
        }
        this.pro_inf_list.drugs[drg_ind][drug_type][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
      } else if (subject === 'add_mix') {
        if (drug_type === 'drug') {
          this.pro_inf_list.drugs[drg_ind].drug_mix[mix_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
        } else {
          this.pro_inf_list.drugs[drg_ind][drug_type][prem_ind].drug_mix[mix_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
        }
      } else if (subject === 'drug_go_up') {
        let arr = this.pro_inf_list.drugs[drg_ind];
        this.pro_inf_list.drugs[drg_ind] = this.pro_inf_list.drugs[drg_ind - 1];
        this.pro_inf_list.drugs[drg_ind - 1] = arr;
      } else if (subject === 'drug_go_down') {
        let arr = this.pro_inf_list.drugs[drg_ind];
        this.pro_inf_list.drugs[drg_ind] = this.pro_inf_list.drugs[drg_ind + 1];
        this.pro_inf_list.drugs[drg_ind + 1] = arr;
      }
    },
    calculate_drug_info: function (drg_ind) {
      let data = this.treatment_data.selected_protocol.drugs[drg_ind];
      this.pro_inf_list.drugs[drg_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_drug_days: {}, input_period: {}, input_repeat: {}, input_delay: {}, select_the_drug: {}, select_note_drug_days: {}, select_lab_drug_days: {}, select_msg_drug_days: {}, select_note_days: {}, select_msg_days: {}, select_lab_days: {}, select_lab_tests: {}, text_note_drug: {} }, before: {}, after: {}, during: {}, drug_mix: {} };
      for (let mx in data.drug_mix) {
        this.pro_inf_list.drugs[drg_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_unit: {}, select_the_drug: {} } };
      }
      for (let pr in this.types) {
        for (let pr_ind in data[this.types[pr]]) {
          this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
          for (let mx in data[this.types[pr]][pr_ind].drug_mix) {
            this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
          }
        }
      }
    },
    delete_protocol_note: function (note_ind) {
      if (confirm('Do you want to remove this protocol note?')) {
        this.treatment_data.notes.splice(note_ind, 1);
        this.$forceUpdate();
      }
    },
    add_protocol_note: function (note_ind) {
      if (confirm('Do you want to add a new protocol note?')) {
        let today = new Date();
        this.treatment_data.notes.push({ user: this.user.username, note_type: 'general', note: '', date_at: moment(today).toISOString() });
        this.$forceUpdate();
      }
    },
    info_func: function (info_drg_ind, prem_ind, type, mix_ind, subject, other_ind = 'none') {
      let res = false;
      if (subject === 'input_dosage') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].dosage;
            if (dosage_second < this.info_options[subject].min.value) {
              data.info_list[subject].min = this.info_options[subject].min;
              res = true;
            } else if (data.info_list[subject].min !== undefined) { this.$delete(data.info_list[subject], 'min'); }
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_planned_dosage') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].planned_dosage;
            if (dosage_second < this.info_options[subject].min.value) {
              data.info_list[subject].min = this.info_options[subject].min;
              res = true;
            } else if (data.info_list[subject].min !== undefined) { this.$delete(data.info_list[subject], 'min'); }
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].planned_dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].planned_dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].planned_dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].planned_dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].planned_dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_volume') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let volume_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].fluid_value;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let volume_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].fluid_value;
              let percent = Math.abs((volume_first - volume_second) * 100 / volume_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = volume_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let volume_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].fluid_value;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let volume_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].fluid_value;
              let percent = Math.abs((volume_first - volume_second) * 100 / volume_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = volume_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_duration') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let duration_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].duration;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].duration;
              let percent = Math.abs((duration_first - duration_second) * 100 / duration_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let duration_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].duration;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].duration;
              let percent = Math.abs((duration_first - duration_second) * 100 / duration_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'select_unit') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'select_frequency') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].frequency === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].frequency === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].frequency === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].frequency === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'select_administration') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'input_duration_wait') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let duration_wait_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].duration_wait;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_wait_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].duration_wait;
              let percent = Math.abs((duration_wait_first - duration_wait_second) * 100 / duration_wait_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let duration_wait_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].duration_wait;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_wait_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].duration_wait;
              let percent = Math.abs((duration_wait_first - duration_wait_second) * 100 / duration_wait_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let duration_wait_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].duration_wait;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_wait_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].duration_wait;
              let percent = Math.abs((duration_wait_first - duration_wait_second) * 100 / duration_wait_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let duration_wait_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].duration_wait;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_wait_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].duration_wait;
              let percent = Math.abs((duration_wait_first - duration_wait_second) * 100 / duration_wait_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'select_prem_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_days.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'select_prem_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].day.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'select_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].day.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'input_period') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let period_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].period;
        if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki period farkını hesaplamaya gerek yok.
          let period_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].period;
          let percent = Math.abs((period_first - period_second) * 100 / period_first);
          if (percent >= this.info_options[subject].bef_aft.value) {
            data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
            res = true;
          } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
        }
        let period_chars = period_second.toString().split('');
        let det = false;
        for (let i in period_chars) {
          if (this.info_options[subject].chars.value.indexOf(period_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < period_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'input_repeat') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let repeat_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].repeat;
        if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki repeat farkını hesaplamaya gerek yok.
          let repeat_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].repeat;
          let percent = Math.abs((repeat_first - repeat_second) * 100 / repeat_first);
          if (percent >= this.info_options[subject].bef_aft.value) {
            data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
            res = true;
          } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
        }
        let repeat_chars = repeat_second.toString().split('');
        let det = false;
        for (let i in repeat_chars) {
          if (this.info_options[subject].chars.value.indexOf(repeat_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < repeat_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'input_delay') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let delay_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].delay;
        if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki delay farkını hesaplamaya gerek yok.
          let delay_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].delay;
          let percent = Math.abs((delay_first - delay_second) * 100 / delay_first);
          if (percent >= this.info_options[subject].bef_aft.value) {
            data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
            res = true;
          } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
        }
        let delay_chars = delay_second.toString().split('');
        let det = false;
        for (let i in delay_chars) {
          if (this.info_options[subject].chars.value.indexOf(delay_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < delay_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'select_the_drug') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            this.pro_inf_list.drugs[info_drg_ind].info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          } else {
            this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind].info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          } else {
            this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          }
        }
      } else if (subject === 'select_note_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.note.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_msg_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.laboratory.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_note_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.note.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_msg_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.laboratory.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_tests') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.laboratory.list[other_ind].tests.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'text_note_drug') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.note.list[other_ind].note === undefined || this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.note.list[other_ind].note === '') {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'text_msg_drug') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].message === undefined || this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].message === '') {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'calculated_dosage') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].calculated_dosage;
            if (dosage_second < this.info_options[subject].min.value) {
              data.info_list[subject].min = this.info_options[subject].min;
              res = true;
            } else if (data.info_list[subject].min !== undefined) { this.$delete(data.info_list[subject], 'min'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].calculated_dosage;
            if (dosage_second < this.info_options[subject].min.value) {
              data.info_list[subject].min = this.info_options[subject].min;
              res = true;
            } else if (data.info_list[subject].min !== undefined) { this.$delete(data.info_list[subject], 'min'); }
          }
        }
      }
      return res;
    },
    go_patient_protocols: function () {
      router.push({ path: '/patient/chemotherapy' });
    },
    go_new: function () {
      router.push({ path: '/user/settings/protocol/new_chemo' });
    },
    delete_this_premedication (drg_ind, ind, type) {
      this.treatment_data.selected_protocol.drugs[drg_ind].premedications[type].list.splice(ind, 1);
      this.$forceUpdate();
    },
    add_prem_note (drg_ind) {
      this.treatment_data.selected_protocol.drugs[drg_ind].premedications.note.list.push({ type: 'general', day: [], drug_days: [], note: '' });
      this.$forceUpdate();
    },
    add_prem_lab (drg_ind) {
      this.treatment_data.selected_protocol.drugs[drg_ind].premedications.laboratory.list.push({ type: 'general', day: [], drug_days: [], note: '', tests: [] });
      this.$forceUpdate();
    },
    add_prem_msg (drg_ind) {
      this.treatment_data.selected_protocol.drugs[drg_ind].premedications.message_to_patient.list.push({ type: 'general', day: [], drug_days: [], send_languages: [], message: '' });
      this.$forceUpdate();
    },
    add_drug: function () {
      let per = 21;
      let rep = 1;
      let days = [1];
      this.treatment_data.protocol_cycles.drugs = {};
      let x = this.treatment_data.selected_protocol.drugs.length;
      if (x !== 0) {
        per = this.treatment_data.selected_protocol.drugs[x - 1].period;
        rep = this.treatment_data.selected_protocol.drugs[x - 1].repeat;
        days = this.treatment_data.selected_protocol.drugs[x - 1].day;
      }
      let new_drug = { recipe_data: {}, model: 'chemotherapy', drug: '', dosage: '', planned_dosage: '', delay: 0, unit: { value: 'mg_m2', caption: 'mg/m²' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'İntravenöz', value: '12' }, fluid: '', type: 'drug', fluid_value: 0, duration: 0, duration_wait: 0, repeat: rep, drug_note: '', drug_mix: [], drug_order: 1, day: days, before: [], during: [], after: [], period: per, is_daily: 0, premedications: { laboratory: { list: [] }, note: { list: [] }, message_to_patient: { list: [] } } };
      this.treatment_data.selected_protocol.drugs.push(new_drug);
      this.calculate_info_list_new('new_drug', (this.treatment_data.selected_protocol.drugs.length - 1));
    },
    add_premedication (drg_ind, type) {
      let i = this.treatment_data.selected_protocol.drugs[drg_ind][type].length - 1;
      let drg_ord = 0;
      if (type && drg_ind !== undefined) {
        if (this.treatment_data.selected_protocol.drugs[drg_ind][type][i] === undefined) {
          drg_ord = 1;
        } else {
          drg_ord = parseInt(this.treatment_data.selected_protocol.drugs[drg_ind][type][i]['drug_order']) + 1;
        }
      }
      let prem = { day: [], minutes_drug_relation: [], drug_note: '', drug_days: [], drug_mix: [], unit: { value: 'mg', caption: 'mg' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'İntravenöz', value: '12' }, duration_wait: 0, drug_order: drg_ord, dosage: 0, planned_dosage: 0, fluid_value: 0, fluid: '', duration: 0, drug: '' };
      if (type === 'during') {
        prem.day = [0];
      }
      this.treatment_data.selected_protocol.drugs[drg_ind][type].push(prem);
      this.calculate_info_list_new('add_premedication', drg_ind, 'none', 'none', type);
    },
    add_mix (drg_ind, prem_ind, type) {
      let mix = { drug_note: '', unit: { value: 'mg', caption: 'mg' }, dosage: 0, planned_dosage: 0, drug: '' };
      let mix_ind = '';
      if (type === 'drug') {
        this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix.push(mix);
        mix_ind = this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix.length - 1;
      } else {
        this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix.push(mix);
        mix_ind = this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix.length - 1;
      }
      this.calculate_info_list_new('add_mix', drg_ind, prem_ind, mix_ind, type);
      this.$forceUpdate();
    },
    add_drug_type: function (type, drug_id) {
      let i = this.treatment_data.selected_protocol.drugs[drug_id][type].length - 1;
      if (type && drug_id !== undefined) {
        if (this.treatment_data.selected_protocol.drugs[drug_id][type][i] === undefined) {
          this.treatment_data.selected_protocol.drugs[drug_id][type].push({ drug_order: 1 });
        } else {
          let last_order = this.treatment_data.selected_protocol.drugs[drug_id][type][i]['drug_order'];
          this.treatment_data.selected_protocol.drugs[drug_id][type].push({ drug_order: ++last_order });
        }
      }
    },
    pop_drugs_type: function (type, drug_id, id) {
      if (type !== 'main') {
        this.treatment_data.selected_protocol.drugs[drug_id][type].splice(id, 1);
      } else {
        this.treatment_data.selected_protocol.drugs.splice(drug_id, 1);
      }
    },
    validateBeforeSubmit () {
      return this.$validator.validateAll().then((result) => {
        return { 'result': result };
      }).catch(() => {
        return { 'result': false };
      });
    },
    calculate_name: function () {
      let str = '';
      for (let i in this.treatment_data.selected_protocol.drugs) {
        let data = this.treatment_data.selected_protocol.drugs;
        if (data[i].type !== 'protocol_premedication') {
          if (data[i].drug && data[i].day && data[i].period && data[i].repeat && data[i].dosage && data[i].unit !== null) {
            str = str + data[i].drug.label + ' (' + data[i].dosage + data[i].unit.label + ' | days:' + data[i].day + ' | p:' + data[i].period + ' | r:' + data[i].repeat + ')';
          }
        }
        if (parseInt(i) !== this.treatment_data.selected_protocol.drugs.length - 1 && str !== '') {
          str = str + ', ';
        }
      }
      this.treatment_data.name = str;
    },
    drug_go_up (drg_ind) { // this drug_index comes from protocol_data.drugs
      if (drg_ind !== 0) {
        let arr = [];
        arr = this.treatment_data.selected_protocol.drugs[drg_ind];
        this.treatment_data.selected_protocol.drugs[drg_ind] = this.treatment_data.selected_protocol.drugs[drg_ind - 1];
        this.treatment_data.selected_protocol.drugs[drg_ind - 1] = arr;
        this.calculate_info_list_new('drug_go_up', drg_ind);
        this.$forceUpdate();
      }
    },
    drug_go_down (drg_ind) { // this drug_index comes from protocol_data.drugs
      if (drg_ind !== this.treatment_data.selected_protocol.drugs.length - 1) {
        let arr = [];
        arr = this.treatment_data.selected_protocol.drugs[drg_ind];
        this.treatment_data.selected_protocol.drugs[drg_ind] = this.treatment_data.selected_protocol.drugs[drg_ind + 1];
        this.treatment_data.selected_protocol.drugs[drg_ind + 1] = arr;
        this.calculate_info_list_new('drug_go_down', drg_ind);
        this.$forceUpdate();
      }
    },
    delete_test_in_this_drug (d_index, lab_ind, test_ind) {
      this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].tests.splice(test_ind, 1);
    },
    add_test_to_drug (d_index, lab_ind) {
      let value = document.getElementById('select-drug-lab_' + d_index + '_' + lab_ind).value;
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].tests.indexOf(value) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].tests.push(value);
      }
    },
    delete_day_msg (d_index, msg_ind, dy_ind) {
      this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list[msg_ind].day.splice(dy_ind, 1);
    },
    add_day_to_this_drug_day_lab (d_index, lab_ind, day) {
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.indexOf(day) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.push(day);
      }
    },
    delete_minutes_drug_relation_drug_lab (d_index, lab_ind, min_ind) {
      this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_related.splice(min_ind, 1);
    },
    add_time_to_drug_lab_minutes_drug_relation (d_index, lab_ind) {
      let value = parseInt(document.getElementById('minutes_drug_relation-lab-drug_' + d_index + '_' + lab_ind).value);
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_related.indexOf(value) === -1 && Number.isInteger(parseInt(value))) {
        this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_related.push(value);
      }
    },
    add_time_to_prem_minutes_drug_relation (d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('minutes_drug_relation-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      if (this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.indexOf(value) === -1 && Number.isInteger(parseInt(value))) {
        this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.push(value);
      }
    },
    delete_minutes_drug_relation_prem (d_index, prem_ind, min_ind, prem_type) {
      this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.splice(min_ind, 1);
    },
    delete_drug_day_prem (d_index, prem_ind, prem_drug_day_ind, prem_type) {
      this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].drug_days.splice(prem_drug_day_ind, 1);
    },
    add_day_to_this_drug_day_prem (d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('select-drug-day-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      if (this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].drug_days.indexOf(value) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].drug_days.push(value);
      }
    },
    delete_drug_day (d_index, dy_ind) {
      this.treatment_data.selected_protocol.drugs[d_index].day.splice(dy_ind, 1);
    },
    delete_day_prem (d_index, prem_ind, dy_ind, prem_type) {
      this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].day.splice(dy_ind, 1);
    },
    add_day_to_this_drug_prem (d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('select-day-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].day = [];
      this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].day.push(value);
    },
    add_day_to_this_drug: function (d_index) {
      let value = parseInt(document.getElementById('select-drug-day_' + d_index).value);
      if (this.treatment_data.selected_protocol.drugs[d_index].day.indexOf(value) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].day.push(value);
      }
    },
    protocol_add_lab_after_day: function (lab_index) {
      let value = parseInt(document.getElementById('select-protocol-lab-day-after' + '_' + lab_index).value);
      if (this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.indexOf(value) === -1) {
        this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.push(value);
      }
    },
    protocol_add_lab_before_day: function (lab_index) {
      let value = parseInt(document.getElementById('select-protocol-lab-day-before' + '_' + lab_index).value);
      value = 0 - value;
      if (this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.indexOf(value) === -1) {
        this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.push(value);
      }
    },
    protocol_add_lab_initial_day: function (lab_index) {
      if (this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.indexOf(1) === -1) {
        this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.push(1);
      }
    },
    lab_add_to_protocol: function (lab_index) {
      let lab_value = document.getElementById('select-protocol-lab' + '_' + lab_index).value;
      if (this.treatment_data.protocol_premedications.laboratory.list[lab_index].tests.indexOf(lab_value) === -1) {
        this.treatment_data.protocol_premedications.laboratory.list[lab_index].tests.push(lab_value);
      }
    },
    use_this_title: function () {
      this.treatment_data.name = this.treatment_name;
      this.$forceUpdate();
    },
    reset_protocol_data: function () {
      this.dosage_adjustment_percent = 0;
      this.treatment_data.selected_protocol = JSON.parse(JSON.stringify(this.real_protocol_data));
      this.calculate_all_dosages();
      this.calculate_ending_date();
      this.ControlCreatininRecord();
      this.record_type_of_repeats_analyze();
      this.calculate_info_list_new('all_drugs');
    },
    delete_drug_from_protocol (drg_ind) { // this drug_index comes from treatment_data.selected_protocol.drugs
      if (confirm('Do you want to remove this drug from protocol?')) {
        this.treatment_data.selected_protocol.drugs.splice(drg_ind, 1);
        this.calculate_info_list_new('delete_drug', drg_ind);
        this.treatment_data.protocol_cycles = { drugs: {}, drug_order: [] };
        this.$forceUpdate();
      }
    },
    delete_day_note (d_index, nt_ind, dy_ind) {
      this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list[nt_ind].day.splice(dy_ind, 1);
    },
    delete_day_lab (d_index, lab_ind, dy_ind) {
      this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].day.splice(dy_ind, 1);
    },
    select_timing: function (d_index, pr_ind, type) {
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].type !== 'before' && this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].type !== 'after') {
        this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].day = [];
        this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].day.push(0);
      }
    },
    add_day_to_this_drug_extra (d_index, pr_ind, type, abrv) {
      let value = parseInt(document.getElementById('select-day-' + abrv + '_' + d_index + '_' + pr_ind).value);
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].day.indexOf(value) === -1) {
        if (this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].type === 'before' || this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].type === 'after') {
          this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].day.push(value);
        } else {
          if (value === 0) {
            this.treatment_data.selected_protocol.drugs[d_index].premedications[type].list[pr_ind].day.push(value);
          }
        }
      }
    },
    add_day_to_this_drug_msg (d_index, msg_ind) {
      let value = parseInt(document.getElementById('select-day-msg_' + d_index + '_' + msg_ind).value);
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list[msg_ind].day.indexOf(value) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list[msg_ind].day.push(value);
      }
    },
    add_day_to_this_drug_note (d_index, nt_ind) {
      let value = parseInt(document.getElementById('select-day-nt_' + d_index + '_' + nt_ind).value);
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list[nt_ind].day.indexOf(value) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list[nt_ind].day.push(value);
      }
    },
    add_day_to_this_drug_lab (d_index, lab_ind) {
      let value = parseInt(document.getElementById('select-day-lab_' + d_index + '_' + lab_ind).value);
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].day.indexOf(value) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].day.push(value);
      }
    },
    delete_drug_day_msg (d_index, msg_ind, day) {
      let value = this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.indexOf(day);
      this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.splice(value, 1);
      this.$forceUpdate();
    },
    delete_drug_day_note (d_index, nt_ind, nt_drug_day) {
      let day_ind = this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list[nt_ind].drug_days.indexOf(nt_drug_day);
      this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list[nt_ind].drug_days.splice(day_ind, 1);
    },
    delete_drug_day_lab (d_index, lab_ind, day) {
      let value = this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.indexOf(day);
      this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.splice(value, 1);
    },
    add_day_to_this_drug_day_msg (d_index, msg_ind, day) {
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.indexOf(day) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.push(day);
      }
    },
    add_day_to_this_drug_day_note (d_index, nt_ind, day) {
      if (this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list[nt_ind].drug_days.indexOf(day) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list[nt_ind].drug_days.push(day);
      }
    },
    dosage_calculate: function (drg_ind, prem_ind, mix_ind, dosage, unit, type) {
      this.$forceUpdate();
      if (!unit) {
        return;
      }
      let dose = 0;
      let calculated_dosage = 0;
      if (type === 'drug') {
        if (mix_ind === 'none') {
          dose = this.treatment_data.selected_protocol.drugs[drg_ind].dosage;
          if (dose) {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind].calculated_dosage = calculated_dosage;
            this.treatment_data.selected_protocol.drugs[drg_ind].planned_dosage = calculated_dosage;
          } else {
            this.treatment_data.selected_protocol.drugs[drg_ind].calculated_dosage = '';
            this.treatment_data.selected_protocol.drugs[drg_ind].planned_dosage = '';
          }
        } else {
          dose = this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].dosage;
          if (dose) {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].calculated_dosage = calculated_dosage;
            this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].planned_dosage = calculated_dosage;
          } else {
            this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].calculated_dosage = '';
            this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].planned_dosage = '';
          }
        }
      } else { // before during after
        if (mix_ind === 'none') {
          dose = this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].dosage;
          if (dose) {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].calculated_dosage = calculated_dosage;
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].planned_dosage = calculated_dosage;
          } else {
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].calculated_dosage = '';
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].planned_dosage = '';
          }
        } else {
          dose = this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].dosage;
          if (dose) {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].calculated_dosage = calculated_dosage;
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage = calculated_dosage;
          } else {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].calculated_dosage = '';
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage = '';
          }
        }
      }
      this.$forceUpdate();
    },
    delete_selected_drugs: function () {
      for (let i = 0; i < this.selected_drugs.length; i++) {
        let dize = this.selected_drugs[i].split('_');
        if (dize[1] === 'drug') {
          this.$delete(this.treatment_data.selected_protocol.drugs, dize[0]);
        }
      }
      this.selected_drugs = [];
    },
    cancel_all_drug_selections: function () {
      this.selected_drugs = [];
    },
    DateFormat: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
      } else {
        return '';
      }
    },
    group_select: function (group) {
      if (!this.group_control && !this.protocol_edit_mode) {
        if (group) {
          this.treatment_data.protocol_group_select = group;
          this.selected_group = group;
          this.protocol_selection = '';
          this.$refs.protocol_selection.mutableValue = null;
          if (this.d_scopeType === 'my_protocols') {
            this.protocol_options = [];
            for (let i in this.d_myProtocolList) {
              if (this.d_myProtocolList[i].data.protocol_group === group.value) {
                this.protocol_options.push(this.d_myProtocolList[i]);
              }
            }
          } else {
            ProtocolService.search({ 'group': group.value, 'scope': [this.d_scopeType], 'favorite': this.protocol_favorite, 'detail': 'not-detailed' })
              .then(resp => {
                if (resp.data.results.length > 0) {
                  this.protocol_options = resp.data.results;
                } else {
                  this.protocol_options = [];
                }
              });
          }
        } else {
          this.selected_group = '';
          this.protocol_selection = '';
          this.$refs.protocol_selection.mutableValue = null;
          this.treatment_data.protocol_group_select = false;
          if (this.d_scopeType === 'my_protocols') {
            this.protocol_options = JSON.parse(JSON.stringify(this.d_myProtocolList));
          } else {
            ProtocolService.search({ 'group': '', 'scope': [this.d_scopeType], 'favorite': this.protocol_favorite, 'detail': 'not-detailed' })
              .then(resp => {
                if (resp.data.results.length > 0) {
                  this.protocol_options = resp.data.results;
                } else {
                  this.protocol_options = [];
                }
              });
          }
        }
      } else {
        this.group_control = false;
      }
    },
    /*    fluid_select: function () {
          this.$forceUpdate();
          for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
            let data = this.treatment_data.selected_protocol.drugs[drg_ind];
            console.log(JSON.stringify(data.fluid));
            for (let i in this.fluid_options) {
              if (data.fluid.value === this.fluid_options[i].value) {
                data.fluid_value = this.fluid_options[i].fluid_value;
                console.log(data.fluid_value);
                break;
              }
            }
            for (let i in this.types) {
              for (let pr_ind in data[this.types[i]]) {
                for (let i in this.fluid_options) {
                  if (data[this.types[i]][pr_ind].fluid.value === this.fluid_options[i].value) {
                    data[this.types[i]][pr_ind].fluid_value = this.fluid_options[i].fluid_value;
                    break;
                  }
                }
              }
            }
          }
        }, */
    calculate_all_dosages: function () {
      if (!this.patient.selected) {
        return;
      }
      for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
        let data = this.treatment_data.selected_protocol.drugs[drg_ind];
        data.calculated_dosage = DosageCalculator.calculate(data.unit.value, this.patient_data, data.dosage);
        data.planned_dosage = data.calculated_dosage;
        if (data.fluid) {
          for (let i in this.fluid_options) {
            if (data.fluid.value === this.fluid_options[i].value) {
              data.fluid_value = this.fluid_options[i].fluid_value;
              break;
            }
          }
        }
        for (let mx in data.drug_mix) {
          data.drug_mix[mx].calculated_dosage = DosageCalculator.calculate(data.drug_mix[mx].unit.value, this.patient_data, data.drug_mix[mx].dosage);
          data.drug_mix[mx].planned_dosage = data.drug_mix[mx].calculated_dosage;
        }
        for (let k in this.types) {
          for (let pr_ind in data[this.types[k]]) {
            data[this.types[k]][pr_ind].calculated_dosage = DosageCalculator.calculate(data[this.types[k]][pr_ind].unit.value, this.patient_data, data[this.types[k]][pr_ind].dosage);
            if (data[this.types[k]][pr_ind].fluid) {
              for (let i in this.fluid_options) {
                if (data[this.types[k]][pr_ind].fluid.value === this.fluid_options[i].value) {
                  data[this.types[k]][pr_ind].fluid_value = this.fluid_options[i].fluid_value;
                  break;
                }
              }
            }
            data[this.types[k]][pr_ind].planned_dosage = data[this.types[k]][pr_ind].calculated_dosage;
            for (let mx in data[this.types[k]][pr_ind].drug_mix) {
              data[this.types[k]][pr_ind].drug_mix[mx].calculated_dosage = DosageCalculator.calculate(data[this.types[k]][pr_ind].drug_mix[mx].unit.value, this.patient_data, data[this.types[k]][pr_ind].drug_mix[mx].dosage);
              data[this.types[k]][pr_ind].drug_mix[mx].planned_dosage = data[this.types[k]][pr_ind].drug_mix[mx].calculated_dosage;
            }
          }
        }
      }
      this.$forceUpdate();
    },
    protocol_select: function (protocol) {
      if (!this.is_new_protocol) {
        this.treatment_data.selected_protocol = {};
      }
      if (protocol && protocol.value) {
        if (this.d_scopeType === 'my_protocols') {
          ProtocolService.get_my_protocol(protocol.value)
            .then(resp => {
              this.f_functionsAfterGetProtocol(protocol, resp.data.result);
            });
        } else {
          ProtocolService.get(protocol.value)
            .then(resp => {
              this.f_functionsAfterGetProtocol(protocol, resp.data);
            });
        }
      }
    },
    f_functionsAfterGetProtocol: function (protocol, resp) {
      this.total_days = 0;
      if (protocol === null) {
        return;
      }
      this.treatment_data.patient_id = this.patient.id;
      this.treatment_data.status = 'active';
      this.treatment_data.protocol_cycles = { 'drugs': {}, 'drug_order': [] };
      this.real_protocol_data = resp;
      // this.treatment_data.protocol = resp;
      this.treatment_data.selected_protocol = {};
      this.treatment_data.insurance_report = { started_at: '', ending_at: '', prepared_by: '', date: '', status: 'not_prepared' };
      this.treatment_data.insurance_data = {};
      this.treatment_data.protocol_id = protocol.value;
      this.treatment_data.selected_protocol = JSON.parse(JSON.stringify(resp));
      this.treatment_data.abbr_name = this.treatment_data.selected_protocol.abbr_name;
      this.calculate_info_list_new('all_drugs');
      this.treatment_data.notes = [];
      if (this.treatment_data.selected_protocol.protocol_note) {
        let today = new Date();
        this.treatment_data.notes.push({ user: this.user.username, note_type: 'general', note: this.treatment_data.selected_protocol.protocol_note, date_at: moment(today).toISOString() });
      }
      this.treatment_data.selected_protocol.label = JSON.parse(JSON.stringify(protocol.label));
      this.treatment_data.selected_protocol.value = JSON.parse(JSON.stringify(protocol.value));
      if (!this.protocol_edit_mode) {
        this.calculate_all_dosages();
      }
      this.treatment_data.protocol_cycles.drug_order = [];
      this.treatment_data.name = protocol.label;

      if (this.is_treatment_list) {
        this.add_edit_treatment_data.selected_protocol = this.treatment_data.selected_protocol;
      }
      // let today = new Date();
      // this.treatment_data.notes = [{user: this.user.username, note_type: 'general', note: resp.protocol_note, date_at: moment(today).toISOString()}];
      this.calculate_ending_date();
      this.ControlCreatininRecord();
      this.record_type_of_repeats_analyze();
    },
    calculate_cycles: function () {
      if (!this.treatment_data.started_at) {
        return;
      }
      let det_name_dif = false;
      let today = new Date();
      today = moment(today).toISOString();
      this.treatment_data.protocol_cycles = { drug_order: [], drugs: {} };
      for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
        let treatment_drug = this.treatment_data.selected_protocol.drugs[drg_ind];
        if (!treatment_drug.model || treatment_drug.model === 'recipe') {
          continue;
        }
        if (JSON.stringify(this.treatment_data.selected_protocol.drugs) !== JSON.stringify(this.real_protocol_data.drugs)) {
          det_name_dif = true;
        }
        if (treatment_drug.drug.translation === undefined) {
          treatment_drug.drug.translation = { tr: treatment_drug.drug.label, en: treatment_drug.drug.label };
        }
        if (!treatment_drug.drug) {
          return;
        }
        let days = [];
        days = days.concat(treatment_drug.day);
        days.sort((a, b) => a - b);
        let new_drug_order =  parseInt(drg_ind) + 1;
        let drug_index = new_drug_order + '-' + treatment_drug.drug.value;
        if (this.treatment_data.protocol_cycles.drug_order.indexOf(drug_index) === -1) {
          this.treatment_data.protocol_cycles.drug_order.push(drug_index);
        }
        if (treatment_drug.delay === undefined) {
          treatment_drug.delay = 0;
        }
        if (this.treatment_data.started_at) {
          var drug_date_started = new Date(this.treatment_data.started_at);
          drug_date_started.setDate(drug_date_started.getDate() + parseInt(treatment_drug.delay));
          drug_date_started = drug_date_started.toISOString();
          drug_date_started = moment(drug_date_started).format('YYYY-MM-DD');
        }
        this.treatment_data.protocol_cycles.drugs[drug_index] = { 'date_started': drug_date_started, 'cycles': [], 'drug': treatment_drug.drug, 'type': treatment_drug.type, 'delay': treatment_drug.delay };
        // protocol_cycles oluşturuluyor.
        for (var i = 0; i < parseInt(treatment_drug.repeat); i++) {
          var cycle = i;
          this.treatment_data.protocol_cycles.drugs[drug_index].cycles.push({ 'days': [], 'period': treatment_drug.period, 'delay': 0 });
          // let repeat_days = days.map(function (item) { return item + (i * Number(treatment_drug.period) + Number(treatment_drug.delay)); });
          for (let day in days) {
            let day_date_started = new Date(this.treatment_data.started_at);
            day_date_started.setDate(day_date_started.getDate() + parseInt(treatment_drug.period) * cycle + parseInt(days[day]) - 1 + parseInt(treatment_drug.delay));
            var day_started = moment(day_date_started).format('YYYY-MM-DD');
            //
            var laboratory = { 'list': [] };
            for (let lab in treatment_drug.premedications.laboratory.list) {
              if (treatment_drug.premedications.laboratory.list[lab].drug_days.indexOf(days[day]) !== -1) {
                let data = treatment_drug.premedications.laboratory.list[lab];
                laboratory.list.push({ 'day': data.day, 'minutes_drug_related': '0', 'note': data.note, 'tests': data.tests, 'type': data.type });
              }
            }
            var messages = { 'list': [] };
            for (let msg in treatment_drug.premedications.message_to_patient.list) {
              if (treatment_drug.premedications.message_to_patient.list[msg].drug_days.indexOf(days[day]) !== -1) {
                let data = treatment_drug.premedications.message_to_patient.list[msg];
                messages.list.push({ 'day': data.day, 'send_languages': data.send_languages, 'message': data.message, 'time': '08:00', 'type': data.type });
              }
            }
            var notes = { 'list': [] };
            for (let nt in treatment_drug.premedications.note.list) {
              if (treatment_drug.premedications.note.list[nt].drug_days.indexOf(days[day]) !== -1) {
                let data = treatment_drug.premedications.note.list[nt];
                notes.list.push({ 'day': data.day, 'note': data.note, 'type': data.type });
              }
            }
            // laboratory data
            if (this.patient_data.last_laboratory['2']) {
              this.calculation_data.lab_date = this.patient_data.last_laboratory['2'].date;
              this.calculation_data.crea = this.patient_data.last_laboratory['2'].val;
              this.calculation_data.gfr = this.patient_data.last_laboratory['2'].gfr;
              this.calculation_data.selected_gfr_formule = this.patient_data.last_laboratory['2'].selected_gfr_formule;
              this.calculation_data.selected_weight_for_gfr = this.patient_data.last_laboratory['2'].selected_weight_for_gfr;
            }
            // bmi data
            if (this.patient_data.last_bmi_data !== 'none') {
              this.calculation_data.bmi_date = this.patient_data.last_bmi_data.date;
              this.calculation_data.bsa = this.patient_data.last_bmi_data.bsa;
              this.calculation_data.length = this.patient_data.last_bmi_data.length;
              this.calculation_data.weight = this.patient_data.last_bmi_data.weight;
              this.calculation_data.selected_weight_for_bsa = this.patient_data.last_bmi_data.selected_weight_for_bsa;
            }
            //
            this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days.push({ 'day': days[day], 'date_started': day_started, 'data': {}, 'laboratory': laboratory, 'note': notes, 'message_to_patient': messages });
            let day_key = this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days.length - 1;
            this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days[day_key].data = { 'drug': treatment_drug.drug, 'order': new_drug_order, 'before': {}, 'during': {}, 'after': {}, 'dosage': treatment_drug.dosage, 'unit': treatment_drug.unit, 'drug_mix': [], 'dosage_form': treatment_drug.dosage_form, 'duration': treatment_drug.duration, 'frequency': treatment_drug.frequency, 'fluid_value': treatment_drug.fluid_value, 'fluid': treatment_drug.fluid, fluid_brands: { 'barcode_list': [], 'brand_count': {} }, 'duration_wait': treatment_drug.duration_wait, 'calculated_dosage': treatment_drug.calculated_dosage, 'planned_dosage': treatment_drug.planned_dosage, 'given_dosage': treatment_drug.planned_dosage, 'note_list': [], 'drug_note': treatment_drug.drug_note, 'dosage_changes': { 'data': {}, 'reasons': [] }, 'toxicity': [], 'approvals': this.approvals, 'calculation_data': this.calculation_data, 'barcode_list': [], 'brand_count': {} };
            if (treatment_drug.drug_note) {
              this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days[day_key].data.note_list.push({ date_at: today, note: treatment_drug.drug_note, note_type: 'drug', user: this.user.username });
            }

            if (treatment_drug.drug_mix) {
              for (let d_mix in treatment_drug.drug_mix) {
                if (treatment_drug.drug_mix[d_mix].drug) {
                  this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days[day_key].data['drug_mix'].push({ 'drug': treatment_drug.drug_mix[d_mix].drug, 'calculated_dosage': treatment_drug.drug_mix[d_mix].calculated_dosage, 'note_list': [], 'planned_dosage': treatment_drug.drug_mix[d_mix].planned_dosage, 'given_dosage': treatment_drug.drug_mix[d_mix].planned_dosage, 'unit': treatment_drug.drug_mix[d_mix].unit, 'dosage': treatment_drug.drug_mix[d_mix].dosage, 'drug_note': treatment_drug.drug_mix[d_mix].drug_note, 'dosage_changes': { 'data': {}, 'reasons': [] }, 'toxicity': [], 'barcode_list': [], 'brand_count': {} });
                }
              }
            }
            let prem_array = ['before', 'during', 'after'];
            for (let a in prem_array) {
              let i = 1;
              let prem_type = prem_array[a];
              for (let pr in treatment_drug[prem_type]) {
                if (treatment_drug[prem_type][pr].drug) {
                  let data = treatment_drug[prem_type][pr];
                  if (data.drug_days.indexOf(days[day]) === -1) {
                    continue;
                  }
                  let premedication_index = i + '-' + data.drug.value;
                  i = i + 1;
                  this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days[day_key].data[prem_type][premedication_index] = { 'drug': data.drug, 'dosage': data.dosage, 'calculated_dosage': data.calculated_dosage, 'planned_dosage': data.planned_dosage, 'given_dosage': data.planned_dosage, 'dosage_form': data.dosage_form, 'fluid': data.fluid, 'fluid_value': data.fluid_value, 'drug_note': data.drug_note, 'drug_order': data.drug_order, 'drug_mix': [], 'duration': data.duration, 'duration_wait': data.duration_wait, 'frequency': data.frequency, 'meal_relation': data.meal_relation, 'unit': data.unit, 'day': data.day, 'dosage_changes': { 'data': {}, 'reasons': [] }, 'toxicity': [], 'approvals': {}, 'calculation_data': this.calculation_data };
                  for (let dy in data.day) {
                    this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days[day_key].data[prem_type][premedication_index].approvals[data.day[dy]] = JSON.parse(JSON.stringify(this.approvals));
                    let prem_day_data = this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days[day_key].data[prem_type][premedication_index].approvals[data.day[dy]];
                    /*
                    let calc_date = new Date(day_started);
                    if (prem_type === 'before') {
                      calc_date.setDate(calc_date.getDate() - parseInt(data.day[dy]));
                    } else if (prem_type === 'after') {
                      calc_date.setDate(calc_date.getDate() + parseInt(data.day[dy]));
                    }
                    prem_day_data.date_started = moment(calc_date).format('YYYY-MM-DD');
                    */
                    //
                    prem_day_data['note_list'] = [];
                    if (data.drug_note) {
                      prem_day_data.note_list.push({ date_at: today, note: data.drug_note, note_type: 'drug', user: this.user.username });
                    }
                    prem_day_data['calculation_data'] = this.calculation_data;
                    prem_day_data['barcode_list'] = [];
                    prem_day_data['brand_count'] = {};
                    prem_day_data['fluid_brands'] = { 'barcode_list': [], 'brand_count': {} };
                  }
                  if (data.drug_mix) {
                    for (let mx in data.drug_mix) {
                      if (data.drug_mix[mx].drug) {
                        this.treatment_data.protocol_cycles.drugs[drug_index].cycles[cycle].days[day_key].data[prem_type][premedication_index]['drug_mix'].push({ 'drug': data.drug_mix[mx].drug, 'calculated_dosage': data.drug_mix[mx].calculated_dosage, 'note_list': [], 'planned_dosage': data.drug_mix[mx].planned_dosage, 'given_dosage': data.drug_mix[mx].planned_dosage, 'unit': data.drug_mix[mx].unit, 'dosage': data.drug_mix[mx].dosage, 'drug_note': data.drug_mix[mx].drug_note, 'dosage_changes': { 'data': {}, 'reasons': [] }, 'toxicity': [], 'barcode_list': [], 'brand_count': {} });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (det_name_dif) {
        this.treatment_data.name = this.treatment_name;
      }
      if (this.is_treatment_list) {
        this.add_edit_treatment_data.protocol_cycles = this.treatment_data.protocol_cycles;
      }
      this.CalculateTotalDays();
      this.calculate_ending_date();
    },
    calculate_date_and_day_dif (start_date, drug_key, cycle_index, day_index) {
      let vm = this;
      var day_total = 0;
      for (var cycle_ind in vm.treatment_data.protocol_cycles.drugs[drug_key].cycles) {
        if (cycle_index > 0 && parseInt(cycle_ind) !== cycle_index) {
          day_total = day_total + parseInt(vm.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_ind].period);
          day_total = day_total + parseInt(vm.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_ind].delay);
        }
        if (parseInt(cycle_ind) === cycle_index) {
          for (var day_ind in vm.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_ind].days) {
            if (day_index === parseInt(day_ind)) {
              if (cycle_index > 0) {
                day_total = day_total + parseInt(vm.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_ind].days[day_index].day) - 1;
              } else {
                day_total = parseInt(vm.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_ind].days[day_index].day) - 1;
              }
              let date = new Date(start_date);
              date.setHours(8, 0, 0, 0);
              date.setDate(date.getDate() + day_total);
              vm.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_ind].days[day_index].date_started = moment(date).toISOString();
              let data_2 = vm.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_ind].days[day_index].data;
              for (let pr in data_2.before) {
                for (let dy in data_2.before[pr].day) {
                  let prem_date = new Date(date);
                  prem_date.setDate(prem_date.getDate() - parseInt(data_2.before[pr].day[dy]));
                  data_2.before[pr].approvals[data_2.before[pr].day[dy].toString()].date_started = moment(prem_date).toISOString();
                }
              }
              for (let pr in data_2.after) {
                for (let dy in data_2.after[pr].day) {
                  let prem_date = new Date(date);
                  prem_date.setDate(prem_date.getDate() + parseInt(data_2.after[pr].day[dy]));
                  data_2.after[pr].approvals[data_2.after[pr].day[dy].toString()].date_started = moment(prem_date).toISOString();
                }
              }
              for (let pr in data_2.during) { // during aynı gün içinde hesaplama yapmıyoruz.
                for (let dy in data_2.during[pr].day) {
                  data_2.during[pr].approvals[data_2.during[pr].day[dy].toString()].date_started = moment(date).toISOString();
                }
              }
              let data_3 = vm.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_ind].days[day_index];
              for (let list_ind in data_3.note.list) { // during aynı gün içinde hesaplama yapmıyoruz.
                for (let dy_ind in data_3.note.list[list_ind].day) {
                  if (data_3.note.list[list_ind].type === 'before') {
                    let note_date = new Date(date);
                    note_date.setDate(note_date.getDate() - parseInt(data_3.note.list[list_ind].day[dy_ind]));
                    data_3.note.list[list_ind].date_started = moment(note_date).toISOString();
                  } else if (data_3.note.list[list_ind].type === 'during') { // during aynı gün
                    data_3.note.list[list_ind].date_started = moment(date).toISOString();
                  } else if (data_3.note.list[list_ind].type === 'after') {
                    let note_date = new Date(date);
                    note_date.setDate(note_date.getDate() + parseInt(data_3.note.list[list_ind].day[dy_ind]));
                    data_3.note.list[list_ind].date_started = moment(note_date).toISOString();
                  } else if (data_3.note.list[list_ind].type === 'general') {
                    data_3.note.list[list_ind].date_started = moment(date).toISOString();
                  }
                }
              }
              for (let list_ind in data_3.message_to_patient.list) { // during aynı gün içinde hesaplama yapmıyoruz.
                for (let dy_ind in data_3.message_to_patient.list[list_ind].day) {
                  if (data_3.message_to_patient.list[list_ind].type === 'before') {
                    let note_date = new Date(date);
                    note_date.setDate(note_date.getDate() - parseInt(data_3.message_to_patient.list[list_ind].day[dy_ind]));
                    data_3.message_to_patient.list[list_ind].date_started = moment(note_date).toISOString();
                  } else if (data_3.message_to_patient.list[list_ind].type === 'during') { // during aynı gün
                    data_3.message_to_patient.list[list_ind].date_started = moment(date).toISOString();
                  } else if (data_3.message_to_patient.list[list_ind].type === 'after') {
                    let note_date = new Date(date);
                    note_date.setDate(note_date.getDate() + parseInt(data_3.message_to_patient.list[list_ind].day[dy_ind]));
                    data_3.message_to_patient.list[list_ind].date_started = moment(note_date).toISOString();
                  } else if (data_3.message_to_patient.list[list_ind].type === 'general') {
                    data_3.message_to_patient.list[list_ind].date_started = moment(date).toISOString();
                  }
                }
              }
              for (let list_ind in data_3.laboratory.list) { // during aynı gün içinde hesaplama yapmıyoruz.
                for (let dy_ind in data_3.laboratory.list[list_ind].day) {
                  if (data_3.laboratory.list[list_ind].type === 'before') {
                    let note_date = new Date(date);
                    note_date.setDate(note_date.getDate() - parseInt(data_3.laboratory.list[list_ind].day[dy_ind]));
                    data_3.laboratory.list[list_ind].date_started = moment(note_date).toISOString();
                  } else if (data_3.laboratory.list[list_ind].type === 'during') { // during aynı gün
                    data_3.laboratory.list[list_ind].date_started = moment(date).toISOString();
                  } else if (data_3.laboratory.list[list_ind].type === 'after') {
                    let note_date = new Date(date);
                    note_date.setDate(note_date.getDate() + parseInt(data_3.laboratory.list[list_ind].day[dy_ind]));
                    data_3.laboratory.list[list_ind].date_started = moment(note_date).toISOString();
                  } else if (data_3.laboratory.list[list_ind].type === 'general') {
                    data_3.laboratory.list[list_ind].date_started = moment(date).toISOString();
                  }
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    go_back: function () {
      router.go(-1);
    },
    change_size: function () {
      let main = document.getElementById('treatment_new');
      let detail = document.getElementById('treatment_details');
      if (this.size_mode === 0) {
        main.className = 'col-sm-12';
        detail.className = 'col-sm-12';
        this.size_mode = 1;
      } else {
        main.className = 'col-sm-6';
        detail.className = 'col-sm-6';
        this.size_mode = 0;
      }
    },
    f_saveWdm: function() {
      if (this.option_data[this.wisdom_data.type].date_ability && this.option_data[this.wisdom_data.type].date_ability === 'yes') {
        if (!this.wisdom_data.date) {
          let modal_data = { 'type': 'alert', 'text': "Lütfen tarih giriniz", 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['require']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      // Bir wdmr kaydı yapabilmek için en az bir parametre dolu olması gerekiyor.
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      // wdmr kaydı sırasında tüm wdm parametreleri için require kontrolü yapıyor. İlgili parametrede val değeri oluştuysa zaten oraya girilmiş oluyor. İçine girilipte (wisdom-param componenti) dolu olmayan tüm parametreleri kontrol ediyoruz. Örneğin er ihd testini ekledi. Fakat pozitif / negatif değerini doldurmadıysa geçemiyor.
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      wdmr_name = return_data.name;
      this.wisdom_data['label'] = wdmr_name;
      wdmr_require_alerts = return_data.wdmr_require_alerts;
      only_one_param_is_filled = return_data.only_one_param_is_filled;
      this.show_wisdom_data_modal = false;
      let d_uuid = this.wisdom_data.id ? this.wisdom_data.id : '';
      let data = {
        'webservice': {},
        'data': this.wisdom_data
      };
      // this.$refs.wisdom_data_modal.calculate_name();
      WisdomDataService.save_wdm(this.patient_data.id, d_uuid, data).then(resp => {
        if (resp.data.status === "success") {
          if (resp.data.result && resp.data.result.data.general && resp.data.result.data.general.diagnosis && resp.data.result.data.general.diagnosis.val) {
            let data = resp.data.result.data.general.diagnosis.val;
            data.id = resp.data.result.id;
            data.label = data.label + ' - ' + resp.data.result.date;
            this.diagnosis_list.push(data);
            store.commit('patient_diagnosis_list', this.diagnosis_list);
            this.treatment_data.diagnosis = data;
            this.$forceUpdate();
          }
        }
      });
    },
    f_newWdm: function (data_type) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz' } });
      this.data_type = data_type;
      // predata hazırlanma sebebi bir değişkene eşittir diğer değişken dendiğinde computed property değişiyor. Fakat datanın içerisindeki sadece bir bilgi değişince sayfada re render olmuyor. dolayısıyla önce predata hazırlayıp. asıl datamıza yani wisdom data ya eşitliyoruz aşağıda.
      let pre_data = { 'data': {}, 'date': '', 'type': this.data_type, 'reference_wdm': {} };
      let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
      let doc_key = '';
      let doc_version = '';
      let bucket = '';
      if (wdm_version.hospital.last[data_type]) {
        doc_key = wdm_version.hospital.last[data_type].key;
        doc_version = wdm_version.hospital.last[data_type].version;
        bucket = 'hospital';
      }
      if (wdm_version.wisdom.last[data_type]) {
        doc_key = wdm_version.wisdom.last[data_type].key;
        doc_version = wdm_version.wisdom.last[data_type].version;
        bucket = 'option';
      }
      if (wdm_version.user.last[data_type]) {
        doc_key = wdm_version.user.last[data_type].key;
        doc_version = wdm_version.user.last[data_type].version;
        bucket = 'hospital';
      }
      GlobalService.get_one_cb_document({ 'key': doc_key, 'bucket': bucket })
        .then(resp => {
          if (resp.data.status === 'success') {
            let option_data = resp.data.result;
            this.option_data = {};
            this.option_data[data_type] = option_data;
            for (let i in option_data.param_group.param_order) {
              pre_data['data'][option_data.param_group.param_order[i]] = {};
            }
            pre_data['reference_wdm']['version'] = doc_version;
            pre_data['reference_wdm']['owner_type'] = option_data['owner_type'];
            pre_data['reference_wdm']['key'] = doc_key;
            this.wisdom_data = pre_data;
            // this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.show_wisdom_data_modal = true;
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error ', resp.data.message);
          }
        });
    }
  },
  watch: {
    d_scopeType: function () {
      // this.treatment_data.started_at = '';
      // this.treatment_data.selected_weight_for_gfr = '';
      // this.treatment_data.selected_weight = '';
      // this.treatment_data.selected_gfr_formule = '';
      // this.treatment_data.last_status = 'no_operation_yet';
      this.protocol_options = [];
      this.treatment_data.name = '';
      this.treatment_data.selected_protocol = '';
      this.treatment_data.protocol_cycles = { 'drugs': {} };
      this.real_protocol_data = {};
      this.protocol_selection = '';
      this.calculate_info_list_new('all_drugs');
      if (!this.protocol_edit_mode) {
        this.calculate_all_dosages();
      }
      this.$forceUpdate();
      if (this.d_scopeType !== 'my_protocols') {
        this.group_select(this.selected_group);
      } else {
        this.protocol_options = JSON.parse(JSON.stringify(this.d_myProtocolList));
      }
    },
    new_protocol_change: function () {
      this.treatment_data.selected_protocol = this.selected_protocol_for_protocol_new;
      this.calculate_info_list_new('all_drugs');
    },
    'select_options.protocol_selected': function () {
      // console.log(this.select_options.protocol_selected);
      this.protocol_select(this.select_options.protocol_selected);
    },
    'mother_children.new_protocol': function () {
      this.show_protocol_new = false;
      // if (this.mother_children.new_protocol) {
      //   this.mother_children.new_protocol = false;
      // }
    },
    'bmi_gfr_change.status': function () {
      // console.log('bmi_gfr_change........');
      this.calculate_all_dosages();
      this.mother_children_add_edit();
    },
    /*    'patient_data.last_bmi_data.bsa': {
          handler: function (val, oldVal) {
            console.log('val', val);
            console.log('oldVal', oldVal);
          },
          deep: true
        }, */
    '$route.params': function () {
      this.route_rule_func();
    },
    'treatment_data.started_at': function (ndate) {
      this.record_type_of_repeats_analyze();
      // this.calculate_ending_date();
    },
    'protocol_scope': function (nscope) {
      this.group_select(this.selected_group);
    },
    'protocol_favorite': function (nfav) {
      this.group_select(this.selected_group);
    }
  },
  components: {
    SelectedProtocol,
    WisdomSelect,
    Datepicker,
    vSelect,
    ProtocolViewer,
    LabEditForm,
    Modal,
    BmiForm,
    BmiGfrWidget,
    ConfirmForm,
    ChemotherapyLaboratory,
    WisdomDataModal,
    TreatmentAddEditDrug,
    cSwitch
  }
};

</script>
<style>
.middle-text {
  text-align: center;
  padding: 5px;
}

.add-edit-before-caption {
  height: 20px;
  width: 26px;
  border-radius: 13px;
  background-color: #20e520;
  font-family: Comic Sans MS;
  float: right;
  text-align: center;
  box-shadow: -2px -2px;
}

.add-edit-during-caption {
  height: 20px;
  width: 26px;
  border-radius: 13px;
  background-color: #ff9900;
  font-family: Comic Sans MS;
  float: right;
  text-align: center;
  box-shadow: -2px -2px;
}

.add-edit-drug-caption {
  height: 20px;
  width: 26px;
  border-radius: 13px;
  background-color: #20a8d8;
  font-family: Comic Sans MS;
  float: right;
  text-align: center;
  box-shadow: -2px -2px;
}

.add-edit-after-caption {
  height: 20px;
  width: 26px;
  border-radius: 13px;
  background-color: #eded2a;
  font-family: Comic Sans MS;
  float: right;
  text-align: center;
  box-shadow: -2px -2px;
}

.add-edit-note-caption {
  height: 20px;
  width: 26px;
  border-radius: 13px;
  background-color: rgb(183, 106, 106);
  font-family: Comic Sans MS;
  float: right;
  text-align: center;
  box-shadow: -2px -2px;
}

.add-edit-note-row {
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  background-color: #ffabab;
  box-shadow: -2px 0px;
}

.add-edit-message-caption {
  height: 20px;
  width: 26px;
  border-radius: 13px;
  background-color: #cc66ff;
  font-family: Comic Sans MS;
  float: right;
  text-align: center;
  box-shadow: -2px -2px;
}

.add-edit-message-row {
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  background-color: #cc80f2;
  box-shadow: -2px 0px;
}

.add-edit-laboratory-caption {
  height: 20px;
  width: 26px;
  border-radius: 13px;
  background-color: rgb(88, 239, 239);
  font-family: Comic Sans MS;
  float: right;
  text-align: center;
  box-shadow: -2px -2px;
}

.add-edit-laboratory-row {
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  background-color: rgb(135, 229, 229);
  box-shadow: -2px 0px;
}

.add-edit-drug-col {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 3px;
}

.add-edit-drug-row {
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
}

.add-edit-caption {
  float: left;
  margin-top: 5px;
}

.form-control {
  background: transparent;
}

</style>
