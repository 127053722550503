<template>
  <div>
    <b-row>
      <b-col cols="9">
        <template v-if="d_drugFilterType === 'all'">
          <span style="color: red;">Arama yaptıktan sonra listeden seçim yaparak ekleme butonuna tıklayınız.</span>
          <b-input-group-append>
            <b-form-input style="color: green;" @keydown.enter.native="GetClickKey" v-model="d_searchDrugText" placeholder="Ürün adı, içeriği ile arama yapabilirsiniz."></b-form-input>
            <b-button size="sm" variant="primary" @click="SearchWithSearchingDrugText()"><i class="fa fa-plus"></i> ara</b-button>
          </b-input-group-append>
          <v-select v-model="d_selectedDrug" name="drug_search" placeholder="Listeden ilaç seçimi yapınız" :options="d_drugSearchList">
            <template slot="option" slot-scope="option">
              <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                <span> {{ option.label }} </span>
              </div>
            </template>
          </v-select>
        </template>
        <template v-else>
          <v-select v-model="d_selectedDrug" name="favorite_drugs" :options="d_favoriteDrugs">
            <template slot="option" slot-scope="option">
              <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                <span> {{ option.label }} </span>
              </div>
            </template>
          </v-select>
        </template>
      </b-col>
      <b-col cols="3">
        <b-form-select v-model="d_drugFilterType">
          <option name="favorite" value="favorite">Favori İlaçlar</option>
          <option name="all" value="all">Tüm İlaçlar</option>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4"></b-col>
      <b-col cols="4">
        <b-button block @click="f_addDrugToBatchList()"><i class="fa fa-arrow-down"> </i> İlacı Ekle <i class="fa fa-arrow-down"> </i></b-button>
      </b-col>
      <b-col cols="4"></b-col>
    </b-row>
    <b-card v-for="drug in d_addedDrugList" :key="'drug_' + drug.drug.value">
      <template slot="header">
        {{ drug.drug.label }} {{ f_infoForDrug(drug) }}
        <b-button class="pull-right" variant="danger" @click="f_removeDrugFromBatchList(drug.drug.value)"><i class="fa fa-trash"> </i> İlacı Sil</i></b-button>
      </template>
      <b-row>
        <b-col cols="4">
          <b-form-checkbox v-model="drug.change_fluid" :value="1" :unchecked-value="0" switch @input="f_forceUpdate">Mayi Belirle</b-form-checkbox>
        </b-col>
        <b-col cols="8" v-if="drug.change_fluid">
          <v-select v-model="drug.fluid" :options="d_fluidList" @input="f_forceUpdate"></v-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-checkbox v-model="drug.change_fluid_value" :value="1" :unchecked-value="0" switch @input="f_forceUpdate">Mayi Miktarı Belirle</b-form-checkbox>
        </b-col>
        <b-col cols="8" v-if="drug.change_fluid_value">
          <b-form-input v-model="drug.fluid_value" type="number" min="1" @input="f_forceUpdate"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-checkbox v-model="drug.change_duration" :value="1" :unchecked-value="0" switch @input="f_forceUpdate">Uygulama Süresi Belirle</b-form-checkbox>
        </b-col>
        <b-col cols="8" v-if="drug.change_duration">
          <b-form-input v-model="drug.duration" type="number" min="1" @input="f_forceUpdate"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-checkbox v-model="drug.change_dosage_form" :value="1" :unchecked-value="0" switch @input="f_forceUpdate">Uygulama Türü Belirle</b-form-checkbox>
        </b-col>
        <b-col cols="8" v-if="drug.change_dosage_form">
          <v-select v-model="drug.dosage_form" :options="d_dosageFormList" @input="f_forceUpdate"></v-select>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import ProtocolService from '@/services/public/protocol';
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import GlobalService from '@/services/global';
import moment from 'moment';
import OptionService from "@/services/option";
import {
  default as ProtocolModifier
} from '@/components/widgets/ProtocolModifier';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as ProtocolNew
} from '@/views/whsmodules/chemotherapy/protocol_new';
// /* global $ */

export default {
  name: 'ChemotherapyBatchEdit',
  computed: mapGetters({
    lang: 'lang',
    help: 'help'
  }),
  props: {
    d_addedDrugList: {
      type: Object,
      required: true
    }
  },
  created () {},
  mounted () {},
  data () {
    return {
      d_dosageFormList: require('@/options/dosage_form').options,
      d_fluidList: require('@/options/fluids').options,
      d_favoriteDrugs: require("@/options/favorite_drugs").options,
      d_drugFilterType: 'favorite',
      d_selectedDrug: '',
      d_searchDrugText: '',
      d_drugSearchList: []
    };
  },
  methods: {
    f_removeDrugFromBatchList (drg_value) {
      this.$delete(this.d_addedDrugList, drg_value);
      // this.$forceUpdate();
    },
    f_forceUpdate () {
      this.$forceUpdate();
    },
    f_infoForDrug (drug) {
      let txt = '';
      if (drug.change_fluid && drug.fluid.value) {
        txt += '[Seçilen Mayi: ' + drug.fluid.label + ']';
      }
      if (drug.change_fluid_value && drug.fluid_value) {
        txt += '[Seçilen Mayi Miktarı: ' + drug.fluid_value + ']';
      }
      if (drug.change_duration && drug.duration) {
        txt += '[Seçilen Süre: ' + drug.duration + ']';
      }
      if (drug.change_dosage_form && drug.dosage_form.value) {
        txt += '[Seçilen Uygulama Türü: ' + drug.dosage_form.label + ']';
      }
      console.log(txt);
      return txt;
    },
    f_addDrugToBatchList () {
      if (this.d_selectedDrug) {
        if (!this.d_addedDrugList[this.d_selectedDrug.value]) {
          this.d_addedDrugList[this.d_selectedDrug.value] = { 'drug': { 'value': this.d_selectedDrug.value, 'label': this.d_selectedDrug.label }, 'change_fluid': 0, 'fluid': '', 'change_duration': 0, 'duration': '', 'change_dosage_form': 0, 'dosage_form': '', 'change_fluid_value': 0, 'fluid_value': '' };
        }
        this.d_selectedDrug = '';
      }
    },
    GetClickKey (event) {
      if (event.which === 13) {
        this.SearchWithSearchingDrugText();
      }
    },
    SearchWithSearchingDrugText: function () {
      if (this.d_searchDrugText && this.d_searchDrugText.length > 0) {
        this.d_drugSearchList = [];
        this.d_selectedDrug = '';
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaçlar listeleniyor.' } });
        let data = { 'search': this.d_searchDrugText, 'wdm_type': 'wdm2' };
        OptionService.search_wdm_option(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_drugSearchList = resp.data.result;
              if (this.d_drugSearchList.length > 0) {
                this.d_selectedDrug = this.d_drugSearchList[0];
              }
            } else {
              this.d_drugSearchList = [];
            }
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          })
      } else {
        alert('Minimum 1 karakter giriniz.');
      }
    }
  },
  watch: {},
  components: {
    vSelect,
    ProtocolModifier,
    Modal,
    ProtocolNew
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  }
};

</script>

<style></style>

