<template>
  <div style="padding: 10px; font-size: 20px;">
    <table>
      <tr>
        <td>
          {{ $t('wdm16.3857') }}
          <div class="btn-group" style="float: left;" v-if="info_func_new('date_new_weight_height', data.date, 'date_of_weight')">
            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
              !
            </button> 
            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px; font-size: 5px;">
              <table>
                <tr v-for="(info, info_ind) in pro_inf_list.date_of_weight.date_new_weight_height">
                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                </tr>
              </table>
            </div>
          </div>
        </td>
        <td>
          <datepicker class="form-control" v-model="data.date" style="background-color: transparent;" :config="{locale: datepicker_langs[lang], maxDate: 'today'}"></datepicker>
        </td>
        <td></td>
      </tr>      
      <tr>
        <td v-if="show_kg">
          {{ $t('wdm16.3904') }}
          <div class="btn-group" style="float: left;" v-if="info_func_new('input_weight_kg', data.weight, 'weight_kg')">
            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
              !
            </button> 
            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
              <table>
                <tr v-for="(info, info_ind) in pro_inf_list.weight_kg.input_weight_kg">
                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                </tr>
              </table>
            </div>
          </div>
        </td>
        <td v-else>
          {{ $t('wdm16.3904') }}
          <div class="btn-group" style="float: left;" v-if="info_func_new('input_weight_lbs', data.weight_lbs, 'weight_lbs')">
            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
              !
            </button> 
            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
              <table>
                <tr v-for="(info, info_ind) in pro_inf_list.weight_lbs.input_weight_lbs">
                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                </tr>
              </table>
            </div>
          </div>
        </td>
        <td>
          <input type="number" v-if="show_kg" id="weight_kg" class="input form-control" name="weight" v-model="data.weight">
          <input type="number" v-else id="weight_lbs" class="input form-control" name="weight_lbs" v-model="data.weight_lbs">
        </td>
        <td>
          <button v-if='show_kg' type="button" class="btn btn-secondary" @click="show_kg_func">{{ $t('wdm16.3999') }}</button>
          <button v-else type="button" class="btn btn-secondary" @click="show_kg_func">{{ $t('wdm16.3971') }}</button>
        </td>
      </tr>
      <tr>
        <td v-if="show_kg">
          {{ $t('wdm16.4200') }}
          <div class="btn-group" style="float: left;" v-if="info_func_new('input_length_cm', data.length, 'length_cm')">
            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
              !
            </button> 
            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
              <table>
                <tr v-for="(info, info_ind) in pro_inf_list.length_cm.input_length_cm">
                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                </tr>
              </table>
            </div>
          </div>
        </td>
        <td v-else>
          {{ $t('wdm16.4200') }}
          <div class="btn-group" style="float: left;" v-if="info_func_new('input_length_inch', data.length_inch, 'length_inch')">
            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
              !
            </button> 
            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
              <table>
                <tr v-for="(info, info_ind) in pro_inf_list.length_inch.input_length_inch">
                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                </tr>
              </table>
            </div>
          </div>
        </td>
        <td>
          <input type="number" v-if="show_cm" class="input form-control" name="length" v-model="data.length">
          <input type="number" v-else class="input form-control" name="length_inch" v-model="data.length_inch">
        </td>
        <td>
          <button v-if='show_cm' type="button" class="btn btn-secondary" @click="show_cm = false">cm</button>
          <button v-else type="button" class="btn btn-secondary" @click="show_cm = true">inch</button>
        </td>
      </tr>
      <tr>
        <td>{{ $t('wdm16.3850') }}</td>
        <td> {{ data.bodymassindex }} </td>
        <td></td>
      </tr>    
      <tr>
        <td>{{ $t('wdm16.3740') }}</td>
        <td> {{ data.adjusted_weight }} </td>
        <td></td>
      </tr>   
      <tr>
        <td>{{ $t('wdm16.3988') }}</td>
        <td> {{ data.ideal_weight }} </td>
        <td></td>
      </tr>
      <tr>
        <td>{{ $t('wdm16.3996') }} (m2)</td>
        <td> {{ data.bsa }} </td>
        <td></td>
      </tr>               
    </table>
  </div>
</template>


<script>
import Cleave from 'vue-cleave';
import Datepicker from 'wisdom-vue-datepicker';
import { tr } from '../../../node_modules/flatpickr/dist/l10n/tr';
import { mapGetters } from 'vuex';

export default {
  name: 'BmiForm',
  mounted () {},
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      lang: 'lang'
    })
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: {weight: '', length: '', adjusted_weight: 0, ideal_weight: 0, length_inch: 0, weight_lbs: 0}
    }
  },
  data () {
    return {
      datepicker_langs: {tr: tr, en: {}},
      info_options: require('@/options/info').options,
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      show_kg: true,
      show_cm: true,
      pro_inf_list: {weight_kg: {}, weight_lbs: {}, length_cm: {}, length_inch: {}, date_of_weight: {}} // döngülü id oluşumu yoksa manuel pro_inf_listi oluşturabiliriz. BMİ modalinde döngülü id olmadığından manuel oluşturuldu.
    };
  },
  created () {},
  methods: {
    show_kg_func: function () {
      this.weight_calculate();
      this.$forceUpdate();
      if (this.show_kg === true) {
        this.show_kg = false;
      } else { this.show_kg = true; }
    },
    create_document_id_list: function (doc_id) { // sadece istediğimiz id'leri buraya gönderiyoruz. gerekli sayfalarda otomatik render ederken ya da döngülü id oluşumu yoksa manuel pro_inf_listi oluşturabiliriz. BMİ modalinde döngülü id olmadığından manuel oluşturuldu.
      if (this.pro_inf_list.doc_id === undefined) {
        this.pro_inf_list.doc_id = {};
      }
    },
    day_dif: function (date1, date2) { // 1 eski 2 yeni tarih.Daha sonra ikiden biri çıkarıyoruz.
      let date_1 = new Date(date1);
      let date_1_Msec = date_1.getTime();
      let date_2 = new Date(date2);
      let date_2_Msec = date_2.getTime();
      let msecPerDay = 86400000;
      return Math.round((date_2_Msec / msecPerDay) - (date_1_Msec / msecPerDay));
    },
    info_func_new: function (subject, val, doc_id, bef_val = 'none') {
      // ilgili alanın önce general item'ına bakıyoruz.
      let res = false;

      let det = false;
      if (!val) {
        this.create_subject_to_doc_id(doc_id, subject, 'general');
        this.pro_inf_list[doc_id][subject]['general'] = this.info_options[subject]['general'];
        res = true;
        det = true;
        return res;
      }
      if (!det) { this.clear_item_of_subject(doc_id, subject, 'general'); }

      let chars = val.toString().split('');
      for (let k in this.info_options[subject]) {
        if (k === 'chars') {
          let det = false;
          for (let i in chars) {
            if (this.info_options[subject].chars.value.indexOf(chars[i]) === -1) {
              this.create_subject_to_doc_id(doc_id, subject, k);
              this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
              res = true;
              det = true;
              break;
            }
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'len') {
          let det = false;
          if (this.info_options[subject][k].value < chars.length) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'decimal') {
          let det = false;
          if (this.numbers_after_decimal(chars) > this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'max') {
          let det = false;
          if (parseInt(val) > this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'min') {
          let det = false;
          if (parseInt(val) < this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'bef_aft' && bef_val !== 'none' && val !== 'none') {
          let det = false;
          let percent = Math.abs((bef_val - val) * 100 / bef_val);
          if (percent >= this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'date') {
          let det = false;
          let percent = Math.abs((bef_val - val) * 100 / bef_val);
          if (percent >= this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        }
      }
      return res;
    },
    clear_item_of_subject: function (doc_id, subject, item) {
      if (this.pro_inf_list[doc_id][subject] !== undefined) {
        if (this.pro_inf_list[doc_id][subject][item] !== undefined) {
          this.$delete(this.pro_inf_list[doc_id][subject], item);
        }
      }
    },
    create_subject_to_doc_id: function (doc_id, subject, item) {
      if (this.pro_inf_list[doc_id][subject] === undefined) {
        this.pro_inf_list[doc_id][subject] = {};
        if (this.pro_inf_list[doc_id][subject][item] === undefined) {
          this.pro_inf_list[doc_id][subject][item] = {};
        }
      }
    },
    info_func: function (subject) {
      let res = false;
      if (subject === 'date_new_weight_height') {
        let today = new Date();
        if (this.day_dif(this.data.date, today.toString()) > this.info_options[subject].dif.days) {
          res = true;
          this.pro_inf_list.date_new_weight_height.dif = this.info_options.date_new_weight_height.dif;
        } else if (this.pro_inf_list.date_new_weight_height.dif !== undefined) {
          this.$delete(this.pro_inf_list.date_new_weight_height, 'dif');
        }
        if (!this.data.date) {
          res = true;
          this.pro_inf_list.date_new_weight_height.date = this.info_options.date_new_weight_height.date;
        } else if (this.pro_inf_list.date_new_weight_height.date !== undefined) {
          this.$delete(this.pro_inf_list.date_new_weight_height, 'date');
        }
      } else if (subject === 'input_weight') {
        if (!this.data.weight) {
          res = false;
          return;
        }
        let chars = this.data.weight.toString().split('');
        let det = false;
        for (let i in chars) {
          if (this.info_options[subject].chars.value.indexOf(chars[i]) === -1) {
            this.pro_inf_list.input_weight.chars = this.info_options.input_weight.chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && this.pro_inf_list.input_weight.chars !== undefined) { this.$delete(this.pro_inf_list.input_weight, 'chars'); }

        if (this.info_options.input_weight.len.value < chars.length) {
          this.pro_inf_list.input_weight.len = this.info_options.input_weight.len;
          res = true;
        } else if (this.pro_inf_list.input_weight.len !== undefined) { this.$delete(this.pro_inf_list.input_weight, 'len'); }

        if (this.info_options.input_weight.type.value === 'float') {
          if (this.an_item_is_multiple(chars, '.')) {
            this.pro_inf_list.input_weight.type = this.info_options.input_weight.type;
            res = true;
          } else if (this.numbers_after_decimal(chars) > this.info_options.input_weight.decimal.value) {

          } else if (this.pro_inf_list.input_weight.type !== undefined) {
            this.$delete(this.pro_inf_list.input_weight, 'type');
          }
        }
      }
      return res;
    },
    an_item_is_multiple: function (arr, char) { // type float alanda bunu chars analizinde ek olarak yapıyoruz.
      let res = false;
      if (arr.indexOf(char) !== -1) { // birinci karakteri çıkarıyoruz.
        arr.splice(arr.indexOf(char), 1);
      }
      if (arr.indexOf(char) !== -1) { // birinci tespit edilen karakteri çıkardığımız halde dizede varsa res true dönüyor.
        res = true;
      }
      return res;
    },
    numbers_after_decimal: function (arr) {
      if (arr.indexOf('.') !== -1) {
        let a = arr.length - 1 - arr.indexOf('.');
        // console.log(a);
        return a;
      }
    },
    weight_calculate: function () {
      if (this.show_kg === true) {
        let a = this.data.weight / 0.45359237;
        this.data.weight_lbs = parseFloat(a).toFixed(0);
      } else {
        let a = parseFloat(this.data.weight_lbs) * 0.45359237;
        this.data.weight = parseFloat(a).toFixed(0);
      }
    },
    length_calculate: function () {
      if (this.show_cm === true) {
        let a = parseInt(this.data.length) / 2.54;
        this.data.length_inch = parseFloat(a).toFixed(0);
      } else {
        let a = parseInt(this.data.length_inch) * 2.54;
        this.data.length = parseFloat(a).toFixed(0);
      }
    },
    bmi_calculate: function () {
      this.data.bodymassindex = (this.data.weight / ((this.data.length / 100) * (this.data.length / 100))).toFixed(2);
    },
    ideal_weight_calculate: function () {
      if (this.patient.sex === '0') {
        let a = 50 + (2.3 * (this.data.length_inch - 60));
        this.data.ideal_weight = parseFloat(a).toFixed(0);
      } else {
        let a = 45.5 + (2.3 * (this.data.length_inch - 60));
        this.data.ideal_weight = parseFloat(a).toFixed(0);
      }
    },
    adjusted_weight_calculate: function () {
      let a = parseInt(this.data.ideal_weight) + 0.4 * (this.data.weight - this.data.ideal_weight);
      this.data.adjusted_weight = parseFloat(a).toFixed(0);
    },
    bsa_calculate: function () {
      let a = 0.007184 * Math.pow(this.data.length, 0.725) * Math.pow(this.data.weight, 0.425);
      this.data.bsa = parseFloat(a).toFixed(2);
    }
  },
  watch: {
    data: { handler (newValue) { // bir obje içerisindeki değişimi watch ediyoruz.
        this.length_calculate();
        this.weight_calculate();
        this.bmi_calculate();
        this.ideal_weight_calculate();
        this.adjusted_weight_calculate();
        this.bsa_calculate();
      },
        deep: true
    },
    'data.ideal_weight': function() {
      this.adjusted_weight_calculate();
    }
  },
  components: {
    Cleave,
    Datepicker
  }
};
</script>

<style type="text/css">
td, tr, th {
  padding: 5px;
  font-size: 12px;
}
</style>
