<template>
  <div id="builder" class="protocol-builder">
    <div class="protocol-header">
      <div class="row" style="padding-left: 13px; padding-right: 13px;">
        <div class="col-12">
          <div class="row">
            <div for="name" class="col-12 card-header">
              {{ calculate_name() }}
              <b style="float: left;">{{ $t('wdm16.3828') }}</b>
              <div class="btn-group" style="float: left;" v-if="other_info_func('input_protocol_name')">
                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                  !
                </button>
                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                  <table>
                    <tr v-for="(info, info_ind) in pro_inf_list.others.input_protocol_name">
                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" style="padding-top: 10px; padding-bottom: 10px;">
              <textarea class="input form-control" name="protocol_name" v-model="data.name" :placeholder="$t('wdm16.3812')" style="width: 100%;"></textarea>
              <div class="card-header" style="background-color: rgb(250, 255, 227)" title="This title has been calculating automatically. If you want to use it, please click right sided button.">
                {{ $t('wdm16.3799') }}
                <button style="float: right;" class="btn btn-success" @click="use_this_title"> {{ $t('wdm16.3875') }} </button>
                <br> <strong> {{ treatment_name }} </strong>
              </div>
            </div>
          </div>
          <div class="row" v-if="!data.id">
            <div for="name" class="col-12 card-header">
              <b style="float: left;">{{ $t('wdm16.3995') }}</b>
              <div class="btn-group" style="float: left;" v-if="other_info_func('select_protocol_group')">
                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                  !
                </button>
                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                  <table>
                    <tr v-for="(info, info_ind) in pro_inf_list.others.select_protocol_group">
                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-sm-10" style="padding-top: 10px; padding-bottom: 10px;">
              <v-select v-model="selected_protocol_group" name="protocol_group" :options="protocol_group"></v-select>
            </div>
            <div class="col-sm-1" style="padding-top: 10px; padding-bottom: 10px;">
              <button class="btn btn-info" @click="add_protocol_group()"><i class="fa fa-plus"></i></button>
            </div>
          </div>
          <br>
          <div class="row" v-if="!data.id">
            <div class="col-sm-12">
              <b style="float: left;"> {{ $t('wdm16.3783') }} </b>
            </div>
            <div class="col-sm-12" style="padding-top: 10px; padding-left: 20px;" v-for="(grp, grp_ind) in data.protocol_group_list">
              <button class="btn-info" @click="delete_protocol_group(grp_ind)" style="float: left;"><i class="fa fa-minus"></i></button>
              &nbsp&nbsp&nbsp {{ grp_ind + 1 }} -) {{ grp.label }}
            </div>
          </div>
          <br>
          <br>
          <div class="row">
            <label for="name" class="col-12 col-form-label"><b>{{ $t('wdm16.4074') }}</b></label>
            <div class="col-12">
              <textarea v-model="data.protocol_note" rows="3" class="input form-control" :placeholder="$t('wdm16.6211') + ' ' + $t('wdm16.' + variable_langs_id_list.languages[lang])"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div role="group">
        <button type="button" class="btn btn-success drug-add-button" @click="add_drug()">{{ $t('wdm16.4079') }}</button>
      </div>
      <div id="accordion" role="tablist" aria-multiselectable="true" style="padding-left: 7px; padding-right: 5px;">
        <template v-for="(drg, drg_ind) in data.drugs">
          {{ calculate_info_list(drg_ind) }}
          <div class="card-header" style="border-radius: 20px; background-color: rgb(49, 187, 187);">
            <div style="float: left; border-radius: 12px; width: 25px; height: 25px; background-color: #3a9d5d; font-family: Comic Sans MS; text-align: center;">
              <b> {{ drg_ind + 1 }} </b>
            </div>
            <div style="float: left; padding-left: 20px; padding-right: 20px;">
              <button @click="drug_go_up(drg_ind)" title="Drug goes up" class="btn-success">
                <a class="fa fa-hand-o-up fa-1x"></a>
              </button>
              <button @click="drug_go_down(drg_ind)" title="Drug goes down" class="btn-primary">
                <a class="fa fa-hand-o-down fa-1x"></a>
              </button>
              <button @click="delete_drug_from_protocol(drg_ind)" title="Remove this drug from protocol" class="btn-info">
                <a class="fa fa-minus-circle fa-1x"></a>
              </button>
              <button @click="add_premedication(drg_ind, 'before')" title="Add drug premedication before this drug" style="background-color: rgb(32, 229, 32); font-family: Comic Sans MS;">B</button>
              <button @click="add_premedication(drg_ind, 'during')" title="Add drug premedication during this drug" style="background-color: rgb(255, 153, 0); font-family: Comic Sans MS;">D</button>
              <button @click="add_premedication(drg_ind, 'after')" title="Add drug premedication after this drug" style="background-color: rgb(237, 237, 42); font-family: Comic Sans MS;">A</button>
              <button @click="add_mix(drg_ind, 'none', 'drug')" title="Add mixing drug with this drug" style="background-color: rgb(181, 221, 221); font-family: Comic Sans MS;">Mx</button>
              <button @click="add_prem_note(drg_ind)" title="Add note for this drug" style="background-color: rgb(183, 106, 106); font-family: Comic Sans MS;">N</button>
              <button @click="add_prem_lab(drg_ind)" title="Add laboratory for this drug" style="background-color: rgb(88, 239, 239); font-family: Comic Sans MS;">L</button>
              <button @click="add_prem_msg(drg_ind)" title="Add message for this drug for sending to patient" style="background-color: rgb(206, 148, 235); font-family: Comic Sans MS;">M</button>
            </div>
            <h5 class="mb-0" style="cursor:pointer;" data-toggle="collapse" data-parent="#accordion" :href="'#day' + drg_ind" aria-expanded="true">  
                        <div v-if="drg.drug"> {{ drg.drug.label }} </div>
                        <div v-else>{{ $t('wdm16.4101') }}</div>
                       </h5>
          </div>
          <div :id="'day' + drg_ind" class="collapse" style="padding-left: 20px; padding-right: 15px;  padding-top: 0px; padding-bottom: 3px;">
            <div>
              <table>
                <thead>
                  <tr>
                    <th style="width: 20%;"></th>
                    <th style="width: 27%;"></th>
                    <th style="width: 27%;"></th>
                    <th style="width: 26%;"></th>
                  </tr>
                </thead>
                <tbody>
                  <!--NOOOOOOOOOOOOOOOOOOOOOOOOOOTTTTTTTTTTTTTTTTTTTTTTEEEEEEEEEEEEEEEEEEEEE-->
                  <template v-if="drg.premedications.note.list.length">
                    <tr style="background-color: rgb(183, 106, 106); border-bottom: solid 1px black; border-top: solid 1px black;">
                      <th style="border-radius: 5px;">
                        <div v-if="drg.drug" style="text-align: left;">{{ drg.drug.label }} {{ $t('wdm16.3758') }} </div>
                        <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                      </th>
                      <th>
                      </th>
                      <th></th>
                      <th>
                        <div style="height: 20px; width: 26px; border-radius: 13px; background-color: #bb2323; font-family: Comic Sans MS; float: right; text-align: center; box-shadow: -2px -2px;">N</div>
                      </th>
                    </tr>
                    <tr v-for="nt, nt_ind in drg.premedications.note.list" style="background-color: rgb(241, 192, 192);">
                      <td style="vertical-align: top;">
                        <div style="width: 100%; padding: 3px;">
                          <strong v-if="drg.drug"> {{ drg.drug.label }} {{ $t('wdm16.3938') }} - {{ nt_ind + 1 }} </strong>
                          <strong v-else> {{ $t('wdm16.4204') }} ({{ $t('wdm16.3938') }} - {{ nt_ind + 1 }} ) </strong>
                        </div>
                        <div class="btn-group" role="group" style="padding: 3px;">
                          <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                            <strong>{{ $t('wdm16.3796') }}</strong>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <button type="button" class="dropdown-item" @click="delete_this_premedication(drg_ind, nt_ind, 'note')">{{ $t('wdm16.4398') }}</button>
                          </div>
                        </div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'text_note_drug', nt_ind)">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.text_note_drug">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                      <td style="vertical-align: top;">
                        <textarea v-model="nt.note" style="background-color: transparent; width: 100%;" :placeholder="$t('wdm16.6211') + ' ' + $t('wdm16.' + variable_langs_id_list.languages[lang])"></textarea>
                      </td>
                      <td style="width: 10%; vertical-align: top;">
                        <div v-if="drg.drug === null" style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_note_drug_days', nt_ind)">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_note_drug_days">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div>
                          <select :id="'select-drug-day-nt_' + drg_ind + '_' + nt_ind" style="width: 50%; background-color: transparent; padding: 3px;">
                            <option disabled value="">{{ $t('wdm16.4055') }}</option>
                            <option v-for="day, day_ind in drg.day" :value="day">
                              {{ 'day-' + day }}
                            </option>
                          </select>
                          <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_day_note(drg_ind, nt_ind)"><i class="fa fa-plus"></i></button>
                        </div>
                        <div v-for="nt_drug_day, nt_drug_day_ind in nt.drug_days" style="padding-top: 3px;">
                          <button class="btn-success" style="background-color:transparent;" @click="delete_drug_day_note(drg_ind, nt_ind, nt_drug_day_ind)"><i class="fa fa-minus"></i></button>
                          <span v-if="drg.drug">{{drg.drug.label + ' / day-'+ nt_drug_day }} </span>
                          <span v-else> {{ $t('wdm16.4101') }} {{' / day-'+ nt_drug_day }} </span>
                        </div>
                      </td>
                      <td style="vertical-align: top;">
                        <div>
                          <select @change="select_timing(drg_ind, nt_ind, 'note')" v-model="nt.type" id="timing" style="width: 50%; background-color: transparent; padding: 3px;">
                            <option value="after">{{ $t('wdm16.3855') }}</option>
                            <option value="before">{{ $t('wdm16.3876') }}</option>
                            <option value="during">{{ $t('wdm16.4035') }}</option>
                            <option value="general">{{ $t('wdm16.1118') }}</option>
                          </select>
                          <select :id="'select-day-nt_' + drg_ind + '_' +  nt_ind" style="width: 50%; background-color: transparent; padding: 3px;">
                            <option disabled value="">{{ $t('wdm16.4055') }}</option>
                            <option value="0">{{ $t('wdm16.3984') }}</option>
                            <option v-for="day in parseInt(drg.period)" :value="day">
                              {{ day }}
                            </option>
                          </select>
                          <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_extra(drg_ind, nt_ind, 'note', 'nt')"><i class="fa fa-plus"></i></button>
                        </div>
                        <div v-for="day, dy_ind in nt.day" style="padding-top: 3px;">
                          <button class="btn-success" style="background-color:transparent;" @click="delete_day_note(drg_ind, nt_ind, dy_ind)"><i class="fa fa-minus"></i></button> {{ day === 0 ? $t('wdm16.3984') : nt.type === 'before' ? Math.abs(day) + ' ' + $t('wdm16.6172') : day + $t('wdm16.6207') }}
                        </div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_note_days', nt_ind)">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_note_days">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <!--MMMMMMMMMMMMMMMMMMMMMMMeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeessssssssssssssssssssssssssssssssssssssssssageeeeeeeeeeeeeeeeee-->
                  <template v-if="drg.premedications.message_to_patient.list.length">
                    <tr style="background-color: rgb(206, 148, 235);  border-bottom: solid 1px black; border-top: solid 1px black;">
                      <th style="border-radius: 5px;">
                        <div v-if="drg.drug" style="text-align: left;">{{ drg.drug.label }} messages</div>
                        <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                      </th>
                      <th>
                      </th>
                      <th></th>
                      <th>
                        <div style="height: 20px; width: 26px; border-radius: 13px; background-color: #cc66ff; font-family: Comic Sans MS; float: right; text-align: center; box-shadow: -2px -2px;">M</div>
                      </th>
                    </tr>
                    <tr v-for="msg, msg_ind in drg.premedications.message_to_patient.list" style="background-color: rgb(209, 189, 219);">
                      <td style="vertical-align: top;">
                        <div style="width: 100%; padding: 3px;">
                          <strong v-if="drg.drug"> {{ drg.drug.label }} {{ $t('wdm16.3945') }} - {{ msg_ind + 1 }} </strong>
                          <strong v-else> {{ $t('wdm16.4101') }} {{ $t('wdm16.3945') }} - {{ msg_ind + 1 }} </strong>
                        </div>
                        <div class="btn-group" role="group" style="padding: 3px;">
                          <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                            <strong>{{ $t('wdm16.3796') }}</strong>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <button type="button" class="dropdown-item" @click="delete_this_premedication(drg_ind, msg_ind, 'message_to_patient')">{{ $t('wdm16.4398') }}</button>
                          </div>
                        </div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'text_msg_drug', msg_ind)">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.text_msg_drug">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                      <td>
                        <textarea v-model="msg.message" style="background-color: transparent; width: 100%;" :placeholder="$t('wdm16.6157')"></textarea>
                      </td>
                      <td style="width: 10%; vertical-align: top;">
                        <div v-if="drg.drug === null" style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_msg_drug_days', msg_ind)">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_msg_drug_days">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div>
                          <select :id="'select-drug-day-msg_' + drg_ind + '_' + msg_ind" style="width: 50%; background-color: transparent; padding: 3px;">
                            <option disabled value="">{{ $t('wdm16.4055') }}</option>
                            <option v-for="day, day_ind in drg.day" :value="day">
                              {{ 'day-' + day }}
                            </option>
                          </select>
                          <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_day_msg(drg_ind, msg_ind)"><i class="fa fa-plus"></i></button>
                        </div>
                        <div v-for="msg_drug_day, msg_drug_day_ind in msg.drug_days" style="padding-top: 3px;">
                          <button class="btn-success" style="background-color:transparent;" @click="delete_drug_day_msg(drg_ind, msg_ind, msg_drug_day_ind)"><i class="fa fa-minus"></i></button>
                          <span v-if="drg.drug">{{drg.drug.label + ' / day-'+ msg_drug_day }} </span>
                          <span v-else> {{ $t('wdm16.4101') }} {{' / day-'+ msg_drug_day }} </span>
                        </div>
                      </td>
                      <td style="vertical-align: top;">
                        <div>
                          <select @change="select_timing(drg_ind, msg_ind, 'message_to_patient')" v-model="msg.type" id="timing" style="width: 50%; background-color: transparent; padding: 3px;">
                            <option value="after">{{ $t('wdm16.3855') }}</option>
                            <option value="before">{{ $t('wdm16.3876') }}</option>
                            <option value="during">{{ $t('wdm16.4035') }}</option>
                            <option value="general">{{ $t('wdm16.1118') }}</option>
                          </select>
                          <select :id="'select-day-msg_' + drg_ind + '_' +  msg_ind" style="width: 50%; background-color: transparent; padding: 3px;">
                            <option disabled value="">{{ $t('wdm16.4055') }}</option>
                            <option value="0">{{ $t('wdm16.3984') }}</option>
                            <option v-for="day in parseInt(drg.period)" :value="day">
                              {{ day }}
                            </option>
                          </select>
                          <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_extra(drg_ind, msg_ind, 'message_to_patient', 'msg')"><i class="fa fa-plus"></i></button>
                        </div>
                        <div v-for="day, dy_ind in msg.day" style="padding-top: 3px;">
                          <button class="btn-success" style="background-color:transparent;" @click="delete_day_msg(drg_ind, msg_ind, dy_ind)"><i class="fa fa-minus"></i></button> {{ day === 0 ? $t('wdm16.3984') : msg.type === 'before' ? Math.abs(day) + ' ' + $t('wdm16.6172') : day + $t('wdm16.6207') }}
                        </div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_msg_days', msg_ind)">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_msg_days">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <!--LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB-->
                  <template v-if="drg.premedications.laboratory.list.length">
                    <tr style="background-color: rgb(88, 239, 239);  border-bottom: solid 1px black; border-top: solid 1px black;">
                      <th style="border-radius: 5px;">
                        <div v-if="drg.drug" style="text-align: left;">{{ drg.drug.label }} {{ $t('wdm16.4256') }}</div>
                        <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                      </th>
                      <th>
                      </th>
                      <th></th>
                      <th>
                        <div style="height: 20px; width: 26px; border-radius: 13px; background-color: #66ffff; font-family: Comic Sans MS; float: right; text-align: center; box-shadow: -2px -2px;">L</div>
                      </th>
                    </tr>
                    <tr v-for="lab, lab_ind in drg.premedications.laboratory.list" style="background-color: rgb(135, 229, 229);">
                      <td style="vertical-align: top;">
                        <div style="width: 100%; padding: 3px;">
                          <strong v-if="drg.drug"> {{ drg.drug.label }} {{ $t('wdm16.4256') }} - {{ lab_ind + 1 }} </strong>
                          <strong v-else> {{ $t('wdm16.4101') }} {{ $t('wdm16.4256') }} - {{ lab_ind + 1 }} </strong>
                        </div>
                        <div class="btn-group" role="group" style="padding: 3px;">
                          <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                            <strong>{{ $t('wdm16.3796') }}</strong>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <button type="button" class="dropdown-item" @click="delete_this_premedication(drg_ind, lab_ind, 'laboratory')">{{ $t('wdm16.4398') }}</button>
                          </div>
                        </div>
                      </td>
                      <td style="vertical-align: top;">
                        <div>
                          <select :id="'select-drug-lab_' + drg_ind + '_' + lab_ind" style="background-color: transparent; padding: 3px;">
                            <option disabled value="">{{ $t('wdm16.4055') }}</option>
                            <option v-for="lab in laboratory_tests" :value="lab">
                              {{ lab }}
                            </option>
                          </select>
                          <button class="btn-info" style="background-color:transparent;" @click="add_test_to_drug(drg_ind, lab_ind)"><i class="fa fa-plus"></i></button>
                        </div>
                        <div v-for="test, test_ind in lab.tests">
                          <button class="btn-success" style="background-color:transparent;" @click="delete_test_in_this_drug(drg_ind, lab_ind, test_ind)"><i class="fa fa-minus"></i></button> {{ test }}
                        </div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_lab_tests', lab_ind)">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_lab_tests">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                      <td style="width: 10%; vertical-align: top;">
                        <div v-if="drg.drug === null" style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_lab_drug_days', lab_ind)">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_lab_drug_days">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div>
                          <select :id="'select-drug-day-lab_' + drg_ind + '_' + lab_ind" style="width: 50%; background-color: transparent; padding: 3px;">
                            <option disabled value="">{{ $t('wdm16.4055') }}</option>
                            <option v-for="day, day_ind in drg.day" :value="day">
                              {{ 'day-' + day }}
                            </option>
                          </select>
                          <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_day_lab(drg_ind, lab_ind)"><i class="fa fa-plus"></i></button>
                        </div>
                        <div v-for="lab_drug_day, lab_drug_day_ind in lab.drug_days" style="padding-top: 3px;">
                          <button class="btn-success" style="background-color:transparent;" @click="delete_drug_day_lab(drg_ind, lab_ind, lab_drug_day_ind)"><i class="fa fa-minus"></i></button>
                          <span v-if="drg.drug">{{drg.drug.label + ' / day-'+ lab_drug_day }} </span>
                          <span v-else> {{ $t('wdm16.4101') }} {{' / day-'+ lab_drug_day }} </span>
                        </div>
                        <!--                                 <br>                              
                                <div>
                                  <input style="width: 70px; background-color: transparent;" type="text" :id="'minutes_drug_relation-lab-drug_' + drg_ind + '_' + lab_ind"> {{ $t('wdm16.4397') }} ago 
                                  <button class="btn-info" style="background-color:transparent;" @click="add_time_to_drug_lab_minutes_drug_relation(drg_ind, lab_ind)"><i class="fa fa-plus"></i></button>
                                </div>
                                <div v-for="min, min_ind in lab.minutes_drug_relation" style="padding-top: 3px;"> 
                                  <button class="btn-success" style="background-color:transparent;" @click="delete_minutes_drug_relation_drug_lab(drg_ind, lab_ind, min_ind)"><i class="fa fa-minus"></i></button> {{ min }} {{ $t('wdm16.4048') }}
                                </div> -->
                      </td>
                      <td style="vertical-align: top;">
                        <div>
                          <div>
                            <select @change="select_timing(drg_ind, lab_ind, 'laboratory')" v-model="lab.type" id="timing" style="width: 50%; background-color: transparent; padding: 3px;">
                              <option value="after">{{ $t('wdm16.3855') }}</option>
                              <option value="before">{{ $t('wdm16.3876') }}</option>
                              <option value="during">{{ $t('wdm16.4035') }}</option>
                              <option value="general">{{ $t('wdm16.1118') }}</option>
                            </select>
                            <select :id="'select-day-lab_' + drg_ind + '_' +  lab_ind" style="width: 50%; background-color: transparent; padding: 3px;">
                              <option disabled value="">{{ $t('wdm16.4055') }}</option>
                              <option value="0">{{ $t('wdm16.3984') }}</option>
                              <option v-for="day in parseInt(drg.period)" :value="day">
                                {{ day }}
                              </option>
                            </select>
                            <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_extra(drg_ind, lab_ind, 'laboratory', 'lab')"><i class="fa fa-plus"></i></button>
                          </div>
                          <div v-for="day, dy_ind in lab.day" style="padding-top: 3px;">
                            <button class="btn-success" style="background-color:transparent;" @click="delete_day_lab(drg_ind, lab_ind, dy_ind)"><i class="fa fa-minus"></i></button> {{ day === 0 ? $t('wdm16.3984') : lab.type === 'before' ? Math.abs(day) + ' ' + $t('wdm16.6172') : day + $t('wdm16.6207') }}
                          </div>
                          <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_lab_days', lab_ind)">
                            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                              !
                            </button>
                            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                              <table>
                                <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_lab_days">
                                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <!--radar BEEEEEEEEEEEEEEEFFFFFFFFFFFFFFFFFFFOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOORRRRRRRRRRRRRRRRRRRRRRRRRREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE-->
                  <template v-if="drg.before.length">
                    <tr style="background-color: rgb(32, 229, 32);  border-bottom: solid 1px black; border-top: solid 1px black;">
                      <th style="border-radius: 5px;">
                        <div v-if="drg.drug" style="text-align: left;"> {{ drg.drug.label }} {{ $t('wdm16.4158') }} </div>
                        <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                      </th>
                      <th></th>
                      <th></th>
                      <th>
                        <div style="height: 20px; width: 26px; border-radius: 13px; background-color: #00ff00; font-family: Comic Sans MS; float: right; text-align: center; box-shadow: -2px -2px;">B</div>
                      </th>
                    </tr>
                    <template v-for="prem, prem_ind in drg.before">
                      <tr style="background-color: rgb(158, 238, 158); border-bottom: solid 1px black;">
                        <td style="vertical-align: top">
                          <input type="checkbox" name="" v-model="search_all_drugs"> {{ $t('wdm16.3860') }}
                          <v-select v-if="search_all_drugs" style="padding: 3px;" :name="'center-drug_before' + drg_ind" v-model="prem.drug" ref="prem.drug" :on-search="drug_search" :options="options"></v-select>
                          <v-select v-else style="padding: 3px;" :name="'center-drug_before' + drg_ind" v-model="prem.drug" ref="prem.drug" :options="favorite_drugs"></v-select>
                          <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'select_the_drug')">
                            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                              !
                            </button>
                            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                              <table>
                                <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.select_the_drug">
                                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <br>
                          <div style="padding: 20px;">
                            <button @click="delete_this_drug('before', drg_ind, prem_ind, 'none')" title="Remove this drug from protocol" class="btn-info">
                              <a class="fa fa-minus-circle fa-1x"></a>
                            </button>
                            <button @click="add_mix(drg_ind, prem_ind, 'before')" title="Add mixing drug with this drug" style="background-color: rgb(181, 221, 221); font-family: Comic Sans MS;">Mx</button>
                          </div>
                        </td>
                        <td style="vertical-align: top;">
                          <table>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3837') }} </b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'input_dosage')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.input_dosage">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div style="padding: 3px;">
                                  <input type="text" v-model="prem.dosage" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3808') }}</b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'select_unit')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.select_unit">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <v-select style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug' + drg_ind" v-model="prem.unit" ref="prem.unit" :options="unit_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.3925') }}</b></td>
                              <td>
                                <v-select style="padding: 3px;" :name="'center-drug_before' + drg_ind" v-model="prem.frequency" ref="prem.frequency" :options="frequency"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.4038') }}</b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'select_administration')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.select_administration">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <v-select style="padding: 3px; float: left; width: 100%;" :name="'center-drug' + drg_ind" v-model="prem.dosage_form" ref="prem.dosage_form" :options="dosage_form_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.3938') }}</b></td>
                              <td>
                                <div style="padding: 3px;">
                                  <textarea value="prem.drug_note" v-model="prem.drug_note" style="background-color: transparent; width: 100%; padding: 3px;" :placeholder="$t('wdm16.6157')"></textarea>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="width: 10%; vertical-align: top;">
                          <table>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.4031') }}</b></td>
                              <td>
                                <v-select :name="'center-drug_fluid' + drg_ind" v-model="prem.fluid" ref="prem.fluid" :options="fluid_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3901') }}</b>
                                <!--before info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'input_volume')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.input_volume">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.fluid_value" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> ml
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.4221') }}</b>
                                <!--before info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'input_duration')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.input_duration">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.duration" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> {{ $t('wdm16.3980') }}
                              </td>
                            </tr>
                            <tr>
                              <td> <b style="float: left;">{{ $t('wdm16.4059') }}</b> </td>
                              <td> {{ prem.fluid_value / (prem.duration/60) }} cc/hour </td>
                            </tr>
                            <tr>
                              <td> <b style="float: left;">{{ $t('wdm16.4134') }}</b> </td>
                              <td> {{ (( prem.fluid_value / (prem.duration/60)) * 33) / 100 }} drop/minute </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;"> {{ $t('wdm16.4240') }} </b>
                                <!--before info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'input_duration_wait')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.input_duration_wait">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.duration_wait" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> {{ $t('wdm16.4397') }}
                              </td>
                            </tr>
                            <tr>
                              <td style="vertical-align: top;"> <b style="float: left;">{{ $t('wdm16.4048') }}</b> </td>
                              <td>
                                <div>
                                  <input style="width: 70px; background-color: transparent;" type="text" :id="'minutes_drug_relation-before_' + drg_ind + '_' + prem_ind">
                                  <button class="btn-info" style="background-color:transparent;" @click="add_time_to_prem_minutes_drug_relation(drg_ind, prem_ind, 'before')"><i class="fa fa-plus"></i></button>
                                </div>
                                <div v-for="min, min_ind in prem.minutes_drug_relation" style="padding-top: 3px;">
                                  <button class="btn-success" style="background-color:transparent;" @click="delete_minutes_drug_relation_prem(drg_ind, prem_ind, min_ind, 'before')"><i class="fa fa-minus"></i></button> {{ min }} {{ $t('wdm16.4048') }}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="vertical-align: top;">
                          <table>
                            <tr>
                              <td>
                                <div v-if="drg.drug" style="text-align: left;">
                                  <b style="float: left;"> {{ drg.drug.label }} days </b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'select_prem_drug_days')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.select_prem_drug_days">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                              </td>
                              <td style="padding: 3px;">
                                <select :id="'select-drug-day-before_' + drg_ind + '_' + prem_ind" style="width: 70%; background-color: transparent; padding: 3px;">
                                  <option disabled value="">{{ $t('wdm16.4055') }}</option>
                                  <option v-for="day, day_ind in drg.day" :value="day">
                                    {{ day }}
                                  </option>
                                </select>
                                <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_day_prem(drg_ind, prem_ind, 'before')"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                              </td>
                              <td style="padding: 3px;">
                                <div v-for="prem_drug_day, prem_drug_day_ind in prem.drug_days" style="padding: 5px; border-radius: 5px; background-color: rgb(32, 229, 32); box-shadow: -2px -2px; width: 70%;">
                                  <button class="btn-success" style="background-color:transparent; border-radius: 5px;" @click="delete_drug_day_prem(drg_ind, prem_ind, prem_drug_day_ind, 'before')"><i class="fa fa-minus"></i></button>
                                  <span v-if="drg.drug"> {{drg.drug.label + '/ Day '+ prem_drug_day }} </span>
                                  <span v-else> {{ $t('wdm16.4101') }} {{ '/ Day '+ prem_drug_day }} </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;"> add days </b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', 'none', 'select_prem_days')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].info_list.select_prem_days">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td style="padding: 3px;">
                                <select :id="'select-day-before_' + drg_ind + '_' +  prem_ind" style="width: 70%;  background-color: transparent; padding: 3px;">
                                  <option disabled value="">{{ $t('wdm16.4055') }}</option>
                                  <option value="0">{{ $t('wdm16.3984') }}</option>
                                  <option v-for="day in parseInt(drg.period)" :value="day">
                                    {{ day }}
                                  </option>
                                </select>
                                <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_prem(drg_ind, prem_ind, 'before')"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <div v-for="day, dy_ind in prem.day" style="padding: 5px; border-radius: 5px; background-color: rgb(32, 229, 32); box-shadow: -2px -2px; width: 70%;">
                                  <button class="btn-success" style="background-color:transparent; border-radius: 5px;" @click="delete_day_prem(drg_ind, prem_ind, dy_ind, 'before')"><i class="fa fa-minus"></i></button> {{ day === 0 ? 'in the same day ' : day + ' day before' }}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <!--mixxxxxxxxxxxxxxxxxxxxxxxxxxxx beforeeeeeeeeeeeeeeeeeeeeeeee-->
                      <template v-if="prem.drug_mix.length">
                        <tr v-for="mx, mx_index in prem.drug_mix" style="background-color: rgb(158, 238, 158);">
                          <td style="vertical-align: top;">
                            <input type="checkbox" name="" v-model="search_all_drugs"> {{ $t('wdm16.3860') }}
                            <v-select v-if="search_all_drugs" style="padding: 3px;" :name="'center-drug_before-mix' + drg_ind" v-model="mx.drug" ref="mx.drug" :on-search="drug_search" :options="options"></v-select>
                            <v-select v-else style="padding: 3px;" :name="'center-drug_before-mix' + drg_ind" v-model="mx.drug" ref="mx.drug" :options="favorite_drugs"></v-select>
                            <b v-if="prem.drug"> ({{ prem.drug.label + ' ' + $t('wdm16.4028') }} ) </b>
                            <b v-else="prem.drug"> {{ $t('wdm16.3777') }} </b>
                            <div class="btn-group" role="group" style="padding: 3px; float: left;">
                              <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                                <strong>{{ $t('wdm16.3796') }}</strong>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <button type="button" class="dropdown-item" @click="delete_this_drug('before', drg_ind, prem_ind, mx_index)">{{ $t('wdm16.4398') }}</button>
                              </div>
                            </div>
                            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', mx_index, 'select_the_drug')">
                              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                !
                              </button>
                              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                <table>
                                  <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].drug_mix[mx_index].info_list.select_the_drug">
                                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </td>
                          <td>
                            <table>
                              <tr>
                                <td>
                                  <b style="float: left;">{{ $t('wdm16.3837') }}</b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', mx_index, 'input_dosage')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].drug_mix[mx_index].info_list.input_dosage">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div style="padding: 3px;">
                                    <input type="text" v-model="mx.dosage" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b style="float: left;">{{ $t('wdm16.3808') }}</b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'before', mx_index, 'select_unit')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].before[prem_ind].drug_mix[mx_index].info_list.select_unit">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <v-select style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug_mix' + drg_ind" v-model="mx.unit" ref="mx.unit" :options="unit_options"></v-select>
                                </td>
                              </tr>
                              <tr>
                                <td><b style="float: left;">{{ $t('wdm16.3938') }}</b></td>
                                <td>
                                  <div style="padding: 3px;">
                                    <textarea value="mx.drug_note" v-model="mx.drug_note" style="background-color: transparent; width: 100%; padding: 3px;" :placeholder="$t('wdm16.6157')"></textarea>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </template>
                    </template>
                  </template>
                  <!-- radar DUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUURRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRİİİİİİİİİİİİİİİİİİİİİİİİİNGGGGGGGGGGG-->
                  <template v-if="drg.during.length">
                    <tr style="background-color:  rgb(255, 153, 0);  border-bottom: solid 1px black; border-top: solid 1px black;">
                      <th style="border-radius: 5px;">
                        <div v-if="drg.drug" style="text-align: left;">{{ drg.drug.label }} {{ $t('wdm16.4241') }} </div>
                        <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                      </th>
                      <th></th>
                      <th></th>
                      <th>
                        <div style="height: 20px; width: 26px; border-radius: 13px; background-color: #ff9900; font-family: Comic Sans MS; float: right; text-align: center; box-shadow: -2px -2px;">D</div>
                      </th>
                    </tr>
                    <template v-for="prem, prem_ind in drg.during">
                      <tr style="background-color: rgb(246, 195, 117); border-bottom: solid 1px black;">
                        <td style="vertical-align: top">
                          <input type="checkbox" name="" v-model="search_all_drugs"> {{ $t('wdm16.3860') }}
                          <v-select v-if="search_all_drugs" style="padding: 3px;" :name="'center-drug_during' + drg_ind" v-model="prem.drug" ref="prem.drug" :on-search="drug_search" :options="options"></v-select>
                          <v-select v-else style="padding: 3px;" :name="'center-drug_during' + drg_ind" v-model="prem.drug" ref="prem.drug" :options="favorite_drugs"></v-select>
                          <div class="btn-group" role="group" style="padding: 3px; float: left;">
                            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                              <strong>{{ $t('wdm16.3796') }}</strong>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                              <button type="button" class="dropdown-item" @click="delete_this_drug('during', drg_ind, prem_ind, 'none')">{{ $t('wdm16.4398') }}</button>
                              <button type="button" class="dropdown-item" @click="add_mix(drg_ind, prem_ind, 'during')">{{ $t('wdm16.3792') }}</button>
                            </div>
                          </div>
                          <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', 'none', 'select_the_drug')">
                            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                              !
                            </button>
                            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                              <table>
                                <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].info_list.select_the_drug">
                                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </td>
                        <td style="vertical-align: top;">
                          <table>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3837') }}</b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', 'none', 'input_dosage')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].info_list.input_dosage">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div style="padding: 3px;">
                                  <input type="text" v-model="prem.dosage" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3808') }}</b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', 'none', 'select_unit')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].info_list.select_unit">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <v-select style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug' + drg_ind" v-model="prem.unit" ref="prem.unit" :options="unit_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.3925') }}</b></td>
                              <td>
                                <v-select style="padding: 3px;" :name="'center-drug_during' + drg_ind" v-model="prem.frequency" ref="prem.frequency" :options="frequency"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.4038') }}</b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', 'none', 'select_administration')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].info_list.select_administration">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <v-select style="padding: 3px; float: left; width: 100%;" :name="'center-drug' + drg_ind" v-model="prem.dosage_form" ref="prem.dosage_form" :options="dosage_form_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.3938') }}</b></td>
                              <td>
                                <div style="padding: 3px;">
                                  <textarea value="prem.drug_note" v-model="prem.drug_note" style="background-color: transparent; width: 100%; padding: 3px;" :placeholder="$t('wdm16.6157')"></textarea>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="width: 10%; vertical-align: top;">
                          <table>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.4031') }}</b></td>
                              <td>
                                <v-select :name="'center-drug_fluid' + drg_ind" v-model="prem.fluid" ref="prem.fluid" :options="fluid_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3901') }}</b>
                                <!--during info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', 'none', 'input_volume')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].info_list.input_volume">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.fluid_value" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> ml
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.4221') }}</b>
                                <!--during info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', 'none', 'input_duration')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].info_list.input_duration">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.duration" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> {{ $t('wdm16.4397') }}
                              </td>
                            </tr>
                            <tr>
                              <td> <b style="float: left;">{{ $t('wdm16.4059') }}</b> </td>
                              <td> {{ prem.fluid_value / (prem.duration/60) }} cc/hour </td>
                            </tr>
                            <tr>
                              <td> <b style="float: left;">{{ $t('wdm16.4134') }}</b> </td>
                              <td> {{ (( prem.fluid_value / (prem.duration/60)) * 33) / 100 }} drop/minute </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;"> {{ $t('wdm16.4240') }} </b>
                                <!--during info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', 'none', 'input_duration_wait')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].info_list.input_duration_wait">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.duration_wait" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> {{ $t('wdm16.4397') }}
                              </td>
                            </tr>
                            <tr>
                              <td style="vertical-align: top;"> <b style="float: left;">{{ $t('wdm16.4048') }}</b> </td>
                              <td>
                                <div>
                                  <input style="width: 70px; background-color: transparent;" type="text" :id="'minutes_drug_relation-during_' + drg_ind + '_' + prem_ind">
                                  <button class="btn-info" style="background-color:transparent;" @click="add_time_to_prem_minutes_drug_relation(drg_ind, prem_ind, 'during')"><i class="fa fa-plus"></i></button>
                                </div>
                                <div v-for="min, min_ind in prem.minutes_drug_relation" style="padding-top: 3px;">
                                  <button class="btn-success" style="background-color:transparent;" @click="delete_minutes_drug_relation_prem(drg_ind, prem_ind, min_ind, 'during')"><i class="fa fa-minus"></i></button> {{ min }} {{ $t('wdm16.4048') }}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="vertical-align: top;">
                          <table>
                            <tr>
                              <td>
                                <div v-if="drg.drug" style="text-align: left;">
                                  <b style="float: left;"> {{ drg.drug.label }} days </b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', 'none', 'select_prem_drug_days')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].info_list.select_prem_drug_days">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                              </td>
                              <td style="padding: 3px;">
                                <select :id="'select-drug-day-during_' + drg_ind + '_' + prem_ind" style="width: 70%; background-color: transparent; padding: 3px;">
                                  <option disabled value="">{{ $t('wdm16.4055') }}</option>
                                  <option v-for="day, day_ind in drg.day" :value="day">
                                    {{ day }}
                                  </option>
                                </select>
                                <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_day_prem(drg_ind, prem_ind, 'during')"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                              </td>
                              <td style="padding: 3px;">
                                <div v-for="prem_drug_day, prem_drug_day_ind in prem.drug_days" style="padding: 5px; border-radius: 5px; background-color: rgb(32, 229, 32); box-shadow: -2px -2px; width: 70%;">
                                  <button class="btn-success" style="background-color:transparent; border-radius: 5px;" @click="delete_drug_day_prem(drg_ind, prem_ind, prem_drug_day_ind, 'during')"><i class="fa fa-minus"></i></button>
                                  <span v-if="drg.drug"> {{drg.drug.label + '/ Day '+ prem_drug_day }} </span>
                                  <span v-else> {{ $t('wdm16.4101') }} {{ '/ Day '+ prem_drug_day }} </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <div v-for="day, dy_ind in prem.day" style="padding: 5px; border-radius: 5px; background-color: rgb(32, 229, 32); box-shadow: -2px -2px; width: 70%;">
                                  {{ day === 0 ? 'in the same day ' : day + ' day during' }}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <!--mixxxxxxxxxxxxxxxxxxxxxxxxxxxx duringeeeeeeeeeeeeeeeeeeeeeee-->
                      <template v-if="prem.drug_mix.length">
                        <tr v-for="mx, mx_index in prem.drug_mix" style="background-color: #f2d6ac;">
                          <td style="vertical-align: top;">
                            <input type="checkbox" name="" v-model="search_all_drugs"> {{ $t('wdm16.3860') }}
                            <v-select v-if="search_all_drugs" style="padding: 3px;" :name="'center-drug_during-mix' + drg_ind" v-model="mx.drug" ref="mx.drug" :on-search="drug_search" :options="options"></v-select>
                            <v-select v-else style="padding: 3px;" :name="'center-drug_during-mix' + drg_ind" v-model="mx.drug" ref="mx.drug" :options="favorite_drugs"></v-select>
                            <b v-if="prem.drug"> ({{ prem.drug.label + ' ' + $t('wdm16.4028') }} ) </b>
                            <b v-else="prem.drug"> {{ $t('wdm16.3777') }} </b>
                            <div class="btn-group" role="group" style="padding: 3px; float: left;">
                              <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                                <strong>{{ $t('wdm16.3796') }}</strong>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <button type="button" class="dropdown-item" @click="delete_this_drug('during', drg_ind, prem_ind, mx_index)">{{ $t('wdm16.4398') }}</button>
                              </div>
                            </div>
                            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', mx_index, 'select_the_drug')">
                              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                !
                              </button>
                              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                <table>
                                  <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].drug_mix[mx_index].info_list.select_the_drug">
                                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </td>
                          <td>
                            <table>
                              <tr>
                                <td>
                                  <b style="float: left;">{{ $t('wdm16.3837') }}</b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', mx_index, 'input_dosage')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].drug_mix[mx_index].info_list.input_dosage">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div style="padding: 3px;">
                                    <input type="text" v-model="mx.dosage" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b style="float: left;">{{ $t('wdm16.3808') }}</b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'during', mx_index, 'select_unit')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].during[prem_ind].drug_mix[mx_index].info_list.select_unit">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <v-select style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug_mix' + drg_ind" v-model="mx.unit" ref="mx.unit" :options="unit_options"></v-select>
                                </td>
                              </tr>
                              <tr>
                                <td><b style="float: left;">{{ $t('wdm16.3938') }}</b></td>
                                <td>
                                  <div style="padding: 3px;">
                                    <textarea value="mx.drug_note" v-model="mx.drug_note" style="background-color: transparent; width: 100%; padding: 3px;" :placeholder="$t('wdm16.6157')"></textarea>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </template>
                    </template>
                  </template>
                  <!--radar DDDDDDDDDDDDDDDRRRRRRRRRRRRRRRUUUUUUUUUUUUUUUUUUUUUUUUUGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG-->
                  <template>
                    <tr style="background-color:  rgb(49, 187, 187);  border-bottom: solid 1px black; border-top: solid 1px black; box-shadow: -5px -1px">
                      <th style="border-radius: 5px;">
                        <div v-if="drg.drug" style="text-align: left;"> {{ drg.drug.label }} </div>
                        <div v-else>{{ $t('wdm16.4101') }}</div>
                      </th>
                      <th></th>
                      <th></th>
                      <th>
                        <div style="height: 20px; width: 50px; border-radius: 13px; background-color:  rgb(49, 187, 187); font-family: Comic Sans MS; float: right; text-align: center; box-shadow: -2px -2px;">DRUG</div>
                      </th>
                    </tr>
                    <tr style="background-color: rgb(157, 221, 221); border-bottom: solid 1px black;">
                      <td style="vertical-align: top">
                        <input type="checkbox" name="" v-model="search_all_drugs"> {{ $t('wdm16.3860') }}
                        <v-select v-if="search_all_drugs" style="padding: 3px;" :name="'center-drug' + drg_ind" v-model="drg.drug" ref="drg.drug" :on-search="drug_search" :options="options"></v-select>
                        <v-select v-else style="padding: 3px;" :name="'center-drug' + drg_ind" v-model="drg.drug" ref="drg.drug" :options="favorite_drugs"></v-select>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_the_drug')">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_the_drug">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                      <td style="vertical-align: top;">
                        <table>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.3837') }}</b>
                              <!--info drug dosage -->
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'input_dosage')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.input_dosage">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div style="padding: 3px;">
                                <input type="text" v-model="drg.dosage" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.3808') }}</b>
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_unit')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_unit">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <v-select style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug' + drg_ind" v-model="drg.unit" ref="drg.unit" :options="unit_options"></v-select>
                            </td>
                          </tr>
                          <tr>
                            <td><b style="float: left;">{{ $t('wdm16.3925') }}</b></td>
                            <td>
                              <v-select style="padding: 3px;" :name="'center-drug_after' + drg_ind" v-model="drg.frequency" ref="drg.frequency" :options="frequency"></v-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.4038') }}</b>
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_administration')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_administration">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <v-select style="padding: 3px; float: left; width: 100%;" :name="'center-drug' + drg_ind" v-model="drg.dosage_form" ref="drg.dosage_form" :options="dosage_form_options"></v-select>
                            </td>
                          </tr>
                          <tr>
                            <td><b style="float: left;">{{ $t('wdm16.3938') }}</b></td>
                            <td>
                              <div style="padding: 3px;">
                                <textarea value="drg.drug_note" v-model="drg.drug_note" style="background-color: transparent; width: 100%; padding: 3px;" :placeholder="$t('wdm16.6157')"></textarea>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style="width: 10%; vertical-align: top;">
                        <table>
                          <tr>
                            <td><b style="float: left;">{{ $t('wdm16.4031') }}</b></td>
                            <td>
                              <v-select :name="'center-drug_fluid' + drg_ind" v-model="drg.fluid" ref="drg.fluid" :options="fluid_options"></v-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.3901') }}</b>
                              <!--after info duration-->
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'input_volume')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.input_volume">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input type="text" v-model="drg.fluid_value" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> ml
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.4221') }}</b>
                              <!--before info duration-->
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'input_duration')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.input_duration">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input type="text" v-model="drg.duration" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> {{ $t('wdm16.4397') }}
                            </td>
                          </tr>
                          <tr>
                            <td> <b style="float: left;">{{ $t('wdm16.4059') }}</b> </td>
                            <td> {{ drg.fluid_value / (drg.duration/60) }} cc/hour </td>
                          </tr>
                          <tr>
                            <td> <b style="float: left;">{{ $t('wdm16.4134') }}</b> </td>
                            <td> {{ (( drg.fluid_value / (drg.duration/60)) * 33) / 100 }} drop/minute </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;"> {{ $t('wdm16.4240') }} </b>
                              <!--before info duration-->
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'input_duration_wait')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.input_duration_wait">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input type="text" v-model="drg.duration_wait" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> {{ $t('wdm16.4397') }}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style="vertical-align: top;">
                        <table>
                          <tr>
                            <td>
                              <b style="float: left;"> {{ $t('wdm16.1178') }} </b>
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'select_drug_days')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.select_drug_days">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <select :id="'select-drug-day_' + drg_ind" style="width: 50%; background-color: transparent; padding: 3px;">
                                <option disabled value="">{{ $t('wdm16.4055') }}</option>
                                <option v-for="day in parseInt(drg.period)" :value="day">
                                  {{ day }}
                                </option>
                              </select>
                              <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug(drg_ind)"><i class="fa fa-plus"></i></button>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style="padding: 3px;">
                              <div v-for="day, dy_ind in drg.day" style="padding: 5px; border-radius: 5px; background-color: rgb(49, 187, 187); float: left; border: solid 1px black;">
                                <strong style="padding: 3px;"> {{ day }} </strong>
                                <button class="btn-success" style="background-color:transparent;" @click="delete_drug_day(drg_ind, dy_ind)"><i class="fa fa-minus"></i></button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.1179') }}</b>
                              <!--info drug dosage -->
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'input_period')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.input_period">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input type="text" v-model="drg.period" name="drg.period" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.4574') }}</b>
                              <!--info drug dosage -->
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'input_repeat')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.input_repeat">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input type="text" v-model="drg.repeat" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.3849') }}</b>
                              <!--info drug dosage -->
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', 'none', 'input_delay')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.input_delay">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input type="text" v-model="drg.delay" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <!--mixxxxxxxxxxxxxxxxxxxxxxxxxxxx aftereeeeeeeeeeeeeeeeeeeeeee-->
                    <tr v-for="mx, mx_index in drg.drug_mix" style="background-color: #b5e6e6;">
                      <td style="vertical-align: top;">
                        <span v-if="drg.drug"> {{ drg.drug.label + ' ' + $t('wdm16.4028') }}</span>
                        <input type="checkbox" name="" v-model="search_all_drugs"> {{ $t('wdm16.3860') }}
                        <v-select v-if="search_all_drugs" :name="'center-drug_mix' + drg_ind" v-model="mx.drug" ref="mx.drug" :on-search="drug_search" :options="options"></v-select>
                        <v-select v-else :name="'center-drug_mix' + drg_ind" v-model="mx.drug" ref="mx.drug" :options="favorite_drugs"></v-select>
                        <div class="btn-group" role="group" style="padding: 3px; float: left;">
                          <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                            <strong>{{ $t('wdm16.3796') }}</strong>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <button type="button" class="dropdown-item" @click="delete_this_drug('drug', drg_ind, 'none', mx_index)">{{ $t('wdm16.4398') }}</button>
                          </div>
                        </div>
                        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', mx_index, 'select_the_drug')">
                          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                            !
                          </button>
                          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                            <table>
                              <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].drug_mix[mx_index].info_list.select_the_drug">
                                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                      <td>
                        <table>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.3837') }}</b>
                              <!--info drug dosage -->
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', mx_index, 'input_dosage')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].info_list.input_dosage">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div style="padding: 3px;">
                                <input type="text" v-model="mx.dosage" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b style="float: left;">{{ $t('wdm16.3808') }}</b>
                              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, 'none', 'drug', mx_index, 'select_unit')">
                                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                  !
                                </button>
                                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                  <table>
                                    <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].drug_mix[mx_index].info_list.select_unit">
                                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td>
                              <v-select style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug' + drg_ind" v-model="mx.unit" ref="mx.unit" :options="unit_options"></v-select>
                            </td>
                          </tr>
                          <tr>
                            <td><b style="float: left;">{{ $t('wdm16.3938') }}</b></td>
                            <td>
                              <textarea value="mx.drug_note" v-model="mx.drug_note" style="background-color: transparent; width: 100%;" :placeholder="$t('wdm16.6157')"></textarea>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </template>
                  <!-- radar AAAAAAAAAAAAAAAAAAAAAFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFTEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR-->
                  <template v-if="drg.after.length">
                    <tr style="background-color: rgb(237, 237, 42);  border-bottom: solid 1px black; border-top: solid 1px black;">
                      <th style="border-radius: 5px;">
                        <div v-if="drg.drug" style="text-align: left;">{{ drg.drug.label }} {{ $t('wdm16.3816') }} </div>
                        <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                      </th>
                      <th>
                      </th>
                      <th></th>
                      <th>
                        <div style="height: 20px; width: 26px; border-radius: 13px; background-color: rgb(300, 280, 55); font-family: Comic Sans MS; float: right; text-align: center; box-shadow: -2px -2px;">A</div>
                      </th>
                    </tr>
                    <template v-for="prem, prem_ind in drg.after">
                      <tr style="background-color: rgb(230, 230, 147); border-bottom: solid 1px black;">
                        <td style="vertical-align: top">
                          <input type="checkbox" name="" v-model="search_all_drugs"> {{ $t('wdm16.3860') }}
                          <v-select v-if="search_all_drugs" style="padding: 3px;" :name="'center-drug_after' + drg_ind" v-model="prem.drug" ref="prem.drug" :on-search="drug_search" :options="options"></v-select>
                          <v-select v-else style="padding: 3px;" :name="'center-drug_after' + drg_ind" v-model="prem.drug" ref="prem.drug" :options="favorite_drugs"></v-select>
                          <div class="btn-group" role="group" style="padding: 3px; float: left;">
                            <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                              <strong>{{ $t('wdm16.3796') }}</strong>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                              <button type="button" class="dropdown-item" @click="delete_this_drug('after', drg_ind, prem_ind, 'none')">{{ $t('wdm16.4398') }}</button>
                              <button type="button" class="dropdown-item" @click="add_mix(drg_ind, prem_ind, 'after')">{{ $t('wdm16.3792') }}</button>
                            </div>
                          </div>
                          <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'select_the_drug')">
                            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                              !
                            </button>
                            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                              <table>
                                <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.select_the_drug">
                                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </td>
                        <td style="vertical-align: top;">
                          <table>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3837') }}</b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'input_dosage')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.input_dosage">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div style="padding: 3px;">
                                  <input type="text" v-model="prem.dosage" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3808') }}</b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'select_unit')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.select_unit">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <v-select style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug' + drg_ind" v-model="prem.unit" ref="prem.unit" :options="unit_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.3925') }}</b></td>
                              <td>
                                <v-select style="padding: 3px;" :name="'center-drug_after' + drg_ind" v-model="prem.frequency" ref="prem.frequency" :options="frequency"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.4038') }}</b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'select_administration')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.select_administration">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <v-select style="padding: 3px; float: left; width: 100%;" :name="'center-drug' + drg_ind" v-model="prem.dosage_form" ref="prem.dosage_form" :options="dosage_form_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.3938') }}</b></td>
                              <td>
                                <div style="padding: 3px;">
                                  <textarea value="prem.drug_note" v-model="prem.drug_note" style="background-color: transparent; width: 100%; padding: 3px;" :placeholder="$t('wdm16.6157')"></textarea>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="width: 10%; vertical-align: top;">
                          <table>
                            <tr>
                              <td><b style="float: left;">{{ $t('wdm16.4031') }}</b></td>
                              <td>
                                <v-select :name="'center-drug_fluid' + drg_ind" v-model="prem.fluid" ref="prem.fluid" :options="fluid_options"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.3901') }}</b>
                                <!--after info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'input_volume')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.input_volume">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.fluid_value" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> ml
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;">{{ $t('wdm16.4221') }}</b>
                                <!--after info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'input_duration')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.input_duration">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.duration" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> {{ $t('wdm16.4397') }}
                              </td>
                            </tr>
                            <tr>
                              <td> <b style="float: left;">{{ $t('wdm16.4059') }}</b> </td>
                              <td> {{ prem.fluid_value / (prem.duration/60) }} cc/hour </td>
                            </tr>
                            <tr>
                              <td> <b style="float: left;">{{ $t('wdm16.4134') }}</b> </td>
                              <td> {{ (( prem.fluid_value / (prem.duration/60)) * 33) / 100 }} drop/minute </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;"> {{ $t('wdm16.4240') }} </b>
                                <!--after info duration-->
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'input_duration_wait')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.input_duration_wait">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" v-model="prem.duration_wait" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;"> {{ $t('wdm16.4397') }}
                              </td>
                            </tr>
                            <tr>
                              <td style="vertical-align: top;"> <b style="float: left;">{{ $t('wdm16.4048') }}</b> </td>
                              <td>
                                <div>
                                  <input style="width: 70px; background-color: transparent;" type="text" :id="'minutes_drug_relation-after_' + drg_ind + '_' + prem_ind">
                                  <button class="btn-info" style="background-color:transparent;" @click="add_time_to_prem_minutes_drug_relation(drg_ind, prem_ind, 'after')"><i class="fa fa-plus"></i></button>
                                </div>
                                <div v-for="min, min_ind in prem.minutes_drug_relation" style="padding-top: 3px;">
                                  <button class="btn-success" style="background-color:transparent;" @click="delete_minutes_drug_relation_prem(drg_ind, prem_ind, min_ind, 'after')"><i class="fa fa-minus"></i></button> {{ min }} {{ $t('wdm16.4048') }}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="vertical-align: top;">
                          <table>
                            <tr>
                              <td>
                                <div v-if="drg.drug" style="text-align: left;">
                                  <b style="float: left;"> {{ drg.drug.label }} {{ $t('wdm16.1178') }} </b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'select_prem_drug_days')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.select_prem_drug_days">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        v
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
                              </td>
                              <td style="padding: 3px;">
                                <select :id="'select-drug-day-after_' + drg_ind + '_' + prem_ind" style="width: 70%; background-color: transparent; padding: 3px;">
                                  <option disabled value="">{{ $t('wdm16.4055') }}</option>
                                  <option v-for="day, day_ind in drg.day" :value="day">
                                    {{ day }}
                                  </option>
                                </select>
                                <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_day_prem(drg_ind, prem_ind, 'after')"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                              </td>
                              <td style="padding: 3px;">
                                <div v-for="prem_drug_day, prem_drug_day_ind in prem.drug_days" style="padding: 5px; border-radius: 5px; background-color: rgb(32, 229, 32); box-shadow: -2px -2px; width: 70%;">
                                  <button class="btn-success" style="background-color:transparent; border-radius: 5px;" @click="delete_drug_day_prem(drg_ind, prem_ind, prem_drug_day_ind, 'after')"><i class="fa fa-minus"></i></button>
                                  <span v-if="drg.drug"> {{drg.drug.label + '/ Day '+ prem_drug_day }} </span>
                                  <span v-else> {{ $t('wdm16.4101') }} {{ '/ Day '+ prem_drug_day }} </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b style="float: left;"> add days </b>
                                <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', 'none', 'select_prem_days')">
                                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                    !
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                    <table>
                                      <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].info_list.select_prem_days">
                                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                              <td style="padding: 3px;">
                                <select :id="'select-day-after_' + drg_ind + '_' +  prem_ind" style="width: 70%;  background-color: transparent; padding: 3px;">
                                  <option disabled value="">{{ $t('wdm16.4055') }}</option>
                                  <option value="0">{{ $t('wdm16.3984') }}</option>
                                  <option v-for="day in parseInt(drg.period)" :value="day">
                                    {{ day }}
                                  </option>
                                </select>
                                <button class="btn-info" style="background-color:transparent;" @click="add_day_to_this_drug_prem(drg_ind, prem_ind, 'after')"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <div v-for="day, dy_ind in prem.day" style="padding: 5px; border-radius: 5px; background-color: rgb(32, 229, 32); box-shadow: -2px -2px; width: 70%;">
                                  <button class="btn-success" style="background-color:transparent; border-radius: 5px;" @click="delete_day_prem(drg_ind, prem_ind, dy_ind, 'after')"><i class="fa fa-minus"></i></button> {{ day === 0 ? $t('wdm16.3984') : day + $t('wdm16.6207') }}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <!--mixxxxxxxxxxxxxxxxxxxxxxxxxxxx aftereeeeeeeeeeeeeeeeeeeeeee-->
                      <template v-if="prem.drug_mix.length">
                        <tr v-for="mx, mx_index in prem.drug_mix" style="background-color: rgb(230, 230, 147);">
                          <td style="vertical-align: top;">
                            <input type="checkbox" name="" v-model="search_all_drugs"> {{ $t('wdm16.3860') }}
                            <v-select v-if="search_all_drugs" style="padding: 3px;" :name="'center-drug_after-mix' + drg_ind" v-model="mx.drug" ref="mx.drug" :on-search="drug_search" :options="options"></v-select>
                            <v-select v-else style="padding: 3px;" :name="'center-drug_after-mix' + drg_ind" v-model="mx.drug" ref="mx.drug" :options="favorite_drugs"></v-select>
                            <b v-if="prem.drug"> ({{ prem.drug.label + ' ' + $t('wdm16.4028') }} ) </b>
                            <b v-else="prem.drug"> {{ $t('wdm16.3777') }} </b>
                            <div class="btn-group" role="group" style="padding: 3px; float: left;">
                              <button id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%; background-color: transparent; border-radius: 5px;">
                                <strong>{{ $t('wdm16.3796') }}</strong>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <button type="button" class="dropdown-item" @click="delete_this_drug('after', drg_ind, prem_ind, mx_index)">{{ $t('wdm16.4398') }}</button>
                              </div>
                            </div>
                            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', mx_index, 'select_the_drug')">
                              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                !
                              </button>
                              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                <table>
                                  <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].drug_mix[mx_index].info_list.select_the_drug">
                                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </td>
                          <td>
                            <table>
                              <tr>
                                <td>
                                  <b style="float: left;">{{ $t('wdm16.3837') }}</b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', mx_index, 'input_dosage')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].drug_mix[mx_index].info_list.input_dosage">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div style="padding: 3px;">
                                    <input type="text" v-model="mx.dosage" name="sdfs" style="background-color: transparent; width: 40%; padding: 5px; float: left;">
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b style="float: left;">{{ $t('wdm16.3808') }}</b>
                                  <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, 'after', mx_index, 'select_unit')">
                                    <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                                      !
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                                      <table>
                                        <tr v-for="(info, info_ind) in pro_inf_list.drugs[drg_ind].after[prem_ind].drug_mix[mx_index].info_list.select_unit">
                                          <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                                          <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <v-select style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug_mix' + drg_ind" v-model="mx.unit" ref="mx.unit" :options="unit_options"></v-select>
                                </td>
                              </tr>
                              <tr>
                                <td><b style="float: left;">{{ $t('wdm16.3938') }}</b></td>
                                <td>
                                  <div style="padding: 3px;">
                                    <textarea value="mx.drug_note" v-model="mx.drug_note" style="background-color: transparent; width: 100%; padding: 3px;" :placeholder="$t('wdm16.6157')"></textarea>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
      <!-- akordiyon bitiyor. -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';

import OptionService from '@/services/option';
import Cleave from 'vue-cleave';
import vSelect from 'vue-select';
// /* global $ */

export default {
  name: 'ProtocolModifier',
  mounted () {},
  computed: mapGetters({
    lang: 'lang'
  }),
  props: {
    pains: {
      type: Object,
      required: false,
      default: () => {}
    },
    data: {
      type: Object,
      required: false,
      default: { scope: '', name: '', protocol_group_list: [], drugs: [{ drug: '', dosage: 0, delay: 0, unit: { caption: 'mg/m²', value: 'mg_m2' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'intra venöz', value: '12' }, fluid: '', fluid_value: 0, duration: 0, duration_wait: 0, repeat: 1, drug_note: '', drug_mix: [], drug_order: 1, day: [], before: [], during: [], after: [], period: 21, is_daily: 0, premedications: { laboratory: { list: [] }, note: { list: [] }, message_to_patient: { list: [] } } }] }
    },
    mode: String
  },
  data () {
    return {
      types: ['before', 'after', 'during'],
      pro_inf_list: { others: {}, drugs: {} },
      search_all_drugs: false,
      treatment_name: '',
      mix: { drug_note: '' },
      unit_options: require('@/options/unit').options,
      dosage_form_options: require('@/options/dosage_form').options,
      protocol_group: require('@/options/protocol_group').options,
      selected_protocol_group: '',
      frequency: require('@/options/frequency').options,
      laboratory_tests: require('@/options/laboratory').options,
      favorite_drugs: require('@/options/favorite_drugs').options,
      fluid_options: require('@/options/fluids').options,
      info_options: require('@/options/info').options,
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      computed_name: '',
      count: 0
    };
  },
  methods: {
    other_info_func: function (subject) {
      let res = false;
      if (subject === 'select_protocol_group') {
        if (this.data.protocol_group_list.length === 0) {
          res = true;
        }
      } else if (subject === 'input_protocol_name') {
        if (this.data.name.length === 0) {
          res = true;
        }
      } else if (subject === 'select_visibility') {
        if (this.data.scope === '') {
          res = true;
        }
      }
      if (res === true) {
        this.pro_inf_list.others[subject] = this.info_options[subject];
      } else {
        this.$delete(this.pro_inf_list.others, subject);
      }
      return res;
    },
    calculate_info_list: function (drg_ind) {
      let data = this.data.drugs[drg_ind];
      this.pro_inf_list.drugs[drg_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_drug_days: {}, input_period: {}, input_repeat: {}, input_delay: {}, select_the_drug: {}, select_note_drug_days: {}, select_lab_drug_days: {}, select_msg_drug_days: {}, select_note_days: {}, select_msg_days: {}, select_lab_days: {}, select_lab_tests: {}, text_note_drug: {}, text_msg_drug: {} }, before: {}, after: {}, during: {}, drug_mix: {} };
      for (let mx in data.drug_mix) {
        this.pro_inf_list.drugs[drg_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_unit: {}, select_the_drug: {} } };
      }
      for (let pr in this.types) {
        for (let pr_ind in data[this.types[pr]]) {
          this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
          for (let mx in data[this.types[pr]][pr_ind].drug_mix) {
            this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
          }
        }
      }
    },
    info_func: function (info_drg_ind, prem_ind, type, mix_ind, subject, other_ind = 'none') { // other ind note, msg ve lab
      let res = false;
      if (subject === 'input_dosage') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let dosage_second = this.data.drugs[info_drg_ind].dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let dosage_second = this.data.drugs[info_drg_ind].drug_mix[mix_ind].dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let dosage_second = this.data.drugs[info_drg_ind][type][prem_ind].dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let dosage_second = this.data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_planned_dosage') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let dosage_second = this.data.drugs[info_drg_ind].planned_dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let dosage_second = this.data.drugs[info_drg_ind].drug_mix[mix_ind].planned_dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let dosage_second = this.data.drugs[info_drg_ind][type][prem_ind].planned_dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let dosage_second = this.data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_volume') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let volume_second = this.data.drugs[info_drg_ind].fluid_value;
            let dosage_chars = volume_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let volume_second = this.data.drugs[info_drg_ind][type][prem_ind].fluid_value;
            let dosage_chars = volume_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_duration') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let duration_second = this.data.drugs[info_drg_ind].duration;
            let dosage_chars = duration_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let duration_second = this.data.drugs[info_drg_ind][type][prem_ind].duration;
            let dosage_chars = duration_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'select_unit') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            if (this.data.drugs[info_drg_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            if (this.data.drugs[info_drg_ind].drug_mix[mix_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            if (this.data.drugs[info_drg_ind][type][prem_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            if (this.data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'select_administration') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            if (this.data.drugs[info_drg_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            if (this.data.drugs[info_drg_ind].drug_mix[mix_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            if (this.data.drugs[info_drg_ind][type][prem_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            if (this.data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'input_duration_wait') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let duration_wait_second = this.data.drugs[info_drg_ind].duration_wait;
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let duration_wait_second = this.data.drugs[info_drg_ind].drug_mix[mix_ind].duration_wait;
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let duration_wait_second = this.data.drugs[info_drg_ind][type][prem_ind].duration_wait;
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let duration_wait_second = this.data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].duration_wait;
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'select_prem_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
        if (this.data.drugs[info_drg_ind][type][prem_ind].drug_days.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'select_prem_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
        if (this.data.drugs[info_drg_ind][type][prem_ind].day.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'select_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].day.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'input_period') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let period_second = this.data.drugs[info_drg_ind].period;
        let period_chars = period_second.toString().split('');
        let det = false;
        for (let i in period_chars) {
          if (this.info_options[subject].chars.value.indexOf(period_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < period_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'input_repeat') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let repeat_second = this.data.drugs[info_drg_ind].repeat;
        let repeat_chars = repeat_second.toString().split('');
        let det = false;
        for (let i in repeat_chars) {
          if (this.info_options[subject].chars.value.indexOf(repeat_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < repeat_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'input_delay') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let delay_second = this.data.drugs[info_drg_ind].delay;
        let delay_chars = delay_second.toString().split('');
        let det = false;
        for (let i in delay_chars) {
          if (this.info_options[subject].chars.value.indexOf(delay_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < delay_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'select_the_drug') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            if (this.data.drugs[info_drg_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          } else {
            if (this.data.drugs[info_drg_ind].drug_mix[mix_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            if (this.data.drugs[info_drg_ind][type][prem_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          } else {
            if (this.data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          }
        }
      } else if (subject === 'select_note_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.note.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_msg_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.laboratory.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_note_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.note.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_msg_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.laboratory.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_tests') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.laboratory.list[other_ind].tests.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'text_note_drug') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.note.list[other_ind].note === undefined || this.data.drugs[info_drg_ind].premedications.note.list[other_ind].note === '') {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'text_msg_drug') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.data.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].message === undefined || this.data.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].message === '') {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      }
      return res;
    },
    add_protocol_group: function () {
      if (this.selected_protocol_group === null) {
        alert(this.$t('wdm16.6225'));
        return;
      }
      let x = true;
      for (let i in this.data.protocol_group_list) {
        if (this.data.protocol_group_list[i].value === this.selected_protocol_group.value) {
          x = false;
          break;
        }
      }
      if (x === true) {
        this.data.protocol_group_list.push(this.selected_protocol_group);
      }
    },
    delete_protocol_group: function (grp_ind) {
      this.data.protocol_group_list.splice(grp_ind, 1);
    },
    delete_this_premedication (drg_ind, ind, type) {
      this.data.drugs[drg_ind].premedications[type].list.splice(ind, 1);
    },
    delete_this_drug (type, drg_ind, prem_ind, mx_index) {
      if (type === 'drug') {
        if (mx_index === 'none') {
          this.data.drugs.splice(drg_ind, 1);
        } else {
          this.data.drugs[drg_ind].drug_mix.splice(mx_index, 1);
        }
      } else { // before during after
        if (mx_index === 'none') {
          this.data.drugs[drg_ind][type].splice(prem_ind, 1);
        } else {
          this.data.drugs[drg_ind][type][prem_ind].drug_mix.splice(mx_index, 1);
        }
      }
    },
    add_prem_note (drg_ind) {
      this.data.drugs[drg_ind].premedications.note.list.push({ type: 'general', day: [], drug_days: [], note: '' });
    },
    add_prem_lab (drg_ind) {
      this.data.drugs[drg_ind].premedications.laboratory.list.push({ minutes_drug_relation: [], day: [], drug_days: [], note: '', tests: [] });
    },
    add_prem_msg (drg_ind) {
      this.data.drugs[drg_ind].premedications.message_to_patient.list.push({ type: 'general', day: [], drug_days: [], send_languages: [], message: '' });
    },
    add_drug: function () {
      this.data.drugs.push({ drug: '', dosage: 0, delay: 0, unit: { caption: 'mg/m²', value: 'mg_m2' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'intra venöz', value: '12' }, fluid: '', fluid_value: 0, duration: 0, duration_wait: 0, repeat: 1, drug_note: '', drug_mix: [], drug_order: 1, day: [], before: [], during: [], after: [], period: 21, is_daily: 0, premedications: { laboratory: { list: [] }, note: { list: [] }, message_to_patient: { list: [] } } });
    },
    add_premedication (drg_ind, type) {
      let i = this.data.drugs[drg_ind][type].length - 1;
      let drg_ord = 0;
      if (type && drg_ind !== undefined) {
        if (this.data.drugs[drg_ind][type][i] === undefined) {
          drg_ord = 1;
        } else {
          drg_ord = parseInt(this.data.drugs[drg_ind][type][i]['drug_order']) + 1;
        }
      }
      if (type !== 'during') {
        this.data.drugs[drg_ind][type].push({ drug: '', dosage: 0, unit: { caption: 'mg', value: 'mg' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'intra venöz', value: '12' }, fluid: '', fluid_value: 0, duration: 0, duration_wait: 0, drug_order: drg_ord, day: [], minutes_drug_relation: [], drug_note: '', drug_days: [], drug_mix: [] });
      } else {
        this.data.drugs[drg_ind][type].push({ drug: '', dosage: 0, unit: { caption: 'mg', value: 'mg' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'intra venöz', value: '12' }, fluid: '', fluid_value: 0, duration: 0, duration_wait: 0, drug_order: drg_ord, day: [0], minutes_drug_relation: [], drug_note: '', drug_days: [], drug_mix: [] });
      }
    },
    add_mix (drg_ind, prem_ind, type) {
      if (type === 'drug') {
        if (this.data.drugs[drg_ind].drug_mix.length !== 0 && this.data.drugs[drg_ind].drug_mix[this.data.drugs[drg_ind].drug_mix.length - 1].drug === undefined) {
          alert('Please first fill the previous drug mix row.');
          return;
        }
        this.data.drugs[drg_ind].drug_mix.push({ drug: '', unit: { caption: 'mg', value: 'mg' }, dosage: 0, drug_note: '' });
      } else {
        if (this.data.drugs[drg_ind][type][prem_ind].drug_mix.length !== 0 && this.data.drugs[drg_ind][type][prem_ind].drug_mix[this.data.drugs[drg_ind][type][prem_ind].drug_mix.length - 1].drug === undefined) {
          alert('Please first fill the previous drug mix row.');
          return;
        }
        this.data.drugs[drg_ind][type][prem_ind].drug_mix.push({ drug: '', unit: { caption: 'mg', value: 'mg' }, dosage: 0, drug_note: '' });
      }
    },
    drug_search: function (search, loading) {
      if (this.search_all_drugs === true) {
        if (search.length > 2) {
          loading(true);
          let data = { 'search': search, 'wdm_type': 'wdm2' };
          OptionService.search_wdm_option(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                this.options = resp.data.result;
              } else {
                this.options = [];
              }
              loading(false);
            });
        }
      }
    },
    fluid_search: function (search, loading) {
      if (search.length > 2) {
        loading(true);
        let data = { 'search': search, 'wdm_type': 'wdm2' };
        OptionService.search_wdm_option(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.fluid_options = resp.data.result;
            } else {
              this.fluid_options = [];
            }
            loading(false);
          });
      }
    },
    add_drug_type: function (type, drug_id) {
      let i = this.data.drugs[drug_id][type].length - 1;
      if (type && drug_id !== undefined) {
        if (this.data.drugs[drug_id][type][i] === undefined) {
          this.data.drugs[drug_id][type].push({ drug_order: 1 });
        } else {
          let last_order = this.data.drugs[drug_id][type][i]['drug_order'];
          this.data.drugs[drug_id][type].push({ drug_order: ++last_order });
        }
      }
    },
    pop_drugs_type: function (type, drug_id, id) {
      if (type !== 'main') {
        this.data.drugs[drug_id][type].splice(id, 1);
      } else {
        this.data.drugs.splice(drug_id, 1);
      }
    },
    use_this_title: function () {
      this.data.name = this.treatment_name;
      this.$forceUpdate();
    },
    calculate_name: function () {
      let str = '';
      for (let i in this.data.drugs) {
        let data = this.data.drugs;
        if (data[i].drug && data[i].day && data[i].period && data[i].repeat && data[i].dosage && data[i].unit !== null) {
          str = str + data[i].drug.label + ' (' + data[i].dosage + ' ' + data[i].unit.label + ' | days:' + data[i].day + ' | p:' + data[i].period + ' | r:' + data[i].repeat + ')';
        }
        if (parseInt(i) !== this.data.drugs.length - 1) {
          str = str + ', ';
        }
      }
      if (this.treatment_name !== str) {
        this.treatment_name = str;
      }
    },
    reset_protocol_data: function () {
      this.data.drugs = JSON.parse(JSON.stringify(this.selected_protocol.drugs));
      this.$forceUpdate();
    },
    delete_drug_from_protocol (drg_ind) { // this drug_index comes from protocol_data.drugs
      if (confirm('Do you want to remove this drug from protocol?')) {
        this.data.drugs.splice(drg_ind, 1);
        this.$forceUpdate();
      }
    },
    drug_go_up (drg_ind) { // this drug_index comes from protocol_data.drugs
      if (drg_ind !== 0) {
        this.data.drug_order = [];
        let arr = [];
        arr = this.data.drugs[drg_ind];
        this.data.drugs[drg_ind] = this.data.drugs[drg_ind - 1];
        this.data.drugs[drg_ind - 1] = arr;
        this.$forceUpdate();
      }
    },
    drug_go_down (drg_ind) { // this drug_index comes from protocol_data.drugs
      if (drg_ind !== this.data.drugs.length - 1) {
        this.data.drug_order = [];
        let arr = [];
        arr = this.data.drugs[drg_ind];
        this.data.drugs[drg_ind] = this.data.drugs[drg_ind + 1];
        this.data.drugs[drg_ind + 1] = arr;
        this.$forceUpdate();
      }
    },
    delete_test_in_this_drug (d_index, lab_ind, test_ind) {
      this.data.drugs[d_index].premedications.laboratory.list[lab_ind].tests.splice(test_ind, 1);
    },
    add_test_to_drug (d_index, lab_ind) {
      let value = document.getElementById('select-drug-lab_' + d_index + '_' + lab_ind).value;
      if (this.data.drugs[d_index].premedications.laboratory.list[lab_ind].tests.indexOf(value) === -1) {
        this.data.drugs[d_index].premedications.laboratory.list[lab_ind].tests.push(value);
      }
    },
    delete_day_msg (d_index, msg_ind, dy_ind) {
      this.data.drugs[d_index].premedications.message_to_patient.list[msg_ind].day.splice(dy_ind, 1);
    },
    delete_day_note (d_index, nt_ind, dy_ind) {
      this.data.drugs[d_index].premedications.note.list[nt_ind].day.splice(dy_ind, 1);
    },
    delete_day_lab (d_index, lab_ind, dy_ind) {
      this.data.drugs[d_index].premedications.laboratory.list[lab_ind].day.splice(dy_ind, 1);
    },
    select_timing: function (d_index, pr_ind, type) {
      if (this.data.drugs[d_index].premedications[type].list[pr_ind].type !== 'before' && this.data.drugs[d_index].premedications[type].list[pr_ind].type !== 'after') {
        this.data.drugs[d_index].premedications[type].list[pr_ind].day = [];
        this.data.drugs[d_index].premedications[type].list[pr_ind].day.push(0);
      }
    },
    add_day_to_this_drug_extra (d_index, pr_ind, type, abrv) {
      let value = parseInt(document.getElementById('select-day-' + abrv + '_' + d_index + '_' + pr_ind).value);
      if (this.data.drugs[d_index].premedications[type].list[pr_ind].day.indexOf(value) === -1) {
        if (this.data.drugs[d_index].premedications[type].list[pr_ind].type === 'before' || this.data.drugs[d_index].premedications[type].list[pr_ind].type === 'after') {
          this.data.drugs[d_index].premedications[type].list[pr_ind].day.push(value);
        } else {
          if (value === 0) {
            this.data.drugs[d_index].premedications[type].list[pr_ind].day.push(value);
          }
        }
      }
    },
    delete_drug_day_msg (d_index, msg_ind, msg_drug_day_ind) {
      this.data.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.splice(msg_drug_day_ind, 1);
    },
    delete_drug_day_note (d_index, nt_ind, nt_drug_day_ind) {
      this.data.drugs[d_index].premedications.note.list[nt_ind].drug_days.splice(nt_drug_day_ind, 1);
    },
    delete_drug_day_lab (d_index, lab_ind, lab_drug_day_ind) {
      this.data.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.splice(lab_drug_day_ind, 1);
    },
    add_day_to_this_drug_day_msg (d_index, msg_ind) {
      let value = parseInt(document.getElementById('select-drug-day-msg_' + d_index + '_' + msg_ind).value);
      if (this.data.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.indexOf(value) === -1) {
        this.data.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.push(value);
      }
    },
    add_day_to_this_drug_day_note (d_index, nt_ind) {
      if (document.getElementById('select-drug-day-nt_' + d_index + '_' + nt_ind).value !== '') {
        let value = parseInt(document.getElementById('select-drug-day-nt_' + d_index + '_' + nt_ind).value);
        if (this.data.drugs[d_index].premedications.note.list[nt_ind].drug_days.indexOf(value) === -1) {
          this.data.drugs[d_index].premedications.note.list[nt_ind].drug_days.push(value);
        }
      } else {
        alert('please select the drug day.');
      }
    },
    add_day_to_this_drug_day_lab (d_index, lab_ind) {
      let value = parseInt(document.getElementById('select-drug-day-lab_' + d_index + '_' + lab_ind).value);
      if (this.data.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.indexOf(value) === -1) {
        this.data.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.push(value);
      }
    },
    delete_minutes_drug_relation_drug_lab (d_index, lab_ind, min_ind) {
      this.data.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_relation.splice(min_ind, 1);
    },
    add_time_to_drug_lab_minutes_drug_relation (d_index, lab_ind) {
      let value = parseInt(document.getElementById('minutes_drug_relation-lab-drug_' + d_index + '_' + lab_ind).value);
      if (this.data.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_relation.indexOf(value) === -1 && Number.isInteger(parseInt(value))) {
        this.data.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_relation.push(value);
      }
    },
    add_time_to_prem_minutes_drug_relation (d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('minutes_drug_relation-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      if (this.data.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.indexOf(value) === -1 && Number.isInteger(parseInt(value))) {
        this.data.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.push(value);
      }
    },
    delete_minutes_drug_relation_prem (d_index, prem_ind, min_ind, prem_type) {
      this.data.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.splice(min_ind, 1);
    },
    delete_drug_day_prem (d_index, prem_ind, prem_drug_day_ind, prem_type) {
      this.data.drugs[d_index][prem_type][prem_ind].drug_days.splice(prem_drug_day_ind, 1);
    },
    add_day_to_this_drug_day_prem (d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('select-drug-day-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      if (this.data.drugs[d_index][prem_type][prem_ind].drug_days.indexOf(value) === -1) {
        this.data.drugs[d_index][prem_type][prem_ind].drug_days.push(value);
      }
    },
    delete_drug_day (d_index, dy_ind) {
      this.data.drugs[d_index].day.splice(dy_ind, 1);
    },
    delete_day_prem (d_index, prem_ind, dy_ind, prem_type) {
      this.data.drugs[d_index][prem_type][prem_ind].day.splice(dy_ind, 1);
    },
    add_day_to_this_drug_prem (d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('select-day-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      if (this.data.drugs[d_index][prem_type][prem_ind].day.indexOf(value) === -1) {
        this.data.drugs[d_index][prem_type][prem_ind].day.push(value);
      }
    },
    add_day_to_this_drug: function (d_index) {
      let value = parseInt(document.getElementById('select-drug-day_' + d_index).value);
      if (this.data.drugs[d_index].day.indexOf(value) === -1) {
        this.data.drugs[d_index].day.push(value);
      }
    },
    protocol_add_lab_after_day: function (lab_index) {
      let value = parseInt(document.getElementById('select-protocol-lab-day-after' + '_' + lab_index).value);
      if (this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.indexOf(value) === -1) {
        this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.push(value);
      }
    },
    protocol_add_lab_before_day: function (lab_index) {
      let value = parseInt(document.getElementById('select-protocol-lab-day-before' + '_' + lab_index).value);
      value = 0 - value;
      if (this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.indexOf(value) === -1) {
        this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.push(value);
      }
    },
    protocol_add_lab_initial_day: function (lab_index) {
      if (this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.indexOf(1) === -1) {
        this.treatment_data.protocol_premedications.laboratory.list[lab_index].day.push(1);
      }
    },
    lab_add_to_protocol: function (lab_index) {
      let lab_value = document.getElementById('select-protocol-lab' + '_' + lab_index).value;
      if (this.treatment_data.protocol_premedications.laboratory.list[lab_index].tests.indexOf(lab_value) === -1) {
        this.treatment_data.protocol_premedications.laboratory.list[lab_index].tests.push(lab_value);
      }
    }
  },
  watch: {
    lang: function (nlang) {
      for (let i in this.protocol_group) {
        this.protocol_group[i].label = this.protocol_group[i].translation[this.lang];
      }
    }
  },
  components: {
    Cleave,
    vSelect
  }
};

</script>

<style>
.protocol-header {
  width: 98%;
  border: 2px solid #cfd8dc;
  /* border-radius: 5px; */
  padding: 5px;
  margin-left: 12px;
}

.protocol-table {
  border: 2px solid #cfd8dc;
}

.drug-add-button {
  margin: 10px;
}

.table-before {
  background: rgba(46, 204, 113, 0.6);
}

.table-during {
  background: rgba(52, 152, 219, 0.6)
}

.table-after {
  background: rgba(241, 196, 15, 0.6);
}

.table-center {
  background: rgba(223, 228, 234, 0.2);
  border-top: 3px solid black;
}

.protocol-select {
  background: #fff;
  border-radius: 0px;
}

.protocol-time-area {
  background: rgba(236, 240, 241, 1.0);
  padding: 5px;
  width: 100%;
  margin: 0px;
}

.protocol-food-time {
  background: rgba(189, 195, 199, 1.0);
  padding: 5px;
  width: 100%;
  margin: 0px;
}

.protocol-dosage {
  background: rgba(149, 165, 166, 1.0);
  padding: 5px;
  width: 100%;
  margin: 0px;
}

.bg-required-area {
  background: rgba(255, 0, 0, 0.3);
}


/* .table-bordered th, .table-bordered td {
    border: 2px solid #cfd8dc;
  } */

</style>

