<template>
  <div>
    <b-card header-tag="header" footer-tag="footer">
      <div v-if="!from_add_edit">
        <b-nav tabs>
          <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_settings_protocol-list-view') !== -1">
            <router-link :to="'/whsmodules/chemotherapy/protocol-list'"> {{ $t('wdm16.4408') }} </router-link>
          </b-nav-item>
          <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_settings_add-protocol') !== -1" active>
            <router-link :to="'/whsmodules/chemotherapy/protocol-add'">{{ $t('wdm16.4167') }} </router-link>
          </b-nav-item>
        </b-nav>
      </div>
      <br>
      <b-row>
        <b-col sm="12" md="12">
          <b-card class="card-accent-primary" header-tag="header">
            <div slot="header">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '136')">?</b-badge>
              <strong>Protokol Genel Bilgileri</strong>
            </div>
            <b-row>
              <b-col sm="10" md="10"></b-col>
              <b-col sm="2" md="2">
                <button v-if="!hide_save_button" type="button" class="btn btn-success" style="width: 100%;" @click="show_confirm_modal('protocol_add')">{{ $t('wdm16.4061') }}</button>
              </b-col>
            </b-row>
            <div class="form-group row">
              <div class="col-sm-2">
                <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '137')">?</b-badge>
                <strong> Protokol adı </strong>
              </div>
              <div class="col-sm-8"> {{ treatment_data.name ? treatment_data.name : 'Protokol ilaçları seçimi sonrası otomatik oluşacaktır' }} </div>
              <div class="col-sm-2 pull right">
                <b-button @click="$refs.selected_protocol_second.calculate_name()" block variant="primary">İsim Güncelle</b-button>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-2">
                <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '138')">?</b-badge>
                <strong> Kısa ad </strong>
              </div>
              <div class="col-sm-10">
                <input type="text" name="protocol_abbr_name" class="form-control" v-model="protocol_data.abbr_name"> </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-2">
                <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '139')">?</b-badge>
                <strong> Referans </strong>
              </div>
              <div class="col-sm-10">
                <textarea v-model="protocol_data.protocol_reference" class="form-control" rows="3"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-2">
                <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '140')">?</b-badge>
                <b>{{ $t('wdm16.3995') }}</b>
                <div class="btn-group" style="float: left;" v-if="other_info_func('select_protocol_group')">
                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                    !
                  </button>
                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                    <table>
                      <tr v-for="(info, info_ind) in pro_inf_list.others.select_protocol_group">
                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <v-select v-if="!protocol_data.id" v-model="selected_protocol_group" name="protocol_group" :options="protocol_group"></v-select>
              </div>
              <div class="col-sm-2">
                <button class="btn btn-info" @click="add_protocol_group()"><i class="fa fa-plus"></i></button>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-2"></div>
              <div class="col-sm-10">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '141')">?</b-badge>
                    <strong style="color: red;"> {{ $t('wdm16.3783') }} </strong>
                  </div>
                </div>
                <div v-for="(grp, grp_ind) in protocol_data.protocol_group_list" class="form-group row">
                  <div class="col-sm-12">
                    <button class="btn btn-info" @click="delete_protocol_group(grp_ind)"><i class="fa fa-minus"></i></button> <strong> {{ grp.label }} </strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row" v-if="false">
              <div class="col-sm-2">
                <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '142')">?</b-badge>
                <strong> {{ $t('wdm16.4067') }} </strong>
                <div class="btn-group" style="float: left;" v-if="other_info_func('select_visibility')">
                  <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                    !
                  </button>
                  <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                    <table>
                      <tr v-for="(info, info_ind) in pro_inf_list.others.select_visibility">
                        <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                        <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-sm-10">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input class="form-radio" type="radio" name="visibility" id="scope.private" v-model="protocol_data.scope" value="private"> {{ $t('wdm16.3756') }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input class="form-radio" type="radio" name="visibility" id="scope.shared" v-model="protocol_data.scope" value="shared"> {{ $t('wdm16.3878') }}
                  </label>
                </div>
                <div class="form-check form-check-inline" v-if="user.permissions_result.indexOf('wisdom') !== -1">
                  <label class="form-check-label">
                    <input class="form-radio" type="radio" name="visibility" id="scope.public" v-model="protocol_data.scope" value="public"> {{ $t('wdm16.4336') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-2">
                <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '143')">?</b-badge>
                <strong> Protokol notu </strong>
              </div>
              <div class="col-sm-10">
                <textarea v-model="protocol_data.protocol_note" class="form-control" rows="5"></textarea>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <selected-protocol-second ref="selected_protocol_second" :is_new_protocol="true" :treatment_data="treatment_data" :real_protocol_data="real_protocol_data" :pro_inf_list="pro_inf_list"></selected-protocol-second>
    </b-card>
    <modal v-if="confirm_modal" @close="confirm_modal = false">
      <h3 slot="header">Konfirmasyon</h3>
      <div slot="body">
        <confirm-form :data="confirm_data"></confirm-form>
      </div>
      <div slot="footer">
        <button v-if="confirm_data.permission" type="button" class="btn btn-success" @click="Confirm_Functions(confirm_data.func_name)">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="confirm_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>
<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import { API_BASE_URL } from '@/config';
import {
  default as router
} from '@/router';
import {
  default as ProtocolBuilder
} from '@/components/widgets/ProtocolBuilder';
import {
  default as ProtocolViewer
} from '@/components/widgets/ProtocolViewer';
import {
  default as Modal
} from '@/components/widgets/Modal';
import ProtocolService from '@/services/public/protocol';
import {
  default as ConfirmForm
} from '@/components/widgets/ConfirmForm';
import vSelect from 'vue-select';
import {
  default as SelectedProtocolSecond
} from '@/components/widgets/SelectedProtocolSecond';

export default {
  name: 'WhsmodulesProtocolAddEdit',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help'
    })
  },
  props: {
    from_add_edit: {
      type: Boolean,
      required: false
    },
    data: {
      type: Object,
      required: false
    },
    mother_children: {
      type: Object,
      required: false
    },
    hide_save_button: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      store: store,
      treatment_data: { name: '', selected_protocol: {} },
      show_prepared_drug_list_modal: false,
      prepared_drugs_list: [],
      pro_inf_list: { others: {}, drugs: {} },
      search_all_drugs: false,
      treatment_name: '',
      mix: { drug_note: '' },
      unit_options: require('@/options/unit').options,
      dosage_form_options: require('@/options/dosage_form').options,
      protocol_group: require('@/options/protocol_group').options,
      selected_protocol_group: '',
      frequency: require('@/options/frequency').options,
      laboratory_tests: require('@/options/laboratory').options,
      favorite_drugs: require('@/options/favorite_drugs').options,
      fluid_options: require('@/options/fluids').options,
      user: {},
      types: ['before', 'after', 'during'],
      confirm_data: { func_name: '', info: [], permission: true },
      confirm_modal: false,
      protocol_id: false,
      show_protocol_add: false,
      info_options: require('@/options/info').options,
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      show_protocol_view: false,
      protocol_data: { 'scope': '', 'owner_type': 'user', 'name': '', 'protocol_group_list': [], 'drugs': [{ 'drug': '', 'dosage': 0, 'delay': 0, 'unit': { 'caption': 'mg/m²', 'value': 'mg_m2' }, 'frequency': { 'caption': '1x1', 'value': '1x1' }, 'type': 'drug', 'dosage_form': { 'caption': 'intra venöz', 'value': '12' }, 'fluid': '', 'fluid_value': 0, 'duration': 0, 'duration_wait': 0, 'repeat': 1, 'drug_note': '', 'drug_mix': [], 'drug_order': 1, 'day': [], 'before': [], 'during': [], 'after': [], 'period': 21, 'is_daily': 0, 'premedications': { 'laboratory': { 'list': [] }, 'note': { 'list': [] }, 'message_to_patient': { 'list': [] } } }] },
      protocol_data_new: { drugs: [{ day: [] }] },
      protocol_data_view: {},
      protocol_url: false,
      protocol_url_global: false,
      protocol_columns: ['name', 'delete'],
      protocol_columns_global: ['name', 'favorite'],
      real_protocol_data: {}
    };
  },
  beforeCreate: function() {
    if (router.currentRoute.name === 'chemotherapy_protocol_edit') {
      if (this.$route.query.from && this.$route.query.from === 'user') {
        ProtocolService.get_one_cb_document({ 'bucket': 'hospital', 'key': this.$route.query.protocol_id })
          .then(resp => {
            this.protocol_data = Object.assign({}, this.protocol_data, resp.data.result);
            this.treatment_data.selected_protocol = this.protocol_data;
            this.real_protocol_data = JSON.parse(JSON.stringify(this.treatment_data.selected_protocol));
            this.calculate_info_list_new('all_drugs');
          });
      } else {
        ProtocolService.get(this.$route.query.protocol_id)
          .then(resp => {
            this.protocol_data = Object.assign({}, this.protocol_data, resp.data);
            if (!this.from_add_edit) {
              this.protocol_data.owner_type = 'wisdom';
            }
            this.treatment_data.selected_protocol = this.protocol_data;
            this.real_protocol_data = JSON.parse(JSON.stringify(this.treatment_data.selected_protocol));
            this.calculate_info_list_new('all_drugs');
          });
      }
    } else if (router.currentRoute.name === 'chemotherapy_protocol_add') {
      if (this.$route.query.protocol_id) {
        if (this.$route.query.from && this.$route.query.from === 'user') {
          ProtocolService.get_one_cb_document({ 'bucket': 'hospital', 'key': this.$route.query.protocol_id })
            .then(resp => {
              this.protocol_data = Object.assign({}, this.protocol_data, resp.data.result);
              this.protocol_data.name = '';
              this.$delete(this.protocol_data, 'id');
              this.treatment_data.selected_protocol = this.protocol_data;
              this.real_protocol_data = JSON.parse(JSON.stringify(this.treatment_data.selected_protocol));
              this.calculate_info_list_new('all_drugs');
              if (this.$route.query.owner_type && this.$route.query.owner_type === 'user') {
                this.protocol_data.owner_type = 'user';
                this.protocol_data.scope = 'user';
              }
            });
        } else {
          ProtocolService.get(this.$route.query.protocol_id)
            .then(resp => {
              this.protocol_data = Object.assign({}, this.protocol_data, resp.data);
              this.protocol_data.name = '';
              this.$delete(this.protocol_data, 'id');
              this.treatment_data.selected_protocol = this.protocol_data;
              this.real_protocol_data = JSON.parse(JSON.stringify(this.treatment_data.selected_protocol));
              this.calculate_info_list_new('all_drugs');
              if (!this.from_add_edit && this.$route.query.owner_type && this.$route.query.owner_type === 'user') {
                this.protocol_data.owner_type = 'user';
                this.protocol_data.scope = 'user';
              }
            });
        }
      }
    }
  },
  created: function() {
    let user = localStorage.getItem('user');
    this.user = JSON.parse(user);
    this.protocol_url = API_BASE_URL + 'protocol/private/search';
    this.protocol_url_global = API_BASE_URL + 'protocol/public/search';
    if (this.from_add_edit) {
      this.protocol_data = this.data;
    }
    if (!this.protocol_data.scope) {
      this.protocol_data.scope = 'user';
    }
    if (!this.from_add_edit && !this.$route.query.protocol_id && this.user.permissions_result.indexOf('wisdom') !== -1) {
      this.protocol_data.owner_type = 'wisdom';
    }
    this.treatment_data.selected_protocol = this.protocol_data;
    this.calculate_info_list_new('all_drugs');
  },
  methods: {
    other_info_func: function(subject) {
      let res = false;
      if (subject === 'select_protocol_group') {
        if (this.protocol_data.protocol_group_list.length === 0) {
          res = true;
        }
      } else if (subject === 'input_protocol_name') {
        if (this.protocol_data.name.length === 0) {
          res = true;
        }
      } else if (subject === 'select_visibility') {
        if (this.protocol_data.scope === '') {
          res = true;
        }
      }
      if (res === true) {
        this.pro_inf_list.others[subject] = this.info_options[subject];
      } else {
        this.$delete(this.pro_inf_list.others, subject);
      }
      return res;
    },
    Confirm_Functions: function(func_name) {
      let vm = this;
      if (!this.from_add_edit && !this.$route.query.protocol_id && this.user.permissions_result.indexOf('wisdom') !== -1) {
        this.protocol_data.owner_type = 'wisdom';
        this.protocol_data.scope = 'public';
      }
      if (func_name === 'protocol_add') {
        vm.protocol_data.drugs = JSON.parse(JSON.stringify(this.treatment_data.selected_protocol.drugs));
        if (vm.protocol_data.translation === undefined) {
          vm.protocol_data.translation = { tr: '', en: '' };
        }
        vm.protocol_data.name = vm.treatment_data.name;
        vm.protocol_data.translation.tr = vm.treatment_data.name;
        vm.protocol_data.translation.en = vm.treatment_data.name;
        for (let drg in vm.protocol_data.drugs) {
          if (vm.protocol_data.drugs[drg].drug.translation === undefined) {
            vm.protocol_data.drugs[drg].drug.translation = { tr: vm.protocol_data.drugs[drg].drug.label, en: vm.protocol_data.drugs[drg].drug.label };
          } else {
            if (vm.protocol_data.drugs[drg].drug.translation.en === undefined) {
              vm.protocol_data.drugs[drg].drug.translation.en = vm.protocol_data.drugs[drg].drug.label;
            }
            if (vm.protocol_data.drugs[drg].drug.translation.tr === undefined) {
              vm.protocol_data.drugs[drg].drug.translation.tr = vm.protocol_data.drugs[drg].drug.label;
            }
          }
          if (vm.protocol_data.drugs[drg].fluid === null) {
            vm.protocol_data.drugs[drg].fluid = '';
            for (let i in vm.types) {
              for (let pr in vm.protocol_data.drugs[drg][vm.types[i]]) {
                if (vm.protocol_data.drugs[drg][vm.types[i]][pr].fluid === null) {
                  vm.protocol_data.drugs[drg][vm.types[i]][pr].fluid = '';
                }
              }
            }
          }
        }
        if (vm.protocol_data.owner_type && vm.protocol_data.owner_type === 'user') {
          if (!vm.protocol_data.version) {
            vm.protocol_data.version = "2.1";
          }
          ProtocolService.save_my_protocol(vm.protocol_data)
            .then(resp => {
              if (vm.from_add_edit) {
                alert('Protokol sisteme kaydedildi.');
                vm.confirm_modal = false;
                if (vm.mother_children.new_protocol) {
                  vm.mother_children.new_protocol = false;
                } else {
                  vm.mother_children.new_protocol = true;
                }
              } else {
                router.push({ path: '/whsmodules/chemotherapy/protocol-list' });
              }
            });
        } else {
          vm.protocol_data.version = "2.0";
          ProtocolService.save(vm.protocol_data)
            .then(resp => {
              if (vm.from_add_edit) {
                alert('Protokol sisteme kaydedildi.');
                vm.confirm_modal = false;
                vm.mother_children.new_protocol = true;
              } else {
                router.push({ path: '/whsmodules/chemotherapy/protocol-list' });
              }
            });
        }
      }
    },
    show_confirm_modal: function(func_name) {
      this.$refs.selected_protocol_second.calculate_name();
      this.confirm_data.info = [];
      this.confirm_data.func_name = func_name;
      if (func_name === 'protocol_add') { // ilgili her bir fonksiyon için yaptığımız analizleri burayda info içine koyup gönderiyoruz.
        let det = false;
        let data = this.pro_inf_list.drugs; // diğer sayfadaki listeyi bu şekilde alıyoruz.
        for (let drg_ind in data) {
          for (let inf_ind in this.info_options) {
            if (data[drg_ind].info_list[inf_ind] !== undefined) {
              for (let inf_type in this.info_options[inf_ind]) {
                if (data[drg_ind].info_list[inf_ind][inf_type] !== undefined) {
                  let validation = data[drg_ind].info_list[inf_ind][inf_type].validation;
                  let info = this.$t('wdm16.' + this.variable_langs_id_list.info[data[drg_ind].info_list[inf_ind][inf_type].info]);
                  let drg_name = this.$t('wdm16.4101');
                  if (this.protocol_data.drugs[drg_ind].drug) {
                    drg_name = this.protocol_data.drugs[drg_ind].drug.label;
                  }
                  this.confirm_data.info.push('[ ' + this.$t('wdm16.' + this.variable_langs_id_list.info[validation]) + ' ] ' + this.$t('wdm16.4257') + '= "' + drg_name + '"; ' + ' (' + info + ')');
                  if (validation === 'required') {
                    det = true;
                  }
                }
              }
            }
            for (let i in this.types) {
              for (let pr_ind in data[drg_ind][this.types[i]]) {
                let prem_data = data[drg_ind][this.types[i]][pr_ind];
                if (prem_data.info_list[inf_ind] !== undefined) {
                  for (let inf_type in this.info_options[inf_ind]) {
                    if (prem_data.info_list[inf_ind][inf_type] !== undefined) {
                      let validation = prem_data.info_list[inf_ind][inf_type].validation;
                      let info = this.$t('wdm16.' + this.variable_langs_id_list.info[prem_data.info_list[inf_ind][inf_type].info]);
                      let drg_name = this.$t('wdm16.4101');
                      if (this.protocol_data.drugs[drg_ind][this.types[i]][pr_ind].drug) {
                        drg_name = this.protocol_data.drugs[drg_ind][this.types[i]][pr_ind].drug.label;
                      }
                      this.confirm_data.info.push('[ ' + this.$t('wdm16.' + this.variable_langs_id_list.info[validation]) + ' ] ' + this.$t('wdm16.4257') + '= "' + drg_name + '"; ' + ' (' + info + ')');
                      if (validation === 'required') {
                        det = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        let data_others = this.pro_inf_list.others;
        for (let inf_type in data_others) {
          for (let inf_ind in data_others[inf_type]) {
            let validation = data_others[inf_type][inf_ind].validation;
            let info = this.$t('wdm16.' + this.variable_langs_id_list.info[data_others[inf_type][inf_ind].info]);
            if (this.protocol_data.id && inf_type === 'select_protocol_group') { // protokol düzenleme modunda grup sormayacağız.
            } else {
              this.confirm_data.info.push('[ ' + this.$t('wdm16.' + this.variable_langs_id_list.info[validation]) + ' ] ' + ' (' + info + ')');
            }
            if (validation === 'required' && !this.protocol_data.id) {
              det = true;
            }
          }
        }
        if (this.confirm_data.info.length === 0) {
          this.confirm_data.info.push(this.$t('wdm16.6230'));
        }
        if (det === true) {
          this.confirm_data.permission = false; // yani istediğimiz zaman save butonunu aktifliğini bu şekilde pasif yapacağız.
        } else {
          this.confirm_data.permission = true; // yani istediğimiz zaman save butonunu aktifliğini bu şekilde pasif yapacağız.
        }
      }
      this.confirm_modal = true;
    },
    calculate_info_list_new: function(subject, drg_ind = 'none', prem_ind = 'none', mix_ind = 'none', drug_type = 'none') {
      if (subject === 'all_drugs') {
        this.pro_inf_list = { others: {}, drugs: {} };
        for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
          this.calculate_drug_info(drg_ind);
        }
      } else if (subject === 'new_drug') {
        this.calculate_drug_info(drg_ind);
      } else if (subject === 'delete_drug') {
        this.$delete(this.pro_inf_list.drugs, drg_ind);
      } else if (subject === 'add_premedication') {
        let pr_ind = '';
        if (Object.keys(this.pro_inf_list.drugs[drg_ind][drug_type]).length === 0) {
          pr_ind = 0;
        } else {
          pr_ind = Object.keys(this.pro_inf_list.drugs[drg_ind][drug_type]).length;
        }
        this.pro_inf_list.drugs[drg_ind][drug_type][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
      } else if (subject === 'add_mix') {
        if (drug_type === 'drug') {
          this.pro_inf_list.drugs[drg_ind].drug_mix[mix_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
        } else {
          this.pro_inf_list.drugs[drg_ind][drug_type][prem_ind].drug_mix[mix_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
        }
      } else if (subject === 'drug_go_up') {
        let arr = this.pro_inf_list.drugs[drg_ind];
        this.pro_inf_list.drugs[drg_ind] = this.pro_inf_list.drugs[drg_ind - 1];
        this.pro_inf_list.drugs[drg_ind - 1] = arr;
      } else if (subject === 'drug_go_down') {
        let arr = this.pro_inf_list.drugs[drg_ind];
        this.pro_inf_list.drugs[drg_ind] = this.pro_inf_list.drugs[drg_ind + 1];
        this.pro_inf_list.drugs[drg_ind + 1] = arr;
      }
    },
    calculate_drug_info: function(drg_ind) {
      let data = this.treatment_data.selected_protocol.drugs[drg_ind];
      this.pro_inf_list.drugs[drg_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_drug_days: {}, input_period: {}, input_repeat: {}, input_delay: {}, select_the_drug: {}, select_note_drug_days: {}, select_lab_drug_days: {}, select_msg_drug_days: {}, select_note_days: {}, select_msg_days: {}, select_lab_days: {}, select_lab_tests: {}, text_note_drug: {} }, before: {}, after: {}, during: {}, drug_mix: {} };
      for (let mx in data.drug_mix) {
        this.pro_inf_list.drugs[drg_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_unit: {}, select_the_drug: {} } };
      }
      for (let pr in this.types) {
        for (let pr_ind in data[this.types[pr]]) {
          this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
          for (let mx in data[this.types[pr]][pr_ind].drug_mix) {
            this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
          }
        }
      }
    },
    ChangeDrugModel: function(drg_ind) {
      let drg_data = this.protocol_data.drugs[drg_ind];
      if (drg_data.model === 'recipe') {
        drg_data.recipe_data = { is_scattered_model: false, scattered_day_dosage_model: [], cycles: [], record_type_of_repeats: 'one_record', status: 'continuing', patient: {}, data_structure: 'continuously', operation_type: 'new_drug_prescribed', started_at: '', ending_at: '' };
      } else {
        this.$delete(drg_data, 'recipe_data');
      }
      this.$forceUpdate();
    },
    calculate_info_list: function(drg_ind) {
      let data = this.protocol_data.drugs[drg_ind];
      this.pro_inf_list.drugs[drg_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_drug_days: {}, input_period: {}, input_repeat: {}, input_delay: {}, select_the_drug: {}, select_note_drug_days: {}, select_lab_drug_days: {}, select_msg_drug_days: {}, select_note_days: {}, select_msg_days: {}, select_lab_days: {}, select_lab_tests: {}, text_note_drug: {}, text_msg_drug: {} }, before: {}, after: {}, during: {}, drug_mix: {} };
      for (let mx in data.drug_mix) {
        this.pro_inf_list.drugs[drg_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_unit: {}, select_the_drug: {} } };
      }
      for (let pr in this.types) {
        for (let pr_ind in data[this.types[pr]]) {
          this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
          for (let mx in data[this.types[pr]][pr_ind].drug_mix) {
            this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
          }
        }
      }
    },
    info_func: function(info_drg_ind, prem_ind, type, mix_ind, subject, other_ind = 'none') { // other ind note, msg ve lab
      let res = false;
      if (subject === 'input_dosage') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let dosage_second = this.protocol_data.drugs[info_drg_ind].dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let dosage_second = this.protocol_data.drugs[info_drg_ind].drug_mix[mix_ind].dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let dosage_second = this.protocol_data.drugs[info_drg_ind][type][prem_ind].dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let dosage_second = this.protocol_data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_planned_dosage') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let dosage_second = this.protocol_data.drugs[info_drg_ind].planned_dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let dosage_second = this.protocol_data.drugs[info_drg_ind].drug_mix[mix_ind].planned_dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let dosage_second = this.protocol_data.drugs[info_drg_ind][type][prem_ind].planned_dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let dosage_second = this.protocol_data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage;
            let dosage_chars = dosage_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_volume') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let volume_second = this.protocol_data.drugs[info_drg_ind].fluid_value;
            let dosage_chars = volume_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let volume_second = this.protocol_data.drugs[info_drg_ind][type][prem_ind].fluid_value;
            let dosage_chars = volume_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_duration') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let duration_second = this.protocol_data.drugs[info_drg_ind].duration;
            let dosage_chars = duration_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let duration_second = this.protocol_data.drugs[info_drg_ind][type][prem_ind].duration;
            let dosage_chars = duration_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'select_unit') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            if (this.protocol_data.drugs[info_drg_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            if (this.protocol_data.drugs[info_drg_ind].drug_mix[mix_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            if (this.protocol_data.drugs[info_drg_ind][type][prem_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            if (this.protocol_data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'select_administration') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            if (this.protocol_data.drugs[info_drg_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            if (this.protocol_data.drugs[info_drg_ind].drug_mix[mix_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            if (this.protocol_data.drugs[info_drg_ind][type][prem_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            if (this.protocol_data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'input_duration_wait') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let duration_wait_second = this.protocol_data.drugs[info_drg_ind].duration_wait;
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let duration_wait_second = this.protocol_data.drugs[info_drg_ind].drug_mix[mix_ind].duration_wait;
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let duration_wait_second = this.protocol_data.drugs[info_drg_ind][type][prem_ind].duration_wait;
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let duration_wait_second = this.protocol_data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].duration_wait;
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'select_prem_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
        if (this.protocol_data.drugs[info_drg_ind][type][prem_ind].drug_days.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'select_prem_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
        if (this.protocol_data.drugs[info_drg_ind][type][prem_ind].day.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'select_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].day.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'input_period') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let period_second = this.protocol_data.drugs[info_drg_ind].period;
        let period_chars = period_second.toString().split('');
        let det = false;
        for (let i in period_chars) {
          if (this.info_options[subject].chars.value.indexOf(period_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < period_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'input_repeat') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let repeat_second = this.protocol_data.drugs[info_drg_ind].repeat;
        let repeat_chars = repeat_second.toString().split('');
        let det = false;
        for (let i in repeat_chars) {
          if (this.info_options[subject].chars.value.indexOf(repeat_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < repeat_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'input_delay') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let delay_second = this.protocol_data.drugs[info_drg_ind].delay;
        let delay_chars = delay_second.toString().split('');
        let det = false;
        for (let i in delay_chars) {
          if (this.info_options[subject].chars.value.indexOf(delay_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < delay_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'select_the_drug') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            if (this.protocol_data.drugs[info_drg_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          } else {
            if (this.protocol_data.drugs[info_drg_ind].drug_mix[mix_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            if (this.protocol_data.drugs[info_drg_ind][type][prem_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          } else {
            if (this.protocol_data.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          }
        }
      } else if (subject === 'select_note_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.note.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_msg_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.laboratory.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_note_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.note.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_msg_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.laboratory.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_tests') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.laboratory.list[other_ind].tests.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'text_note_drug') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.note.list[other_ind].note === undefined || this.protocol_data.drugs[info_drg_ind].premedications.note.list[other_ind].note === '') {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'text_msg_drug') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.protocol_data.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].message === undefined || this.protocol_data.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].message === '') {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      }
      return res;
    },
    add_protocol_group: function() {
      if (this.selected_protocol_group === null) {
        alert(this.$t('wdm16.6225'));
        return;
      }
      let x = true;
      for (let i in this.protocol_data.protocol_group_list) {
        if (this.protocol_data.protocol_group_list[i].value === this.selected_protocol_group.value) {
          x = false;
          break;
        }
      }
      if (x === true) {
        this.protocol_data.protocol_group_list.push(this.selected_protocol_group);
      }
    },
    delete_protocol_group: function(grp_ind) {
      this.protocol_data.protocol_group_list.splice(grp_ind, 1);
    },
    delete_this_premedication(drg_ind, ind, type) {
      this.protocol_data.drugs[drg_ind].premedications[type].list.splice(ind, 1);
    },
    delete_this_drug(type, drg_ind, prem_ind, mx_index) {
      if (type === 'drug') {
        if (mx_index === 'none') {
          this.protocol_data.drugs.splice(drg_ind, 1);
        } else {
          this.protocol_data.drugs[drg_ind].drug_mix.splice(mx_index, 1);
        }
      } else { // before during after
        if (mx_index === 'none') {
          this.protocol_data.drugs[drg_ind][type].splice(prem_ind, 1);
        } else {
          this.protocol_data.drugs[drg_ind][type][prem_ind].drug_mix.splice(mx_index, 1);
        }
      }
    },
    add_prem_note(drg_ind) {
      this.protocol_data.drugs[drg_ind].premedications.note.list.push({ type: 'general', day: [], drug_days: [], note: '' });
    },
    add_prem_lab(drg_ind) {
      this.protocol_data.drugs[drg_ind].premedications.laboratory.list.push({ minutes_drug_relation: [], day: [], drug_days: [], note: '', tests: [] });
    },
    add_prem_msg(drg_ind) {
      this.protocol_data.drugs[drg_ind].premedications.message_to_patient.list.push({ type: 'general', day: [], drug_days: [], send_languages: [], message: '' });
    },
    add_drug: function() {
      this.protocol_data.drugs.push({ drug: '', dosage: 0, delay: 0, unit: { caption: 'mg/m²', value: 'mg_m2' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'intra venöz', value: '12' }, type: 'drug', fluid: '', fluid_value: 0, duration: 0, duration_wait: 0, repeat: 1, drug_note: '', drug_mix: [], drug_order: 1, day: [], before: [], during: [], after: [], period: 21, is_daily: 0, premedications: { laboratory: { list: [] }, note: { list: [] }, message_to_patient: { list: [] } } });
    },
    add_premedication(drg_ind, type) {
      let i = this.protocol_data.drugs[drg_ind][type].length - 1;
      let drg_ord = 0;
      if (type && drg_ind !== undefined) {
        if (this.protocol_data.drugs[drg_ind][type][i] === undefined) {
          drg_ord = 1;
        } else {
          drg_ord = parseInt(this.protocol_data.drugs[drg_ind][type][i]['drug_order']) + 1;
        }
      }
      if (type !== 'during') {
        this.protocol_data.drugs[drg_ind][type].push({ drug: '', dosage: 0, unit: { caption: 'mg', value: 'mg' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'intra venöz', value: '12' }, fluid: '', fluid_value: 0, duration: 0, duration_wait: 0, drug_order: drg_ord, day: [], minutes_drug_relation: [], drug_note: '', drug_days: [], drug_mix: [] });
      } else {
        this.protocol_data.drugs[drg_ind][type].push({ drug: '', dosage: 0, unit: { caption: 'mg', value: 'mg' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'intra venöz', value: '12' }, fluid: '', fluid_value: 0, duration: 0, duration_wait: 0, drug_order: drg_ord, day: [0], minutes_drug_relation: [], drug_note: '', drug_days: [], drug_mix: [] });
      }
    },
    add_mix(drg_ind, prem_ind, type) {
      if (type === 'drug') {
        if (this.protocol_data.drugs[drg_ind].drug_mix.length !== 0 && this.protocol_data.drugs[drg_ind].drug_mix[this.protocol_data.drugs[drg_ind].drug_mix.length - 1].drug === undefined) {
          alert('Please first fill the previous drug mix row.');
          return;
        }
        this.protocol_data.drugs[drg_ind].drug_mix.push({ drug: '', unit: { caption: 'mg', value: 'mg' }, dosage: 0, drug_note: '' });
      } else {
        if (this.protocol_data.drugs[drg_ind][type][prem_ind].drug_mix.length !== 0 && this.protocol_data.drugs[drg_ind][type][prem_ind].drug_mix[this.protocol_data.drugs[drg_ind][type][prem_ind].drug_mix.length - 1].drug === undefined) {
          alert('Please first fill the previous drug mix row.');
          return;
        }
        this.protocol_data.drugs[drg_ind][type][prem_ind].drug_mix.push({ drug: '', unit: { caption: 'mg', value: 'mg' }, dosage: 0, drug_note: '' });
      }
    },
    add_drug_type: function(type, drug_id) {
      let i = this.protocol_data.drugs[drug_id][type].length - 1;
      if (type && drug_id !== undefined) {
        if (this.protocol_data.drugs[drug_id][type][i] === undefined) {
          this.protocol_data.drugs[drug_id][type].push({ drug_order: 1 });
        } else {
          let last_order = this.protocol_data.drugs[drug_id][type][i]['drug_order'];
          this.protocol_data.drugs[drug_id][type].push({ drug_order: ++last_order });
        }
      }
    },
    pop_drugs_type: function(type, drug_id, id) {
      if (type !== 'main') {
        this.protocol_data.drugs[drug_id][type].splice(id, 1);
      } else {
        this.protocol_data.drugs.splice(drug_id, 1);
      }
    },
    validateBeforeSubmit() {
      return this.$validator.validateAll().then((result) => {
        return { 'result': result };
      }).catch(() => {
        return { 'result': false };
      });
    },
    use_this_title: function() {
      this.protocol_data.name = this.treatment_name;
      this.$forceUpdate();
    },
    calculate_name: function() {
      let str = '';
      for (let i in this.protocol_data.drugs) {
        let data = this.protocol_data.drugs;
        if (data[i].type !== 'protocol_premedication') {
          if (data[i].drug && data[i].day && data[i].period && data[i].repeat && data[i].dosage && data[i].unit !== null) {
            str = str + data[i].drug.label + ' (' + data[i].dosage + data[i].unit.label + ' | days:' + this.calculate_listing_numbers(data[i].day, 'together') + ' | p:' + data[i].period + ' | r:' + data[i].repeat + ')';
          }
        }
        if (parseInt(i) !== this.protocol_data.drugs.length - 1 && str !== '') {
          str = str + ', ';
        }
      }
      this.treatment_name = str;
    },
    calculate_listing_numbers: function(list_data, list_type) {
      let day = '';
      list_data.sort(function(a, b) { return a - b });
      if (list_type === 'onebyone') {
        day = list_data.join(', ');
      } else {
        if (list_data.length === 1) {
          day = list_data[0].toString();
        } else if (list_data.length === 2) {
          day = list_data.join(', ');
        } else {
          for (let i in list_data) {
            if (i === 0) {
              day += list_data[parseInt(i)].toString();
            } else if (i === list_data.length - 1) {
              if (list_data[parseInt(i)] - 1 !== list_data[parseInt(i) - 1]) {
                day += ',' + list_data[parseInt(i)].toString();
              } else {
                day += '-' + list_data[parseInt(i)].toString();
              }
            } else {
              if (list_data[parseInt(i)] - 1 !== list_data[parseInt(i) - 1]) {
                day += ',' + list_data[parseInt(i)].toString();
              } else {
                if (list_data[parseInt(i)] + 1 !== list_data[parseInt(i) + 1]) {
                  day += '-' + list_data[parseInt(i)].toString();
                }
              }
            }
          }
        }
      }
      day += '.';
      return day;
    },
    reset_protocol_data: function() {
      this.protocol_data.drugs = JSON.parse(JSON.stringify(this.selected_protocol.drugs));
      this.$forceUpdate();
    },
    delete_drug_from_protocol(drg_ind) { // this drug_index comes from protocol_data.drugs
      if (confirm('Do you want to remove this drug from protocol?')) {
        this.protocol_data.drugs.splice(drg_ind, 1);
        this.$forceUpdate();
      }
    },
    drug_go_up(drg_ind) { // this drug_index comes from protocol_data.drugs
      if (drg_ind !== 0) {
        this.protocol_data.drug_order = [];
        let arr = [];
        arr = this.protocol_data.drugs[drg_ind];
        this.protocol_data.drugs[drg_ind] = this.protocol_data.drugs[drg_ind - 1];
        this.protocol_data.drugs[drg_ind - 1] = arr;
        this.$forceUpdate();
      }
    },
    drug_go_down(drg_ind) { // this drug_index comes from protocol_data.drugs
      if (drg_ind !== this.protocol_data.drugs.length - 1) {
        this.protocol_data.drug_order = [];
        let arr = [];
        arr = this.protocol_data.drugs[drg_ind];
        this.protocol_data.drugs[drg_ind] = this.protocol_data.drugs[drg_ind + 1];
        this.protocol_data.drugs[drg_ind + 1] = arr;
        this.$forceUpdate();
      }
    },
    delete_test_in_this_drug(d_index, lab_ind, test_ind) {
      this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].tests.splice(test_ind, 1);
    },
    add_test_to_drug(d_index, lab_ind) {
      let value = document.getElementById('select-drug-lab_' + d_index + '_' + lab_ind).value;
      if (this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].tests.indexOf(value) === -1) {
        this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].tests.push(value);
      }
    },
    delete_day_msg(d_index, msg_ind, dy_ind) {
      this.protocol_data.drugs[d_index].premedications.message_to_patient.list[msg_ind].day.splice(dy_ind, 1);
    },
    delete_day_note(d_index, nt_ind, dy_ind) {
      this.protocol_data.drugs[d_index].premedications.note.list[nt_ind].day.splice(dy_ind, 1);
    },
    delete_day_lab(d_index, lab_ind, dy_ind) {
      this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].day.splice(dy_ind, 1);
    },
    select_timing: function(d_index, pr_ind, type) {
      if (this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].type !== 'before' && this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].type !== 'after') {
        this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].day = [];
        this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].day.push(0);
      }
    },
    add_day_to_this_drug_extra(d_index, pr_ind, type, abrv) {
      let value = parseInt(document.getElementById('select-day-' + abrv + '_' + d_index + '_' + pr_ind).value);
      if (this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].day.indexOf(value) === -1) {
        if (this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].type === 'before' || this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].type === 'after') {
          this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].day.push(value);
        } else {
          if (value === 0) {
            this.protocol_data.drugs[d_index].premedications[type].list[pr_ind].day.push(value);
          }
        }
      }
    },
    delete_drug_day_msg(d_index, msg_ind, msg_drug_day_ind) {
      this.protocol_data.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.splice(msg_drug_day_ind, 1);
    },
    delete_drug_day_note(d_index, nt_ind, nt_drug_day_ind) {
      this.protocol_data.drugs[d_index].premedications.note.list[nt_ind].drug_days.splice(nt_drug_day_ind, 1);
    },
    delete_drug_day_lab(d_index, lab_ind, lab_drug_day_ind) {
      this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.splice(lab_drug_day_ind, 1);
    },
    add_day_to_this_drug_day_msg(d_index, msg_ind) {
      let value = parseInt(document.getElementById('select-drug-day-msg_' + d_index + '_' + msg_ind).value);
      if (this.protocol_data.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.indexOf(value) === -1) {
        this.protocol_data.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days.push(value);
      }
    },
    add_day_to_this_drug_day_note(d_index, nt_ind) {
      if (document.getElementById('select-drug-day-nt_' + d_index + '_' + nt_ind).value !== '') {
        let value = parseInt(document.getElementById('select-drug-day-nt_' + d_index + '_' + nt_ind).value);
        if (this.protocol_data.drugs[d_index].premedications.note.list[nt_ind].drug_days.indexOf(value) === -1) {
          this.protocol_data.drugs[d_index].premedications.note.list[nt_ind].drug_days.push(value);
        }
      } else {
        alert('please select the drug day.');
      }
    },
    add_day_to_this_drug_day_lab(d_index, lab_ind) {
      let value = parseInt(document.getElementById('select-drug-day-lab_' + d_index + '_' + lab_ind).value);
      if (this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.indexOf(value) === -1) {
        this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days.push(value);
      }
    },
    delete_minutes_drug_relation_drug_lab(d_index, lab_ind, min_ind) {
      this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_relation.splice(min_ind, 1);
    },
    add_time_to_drug_lab_minutes_drug_relation(d_index, lab_ind) {
      let value = parseInt(document.getElementById('minutes_drug_relation-lab-drug_' + d_index + '_' + lab_ind).value);
      if (this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_relation.indexOf(value) === -1 && Number.isInteger(parseInt(value))) {
        this.protocol_data.drugs[d_index].premedications.laboratory.list[lab_ind].minutes_drug_relation.push(value);
      }
    },
    add_time_to_prem_minutes_drug_relation(d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('minutes_drug_relation-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      if (this.protocol_data.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.indexOf(value) === -1 && Number.isInteger(parseInt(value))) {
        this.protocol_data.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.push(value);
      }
    },
    delete_minutes_drug_relation_prem(d_index, prem_ind, min_ind, prem_type) {
      this.protocol_data.drugs[d_index][prem_type][prem_ind].minutes_drug_relation.splice(min_ind, 1);
    },
    delete_drug_day_prem(d_index, prem_ind, prem_drug_day_ind, prem_type) {
      this.protocol_data.drugs[d_index][prem_type][prem_ind].drug_days.splice(prem_drug_day_ind, 1);
    },
    add_day_to_this_drug_day_prem(d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('select-drug-day-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      if (this.protocol_data.drugs[d_index][prem_type][prem_ind].drug_days.indexOf(value) === -1) {
        this.protocol_data.drugs[d_index][prem_type][prem_ind].drug_days.push(value);
      }
    },
    delete_drug_day(d_index, dy_ind) {
      this.protocol_data.drugs[d_index].day.splice(dy_ind, 1);
    },
    delete_day_prem(d_index, prem_ind, dy_ind, prem_type) {
      this.protocol_data.drugs[d_index][prem_type][prem_ind].day.splice(dy_ind, 1);
    },
    add_day_to_this_drug_prem(d_index, prem_ind, prem_type) {
      let value = parseInt(document.getElementById('select-day-' + prem_type + '_' + d_index + '_' + prem_ind).value);
      if (this.protocol_data.drugs[d_index][prem_type][prem_ind].day.indexOf(value) === -1) {
        this.protocol_data.drugs[d_index][prem_type][prem_ind].day.push(value);
      }
    },
    add_day_to_this_drug: function(d_index) {
      let value = parseInt(document.getElementById('select-drug-day_' + d_index).value);
      if (this.protocol_data.drugs[d_index].day.indexOf(value) === -1) {
        this.protocol_data.drugs[d_index].day.push(value);
      }
    }
  },
  components: {
    SelectedProtocolSecond,
    vSelect,
    ProtocolBuilder,
    ProtocolViewer,
    Modal,
    ConfirmForm
  }
};

</script>
<style>
.protocol-menu {
  border-right: 2px solid rgb(207, 216, 220);
}

</style>
