<template>
<div>
    <input class="input form-control" type="text" id="input_filter" v-on:keyup="laboratory_filtered()" placeholder="Search...">
    <table class="table-bordered">
        <tbody>
            <tr>
                <td>
                  <label for="name"> Date </label>
                    <div class="btn-group" style="float: left;" v-if="info_func_new('date_laboratory', data.date, 'lab_date')">
                      <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                        !
                      </button> 
                      <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                        <table>
                          <tr v-for="(info, info_ind) in pro_inf_list.lab_date.date_laboratory">
                            <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                            <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                </td>
                <td>
                  <datepicker :config="{ enableTime: true, maxDate:'today'}" class="form-control" v-model="data.date" name="lab_date"></datepicker>
                </td>
            </tr>
        </tbody>
    </table>
    <table id="laboratory_table" class="table-bordered">
        <tbody>
            <tr>
                <th>{{ $t('wdm16.3895') }}</th>
                <th>{{ $t('wdm16.4052') }}</th>
                <th>{{ $t('wdm16.3808') }}</th>
                <th>{{ $t('wdm16.4230') }}</th>
                <th>{{ $t('wdm16.4578') }}</th>
            </tr>
            <tr v-for="key in form[active_tab].param_order" v-if="form[active_tab].parameters[key].type === 'float'">
                <td>
                    <label for="name">
                      {{ form[active_tab].parameters[key].value }}
                    </label>
                    <div class="btn-group" style="float: left;" v-if="info_func_new('input_lab', data.results[key], key)">
                      <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                        !
                      </button> 
                      <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                        <table>
                          <tr v-for="(info, info_ind) in pro_inf_list[key].input_lab">
                            <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                            <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                </td>
                <td>
                  <input type="text" class="input form-control" v-model="data['results'][key]">
                </td>
                <td> {{ form[active_tab].parameters[key].unit }} </td>
                <td> {{ form[active_tab].parameters[key].lln }} </td>
                <td> {{ form[active_tab].parameters[key].uln }} </td>
            </tr>
        </tbody>
    </table>
</div> 
</template>
<script>
import Vue from 'vue';

import { mapGetters } from 'vuex';
import Cleave from 'vue-cleave';
import 'cleave.js/dist/addons/cleave-phone.cn';
import Datepicker from 'wisdom-vue-datepicker';
import vSelect from 'vue-select';


export default {
  name: 'LabEditForm',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    Datepicker,
    Cleave,
    vSelect
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {}
    },
    form: {
      type: Object,
      required: true
    },
    active_tab: String,
    catalog_key: String
  },
  data () {
    return {
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      template: null,
      options: [],
      functions: [],
      pro_inf_list: {},
      info_options: {}
    };
  },
  created () {
    this.info_options = require('@/options/info').options;
    for (let i in this.form[this.active_tab].param_order) {
      this.pro_inf_list[this.form[this.active_tab].param_order[i]] = {};
      // this.data.results[this.form[this.active_tab].param_order[i]] = '';
    }
    this.pro_inf_list.lab_date = {};
  },
  methods: {
    info_func_new: function (subject, val, doc_id, bef_val = 'none') {
      // ilgili alanın önce general item'ına bakıyoruz.
      let res = false;
      let det = false;
      if (!val && this.info_options[subject].general !== undefined) {
        this.create_subject_to_doc_id(doc_id, subject, 'general');
        this.pro_inf_list[doc_id][subject]['general'] = this.info_options[subject]['general'];
        res = true;
        det = true;
        return res;
      }
      if (!det) { this.clear_item_of_subject(doc_id, subject, 'general'); }

      if (!val) {
        this.clear_all_subjects_except_general(doc_id); // daha önce eklenmiş olan infoların olmasına gerek yok. İlgili alan boş iken tek uyarı veren mekanizma general olacak. Yani üstteki fonksiyon
        return;
      } // analizlerde gelen değer aşağıda kullanıldığından bundan sonra val boş ise devam edilmiyor.
      let chars = val.toString().split('');
      for (let k in this.info_options[subject]) {
        if (k === 'chars') {
          let det = false;
          for (let i in chars) {
            if (this.info_options[subject].chars.value.indexOf(chars[i]) === -1) {
              this.create_subject_to_doc_id(doc_id, subject, k);
              this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
              res = true;
              det = true;
              break;
            }
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'len') {
          let det = false;
          if (this.info_options[subject][k].value < chars.length) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'decimal') {
          let det = false;
          if (this.numbers_after_decimal(chars) > this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'max') {
          let det = false;
          if (parseInt(val) > this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'min') {
          let det = false;
          if (parseInt(val) < this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'bef_aft' && bef_val !== 'none' && val !== 'none') {
          let det = false;
          let percent = Math.abs((bef_val - val) * 100 / bef_val);
          if (percent >= this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        } else if (k === 'date') {
          let det = false;
          let percent = Math.abs((bef_val - val) * 100 / bef_val);
          if (percent >= this.info_options[subject][k].value) {
            this.create_subject_to_doc_id(doc_id, subject, k);
            this.pro_inf_list[doc_id][subject][k] = this.info_options[subject][k];
            res = true;
            det = true;
          }
          if (!det) { this.clear_item_of_subject(doc_id, subject, k); }
        }
      }
      return res;
    },
    clear_all_subjects_except_general: function (doc_id) {
      for (let subj in this.pro_inf_list[doc_id]) {
        if (subj !== 'general') {
          this.$delete(this.pro_inf_list[doc_id], subj);
        }
      }
    },
    clear_item_of_subject: function (doc_id, subject, item) {
      if (this.pro_inf_list[doc_id][subject] !== undefined) {
        if (this.pro_inf_list[doc_id][subject][item] !== undefined) {
          this.$delete(this.pro_inf_list[doc_id][subject], item);
        }
      }
    },
    create_subject_to_doc_id: function (doc_id, subject, item) {
      if (this.pro_inf_list[doc_id][subject] === undefined) {
        this.pro_inf_list[doc_id][subject] = {};
        if (this.pro_inf_list[doc_id][subject][item] === undefined) {
          this.pro_inf_list[doc_id][subject][item] = {};
        }
      }
    },
    an_item_is_multiple: function (arr, char) { // type float alanda bunu chars analizinde ek olarak yapıyoruz.
      let res = false;
      if (arr.indexOf(char) !== -1) { // birinci karakteri çıkarıyoruz.
        arr.splice(arr.indexOf(char), 1);
      }
      if (arr.indexOf(char) !== -1) { // birinci tespit edilen karakteri çıkardığımız halde dizede varsa res true dönüyor.
        res = true;
      }
      return res;
    },
    numbers_after_decimal: function (arr) {
      if (arr.indexOf('.') !== -1) {
        let a = arr.length - 1 - arr.indexOf('.');
        console.log(a);
        return a;
      }
    },
    laboratory_filtered: function () {
      var input, filter, table, tr, td, i;
      input = document.getElementById('input_filter');
      filter = input.value.toUpperCase();
      table = document.getElementById('laboratory_table');
      tr = table.getElementsByTagName('tr');
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName('td')[0];
        if (td) {
          if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = '';
          } else {
            tr[i].style.display = 'none';
          }
        }
      }
    }
  },
  filter: {
    asdas: function (a) {
      alert(a);
    }
  }
};
</script>
<style>
  .table_danger {
    background: red;
  }
</style>
