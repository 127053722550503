<template>
  <div>
    <div class="card-block">
      <div class="pull-right">
        <button type="button" class="btn btn-success" @click="AddSelectedDrug()">Seçili ilacı ekle</button>
      </div>
    </div>
    <div class="card-block">                     
      <table class="table">
        <tr>
          <td style="width: 20%"> <strong> Protokoldeki yeri </strong> </td>
          <td>
            <select v-model="selected_drug_order" style="width: 300px; height: 30px;">
              <option value="initial">Protokol başına</option>
              <option value="end">Protokol sonuna</option>
            </select>
          </td>
        </tr>
        <tr>
          <td style="width: 20%"> <strong> İlaç listesi </strong> </td>
          <td><v-select v-model="selected_prepared_drug" name="prepared_drugs_list" :options="prepared_drugs_list"></v-select></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default {
  name: 'PreparedDrugs',
  mounted () {
    for (let i in this.favorite_prepared_drugs) {
      let data = JSON.parse(JSON.stringify(this.favorite_prepared_drugs[i]));
      data.label = data.drug.label + ' (' + data.dosage + data.unit.label + ' | ' + data.frequency.label + ' | ' + data.dosage_form.label + ' | ' + 'siklus= ' + data.repeat + ' | ' + 'period= ' + data.period;
      data.ind = i;
      this.prepared_drugs_list.push(data);
    }
  },
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  props: {
    protocol_data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selected_drug_order: 'end',
      selected_prepared_drug: '',
      prepared_drugs_list: [],
      favorite_prepared_drugs: require('@/options/favorite_prepared_drugs').options
    };
  },
  created () {},
  methods: {
    AddSelectedDrug: function () {
      if (this.selected_prepared_drug) {
        if (this.selected_drug_order === 'end') {
          this.protocol_data.drugs.push(JSON.parse(JSON.stringify(this.favorite_prepared_drugs[this.selected_prepared_drug.ind])));
        } else if (this.selected_drug_order === 'initial') {
          this.protocol_data.drugs.splice(0, 0, JSON.parse(JSON.stringify(this.favorite_prepared_drugs[this.selected_prepared_drug.ind])));
        }
      }
    }
  },
  watch: {},
  components: {
    vSelect
  }
};
</script>

<style type="text/css">
.footer {
    position: fixed;
    bottom: 100px;
    left: 0px;
    width: 100%;
    height: 50px;
    padding:15px;
}

.pagination {
  border-radius: 3px;
  border-top-color: #c5c5c5;
  border-bottom-color: #fff;
  padding: 2px 2px 3px 1px;
  display: inline-block;
}
.pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left; 
}
.pagination li {
  float: left;
  margin: 0 4px 0 0;
  font-size: 12px;
} 

.pagination li button {
  cursor: pointer;
  float: left;
  background: #fff;
  background: linear-gradient(to bottom, #fff, #f5f5f5);
  border-radius: 3px;
  padding: 4px 10px;
  border: 1px solid #d9d9d9;
  border-bottom-color: #b6b7b9;
  box-shadow: 0 3px 5px rgba(0,0,0,0.05);
  color: #7b8095;
  text-decoration: none;
}
</style>
