<template>
  <div>
    <b-card header-tag="header" style="min-height: 750px;">
      <b-row>
        <b-col cols="12">
          <b-card bg-variant="primary" style="margin-bottom: 5px; background-color: #3c9ca5!important;" no-body>
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> WDM Şablonları
              <b-button class="pull-right" size="sm" variant="primary" @click="d_showMode ? d_showMode = false : d_showMode = true"><i class="fa fa-edit"></i></b-button>
            </b-card-header>
            <b-row style="padding: 3px;">
              <b-col sm="12" md="4">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-search"></i> Ekran Görünümü
                  </b-card-header>
                  <b-row>
                    <b-col cols="3">
                      Ekran Görünümü
                    </b-col>
                    <b-col cols="9">
                      <b-form-select v-model="page_view_settings.general">
                        <option value="divided">İkiye Bölünmüş Ekran</option>
                        <option value="fullscreen">Tam Ekran</option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col sm="12" md="8" style="border-radius: 15px">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-database"></i> Ekran İçerikleri
                  </b-card-header>
                  <template v-if="page_view_settings.general === 'fullscreen'">
                    <b-row>
                      <b-col cols="2">
                        Ekran
                      </b-col>
                      <b-col cols="4">
                        <b-form-select v-model="page_view_settings.fullscreen.schemalist_type">
                          <option value="fullscreen_wisdom_schemas">WisdomEra Şablonları</option>
                          <option value="fullscreen_my_schemas">Bana Ait Şablonlar</option>
                          <option value="fullscreen_hospital_schemas">Hastaneme Ait Şablonlar</option>
                          <option v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" value="fullscreen_user_schemas">Birine Ait Şablonlar</option>
                          <option v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" value="fullscreen_user_hospital_schemas">Bir Kullanıcının Hastanesine Ait Şablonlar</option>
                        </b-form-select>
                      </b-col>
                      <b-col cols="6" v-if="['fullscreen_user_schemas', 'fullscreen_user_hospital_schemas'].indexOf(page_view_settings.fullscreen.schemalist_type) !== -1">
                        <v-select v-model="page_view_settings.fullscreen.user" :options="d_allUsersList"></v-select>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-if="page_view_settings.general === 'divided'">
                    <b-row>
                      <b-col cols="2">
                        Sol Taraf
                      </b-col>
                      <b-col cols="4">
                        <b-form-select v-model="page_view_settings.side.left.schemalist_type">
                          <option value="left_wisdom_schemas">WisdomEra Şablonları</option>
                          <option value="left_my_schemas">Bana Ait Şablonlar</option>
                          <option value="left_hospital_schemas">Hastaneme Ait Şablonlar</option>
                          <option v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" value="left_user_schemas">Birine Ait Şablonlar</option>
                          <option v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" value="left_user_hospital_schemas">Bir Kullanıcının Hastanesine Ait Şablonlar</option>
                        </b-form-select>
                      </b-col>
                      <b-col cols="6" v-if="['left_user_schemas', 'left_user_hospital_schemas'].indexOf(page_view_settings.side.left.schemalist_type) !== -1">
                        <v-select v-model="page_view_settings.side.left.user" :options="d_allUsersList"></v-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2">
                        Sağ Taraf
                      </b-col>
                      <b-col cols="4">
                        <b-form-select v-model="page_view_settings.side.right.schemalist_type">
                          <option value="right_wisdom_schemas">WisdomEra Şablonları</option>
                          <option value="right_my_schemas">Bana Ait Şablonlar</option>
                          <option value="right_hospital_schemas">Hastaneme Ait Şablonlar</option>
                          <option v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" value="right_user_schemas">Birine Ait Şablonlar</option>
                          <option v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" value="right_user_hospital_schemas">Bir Kullanıcının Hastanesine Ait Şablonlar</option>
                        </b-form-select>
                      </b-col>
                      <b-col cols="6" v-if="['right_user_schemas', 'right_user_hospital_schemas'].indexOf(page_view_settings.side.right.schemalist_type) !== -1">
                        <v-select v-model="page_view_settings.side.right.user" :options="d_allUsersList"></v-select>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-body border-variant="primary" :class="[parameter_design_full_screen ? 'parameter-design-full-screen' : 'not-parameter-design-full-screen']">
            <b-card-header header-bg-variant="primary" class="p-1">
              <b-row>
                <b-col cols="1">
                  <b-dropdown variant="secondary" size="sm">
                    <template slot="button-content">
                      <i class="icon-settings"></i> işlemler
                    </template>
                    <b-dropdown-item @click="">Yeni xxx ekle</b-dropdown-item>
                    <b-dropdown-item v-if="page_view_settings.general === 'divided'" @click="f_copyAllTreatments(page_view_settings.side.left.schemalist_type, page_view_settings.side.right.schemalist_type)">Soldakileri Sağa Ekle</b-dropdown-item>
                    <b-dropdown-item v-if="page_view_settings.general === 'divided'" @click="f_copyAllTreatments(page_view_settings.side.right.schemalist_type, page_view_settings.side.left.schemalist_type)">Sağdakileri Sola Ekle</b-dropdown-item>
                  </b-dropdown>
                </b-col>
                <b-col cols="1">
                  Veri Yapısı
                </b-col>
                <b-col cols="8">
                  <b-row>
                    <b-col cols="8">
                      <v-select name="protocol_group" ref="selected_group" placeholder="WDM Türüyle Filtrelemek İçin Seçiniz" v-model="selected_group.value" :options="d_wdmListForFilter"></v-select>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="2">
                  <b-button style="padding-right: 5px;" size="sm" class="pull-right" variant="success" @click="parameter_design_full_screen ? parameter_design_full_screen = false : parameter_design_full_screen = true"><i class="fa fa-arrows-up"></i>tam ekran</b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <template v-if="page_view_settings.general === 'fullscreen'">
              <b-row style="margin: 0px; height: 95%;">
                <b-col sm="12" :md="colList.models_general_col_left" :lg="colList.models_general_col_left">
                  <!-- user protocols değilse varsa muhabbetleri 3 defaya da eklenecek -->
                  <wdm-schema-list :p_showSchema="d_showSchema" :d_wdmName="d_wdmName" :d_componentSide="'fullscreen'" :d_selectedSchema="d_selectedSchema" :d_schemaList="d_fullscreenSchemaList" :schema_updated="schema_updated" :page_type="page_view_settings.general" :list_type="page_view_settings.fullscreen.schemalist_type" :list_user="page_view_settings.fullscreen.user.value" :selected_group="selected_group" :groups_list="d_wdmListForFilter" :page_view_settings="page_view_settings"></wdm-schema-list>
                </b-col>
                <b-col sm="12" :md="colList.models_general_col_right" :lg="colList.models_general_col_right">
                  <!-- user protocols değilse varsa muhabbetleri 3 defaya da eklenecek -->
                  <template v-if="d_selectedSchema.value && d_showSchema.show">
                    <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
                      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                        {{ d_selectedSchema.label }}
                        <b-button @click="f_showEditSchemaModal()" class="pull-right">Şablonu Düzenle</b-button>
                      </b-card-header>
                      <wisdom-data-show :change="'0'" :view_mode="'table'" :data="d_showSchema.data" :data_type="d_showSchema.data_type" :option_data="d_showSchema.option_data"></wisdom-data-show>
                    </b-card>
                  </template>
                </b-col>
              </b-row>
            </template>
            <template v-if="page_view_settings.general === 'divided'">
              <b-row style="margin: 0px; height: 95%;">
                <b-col sm="12" :md="colList.models_general_col_left" :lg="colList.models_general_col_left" style="padding-right: 0px; padding-left: 0px; border: solid 3px #0c6b59;">
                  <wdm-schema-list :p_showSchema="d_showSchema" :d_wdmName="d_wdmName" :d_componentSide="'left'" :d_selectedSchema="d_selectedSchema" :d_schemaList="d_leftSchemaList" :schema_updated="schema_updated" :page_type="page_view_settings.general" :list_type="page_view_settings.side.left.schemalist_type" :list_user="page_view_settings.side.left.user.value" :selected_group="selected_group" :groups_list="d_wdmListForFilter" :page_view_settings="page_view_settings"></wdm-schema-list>
                </b-col>
                <b-col sm="12" :md="colList.models_general_col_right" :lg="colList.models_general_col_right" style="padding-left: 0px; border-right: solid 3px #0c6b59;border-top: solid 3px #0c6b59;border-bottom: solid 3px #0c6b59;">
                  <wdm-schema-list :p_showSchema="d_showSchema" :d_wdmName="d_wdmName" :d_componentSide="'right'" :d_selectedSchema="d_selectedSchema" :d_schemaList="d_rightSchemaList" :schema_updated="schema_updated" :page_type="page_view_settings.general" :list_type="page_view_settings.side.right.schemalist_type" :list_user="page_view_settings.side.right.user.value" :selected_group="selected_group" :groups_list="d_wdmListForFilter" :page_view_settings="page_view_settings"></wdm-schema-list>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <!-- <modal v-if="show_protocol_add" @close="show_protocol_add = false" large="true">
      <h3 slot="header">{{ $t('wdm16.4167') }}</h3>
      <div slot="body">
        <protocol-builder :data="protocol_data"></protocol-builder>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" v-on:click="protocol_add()">{{ $t('wdm16.3956') }}</button>
        <button type="button" class="btn btn-secondary" @click="show_protocol_add = false">{{ $t('wdm16.4045') }}</button>
      </div>
    </modal> -->
    <!-- <modal v-if="show_protocol_view" @close="show_protocol_view = false" large="true">
      <h3 slot="header">View Protocol</h3>
      <div slot="body">
        <protocol-viewer :data="protocol_data_view" :protocol_one_note="protocol_data_view.protocol_note" :protocol_notes="[]" :insurance_report="{}" :insurance_data="{}" :patient_data="{}" :diagnosis="{}"></protocol-viewer>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary" @click="show_protocol_view = false">{{ $t('wdm16.4045') }}</button>
      </div>
    </modal> -->
    <modal v-if="d_showEditSchemaModal" @close="d_showEditSchemaModal = false" :width="'1500'">
      <h3 slot="header"> Şablon Düzenleme </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :p_schemaEdit="d_showSchema" :data="d_showSchema.data" :data_type="d_showSchema.data_type" :option_data="d_showSchema.option_data_for_modal" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showEditSchemaModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import vSelect from 'vue-select';
import {
  default as router
} from '@/router';
import {
  default as WisdomDataShow
} from "@/components/widgets/WisdomDataShow";
import {
  default as WdmSchemaList
} from '@/components/widgets/WdmSchemaList';
import {
  default as Modal
} from '@/components/widgets/Modal';
import store from '@/store';
import SchemaService from "@/services/schema";
import ProtocolService from '@/services/public/protocol';
import {default as WisdomDataModal } from "@/components/widgets/WisdomDataModal";
import GlobalService from '@/services/global';

export default {
  name: 'Schema',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help'
    })
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      selected_group: {
        'value': ''
      },
      selected_group_my_protocol: { 'selected': '' },
      page_view_settings: {
        'general': 'fullscreen', // divided
        'divide_count': '2',
        'list': {
          'public': [],
          'shared': [],
          'my_protocols': [],
          'left_user_protocols': [],
          'right_user_protocols': [],
          'fullscreen_user_schemas': []
        },
        'side': {
          'left': {
            'schemalist_type': 'left_wisdom_schemas',
            'type': 'schemalist', // details, edittreatment,
            'selected_group': '',
            'user': { 'value': '', 'label': '' }
          },
          'right': {
            'schemalist_type': 'right_my_schemas',
            'type': 'schemalist', // details, edittreatment,
            'selected_group': '',
            'user': { 'value': '', 'label': '' }
          }
        },
        'fullscreen': {
          'schemalist_type': 'fullscreen_wisdom_schemas',
          'type': 'schemalist', // başka formatlar üzerine çalışılabilir.
          'user': { 'value': '', 'label': '' }
        }
      },
      d_selectedGroupShared: '',
      d_selectedGroupMyProtocols: '',
      d_selectedGroupPublic: '',
      showPages: false,
      selected_right_tab: 'my_protocols',
      selected_tab: '',
      selected_general_tab: 'public',
      colList: {
        'models_general_col_left': 6,
        'models_general_col_right': 6
      },
      parameter_design_full_screen: false,
      store: store,
      recorded_protocol_type: 'my_protocols',
      together_period_repeat_parenthesis: 'square_brackets',
      numbering_the_drugs: false,
      use_protocol_prem: false,
      tag_between_drugs: ' , ',
      add_abbr_name_to_protocol_name: true,
      drug_initial: '',
      days_list: 'together',
      drug_details: 'together',
      parenthesis_type: 'standart',
      spacing_in_details: ' , ',
      days_tag_en: '',
      days_tag_en_end: 'days',
      days_tag_tr: '',
      days_tag_tr_end: '.gün',
      period_tag_en: 'q',
      period_tag_en_end: 'd',
      period_tag_tr: '',
      period_tag_tr_end: ' gündebir',
      cycle_tag_en: '',
      cycle_tag_en_end: 'cycles',
      cycle_tag_tr: '',
      cycle_tag_tr_end: 'kür',
      protocols_edit_mode: false,
      protocols_list: [],
      search_text: '',
      public_protocols_list: [],
      d_myProtocolList: [],
      shared_protocols_list: [],
      show_protocol_add: false,
      show_protocol_view: false,
      protocol_groups_list: require('@/options/protocol_group').options,
      protocol_data: { duration: 21, drugs: [{ day: [], before: [{}], after: [{}] }] },
      protocol_data_new: { drugs: [{ day: [] }] },
      protocol_data_view: {},
      user: {},
      groups_list: [],
      d_protocolDeleteQuery: {},
      schema_updated: { 'public': 0, 'shared': 0, 'my_protocols': 0, 'left_user_protocols': 0, 'right_user_protocols': 0, 'fullscreen_user_schemas': 0 },
      d_allUsersList: [],
      protocol_groups_object_list: {},
      d_wisdomSchemaList: {},
      d_userSchemaList: {},
      d_hospitalSchemaList: {},
      d_selectedSchema: { 'label': '', 'value': '', 'bucket': '', 'schema_owner_type': '', 'schema_owner': '' },
      d_showSchema: {'saved': 0, 'show': true},
      d_wdmListForFilter: [],
      d_wdmName: {},
      d_fullscreenSchemaList: {},
      d_leftSchemaList: {},
      d_rightSchemaList: {},
      d_showEditSchemaModal: false
    };
  },
  created: function () {
    let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
    for (let wdm_owner in wdm_version) {
      for (let wdm_type in wdm_version[wdm_owner].all) {
        if (!this.d_wdmName[wdm_type]) {
          this.d_wdmName[wdm_type] = wdm_version[wdm_owner].all[wdm_type].name.label;
        }
      }
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_updateSchemaLists(this.user.username);
    let all_users = JSON.parse(localStorage.getItem('all_users'));
    for (let i in all_users) {
      this.d_allUsersList.push({ 'value': all_users[i].username, 'label': all_users[i].first_name + ' ' + all_users[i].last_name });
    }
    for (let pg in this.protocol_groups_list) {
      this.protocol_groups_object_list[this.protocol_groups_list[pg].value] = this.protocol_groups_list[pg];
    }
  },
  mounted: function () {
    // this.page_view_settings.side.left.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
    // this.page_view_settings.side.right.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
    // this.page_view_settings.fullscreen.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
    // let user_list = [];
    // user_list.push(this.user.username);
    // this.get_user_favorite_protocols(user_list, 'active_user');
  },
  methods: {
    f_showEditSchemaModal: function () {
      this.d_showEditSchemaModal = true;
    },
    f_changeScreenType: function (screen_type) {
      if (!screen_type) {
        if (this.page_view_settings.general === 'fullscreen') {
          if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_user_schemas') {
            if (this.page_view_settings.fullscreen.user.value === '') {
              this.page_view_settings.fullscreen.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
            }
            this.f_getSchemaListForJson('user', this.page_view_settings.fullscreen.user.value, 'd_fullscreenSchemaList');
          } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_wisdom_schemas') {
            this.f_getSchemaListForJson('wisdom', this.user.username, 'd_fullscreenSchemaList');
          } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_my_schemas') {
            this.f_getSchemaListForJson('user', this.user.username, 'd_fullscreenSchemaList');
          } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_hospital_schemas') {
            this.f_getSchemaListForJson('hospital', this.user.username, 'd_fullscreenSchemaList');
          } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_user_hospital_schemas') {
            if (this.page_view_settings.fullscreen.user.value === '') {
              this.page_view_settings.fullscreen.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
            }
            this.f_getSchemaListForJson('hospital', this.page_view_settings.fullscreen.user.value, 'd_fullscreenSchemaList');
          }
        } else if (this.page_view_settings.general === 'divided') {
          if (this.page_view_settings.side.left.schemalist_type === 'left_user_schemas') {
            if (this.page_view_settings.side.left.user.value === '') {
              this.page_view_settings.side.left.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
            }
            this.f_getSchemaListForJson('user', this.page_view_settings.side.left.user.value, 'd_leftSchemaList');
          } else if (this.page_view_settings.side.left.schemalist_type === 'left_wisdom_schemas') {
            this.f_getSchemaListForJson('wisdom', this.user.username, 'd_leftSchemaList');
          } else if (this.page_view_settings.side.left.schemalist_type === 'left_my_schemas') {
            this.f_getSchemaListForJson('user', this.user.username, 'd_leftSchemaList');
          } else if (this.page_view_settings.side.left.schemalist_type === 'left_hospital_schemas') {
            this.f_getSchemaListForJson('hospital', this.user.username, 'd_leftSchemaList');
          } else if (this.page_view_settings.side.left.schemalist_type === 'left_user_hospital_schemas') {
            if (this.page_view_settings.side.left.user.value === '') {
              this.page_view_settings.side.left.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
            }
            this.f_getSchemaListForJson('hospital', this.page_view_settings.side.left.schemalist_type.user.value, 'd_leftSchemaList');
          }

          if (this.page_view_settings.side.right.schemalist_type === 'right_user_schemas') {
            if (this.page_view_settings.side.right.user.value === '') {
              this.page_view_settings.side.right.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
            }
            this.f_getSchemaListForJson('user', this.page_view_settings.side.right.user.value, 'd_rightSchemaList');
          } else if (this.page_view_settings.side.right.schemalist_type === 'right_wisdom_schemas') {
            this.f_getSchemaListForJson('wisdom', this.user.username, 'd_rightSchemaList');
          } else if (this.page_view_settings.side.right.schemalist_type === 'right_my_schemas') {
            this.f_getSchemaListForJson('user', this.user.username, 'd_rightSchemaList');
          } else if (this.page_view_settings.side.right.schemalist_type === 'right_hospital_schemas') {
            this.f_getSchemaListForJson('hospital', this.user.username, 'd_rightSchemaList');
          } else if (this.page_view_settings.side.right.schemalist_type === 'right_user_hospital_schemas') {
            if (this.page_view_settings.side.right.user.value === '') {
              this.page_view_settings.side.right.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
            }
            this.f_getSchemaListForJson('hospital', this.page_view_settings.side.right.user.value, 'd_rightSchemaList');
          }
        }
      } else if (screen_type === 'fullscreen') {
        if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_user_schemas') {
          if (this.page_view_settings.fullscreen.user.value === '') {
            this.page_view_settings.fullscreen.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('user', this.page_view_settings.fullscreen.user.value, 'd_fullscreenSchemaList');
        } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_wisdom_schemas') {
          this.f_getSchemaListForJson('wisdom', this.user.username, 'd_fullscreenSchemaList');
        } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_my_schemas') {
          this.f_getSchemaListForJson('user', this.user.username, 'd_fullscreenSchemaList');
        } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_hospital_schemas') {
          this.f_getSchemaListForJson('hospital', this.user.username, 'd_fullscreenSchemaList');
        } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_user_hospital_schemas') {
          if (this.page_view_settings.fullscreen.user.value === '') {
            this.page_view_settings.fullscreen.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('hospital', this.page_view_settings.fullscreen.user.value, 'd_fullscreenSchemaList');
        }
      } else if (screen_type === 'divided') {

        if (this.page_view_settings.side.left.schemalist_type === 'left_user_schemas') {
          if (this.page_view_settings.side.left.user.value === '') {
            this.page_view_settings.side.left.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('user', this.page_view_settings.side.left.user.value, 'd_leftSchemaList');
        } else if (this.page_view_settings.side.left.schemalist_type === 'left_wisdom_schemas') {
          this.f_getSchemaListForJson('wisdom', this.user.username, 'd_leftSchemaList');
        } else if (this.page_view_settings.side.left.schemalist_type === 'left_my_schemas') {
          this.f_getSchemaListForJson('user', this.user.username, 'd_leftSchemaList');
        } else if (this.page_view_settings.side.left.schemalist_type === 'left_hospital_schemas') {
          this.f_getSchemaListForJson('hospital', this.user.username, 'd_leftSchemaList');
        } else if (this.page_view_settings.side.left.schemalist_type === 'left_user_hospital_schemas') {
          if (this.page_view_settings.side.left.user.value === '') {
            this.page_view_settings.side.left.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('hospital', this.page_view_settings.side.left.schemalist_type.user.value, 'd_leftSchemaList');
        }

        if (this.page_view_settings.side.right.schemalist_type === 'right_user_schemas') {
          if (this.page_view_settings.side.right.user.value === '') {
            this.page_view_settings.side.right.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('user', this.page_view_settings.side.right.user.value, 'd_rightSchemaList');
        } else if (this.page_view_settings.side.right.schemalist_type === 'right_wisdom_schemas') {
          this.f_getSchemaListForJson('wisdom', this.user.username, 'd_rightSchemaList');
        } else if (this.page_view_settings.side.right.schemalist_type === 'right_my_schemas') {
          this.f_getSchemaListForJson('user', this.user.username, 'd_rightSchemaList');
        } else if (this.page_view_settings.side.right.schemalist_type === 'right_hospital_schemas') {
          this.f_getSchemaListForJson('hospital', this.user.username, 'd_rightSchemaList');
        } else if (this.page_view_settings.side.right.schemalist_type === 'right_user_hospital_schemas') {
          if (this.page_view_settings.side.right.user.value === '') {
            this.page_view_settings.side.right.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('hospital', this.page_view_settings.side.right.user.value, 'd_rightSchemaList');
        }

      } else if (screen_type === 'left') {

        if (this.page_view_settings.side.left.schemalist_type === 'left_user_schemas') {
          if (this.page_view_settings.side.left.user.value === '') {
            this.page_view_settings.side.left.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('user', this.page_view_settings.side.left.user.value, 'd_leftSchemaList');
        } else if (this.page_view_settings.side.left.schemalist_type === 'left_wisdom_schemas') {
          this.f_getSchemaListForJson('wisdom', this.user.username, 'd_leftSchemaList');
        } else if (this.page_view_settings.side.left.schemalist_type === 'left_my_schemas') {
          this.f_getSchemaListForJson('user', this.user.username, 'd_leftSchemaList');
        } else if (this.page_view_settings.side.left.schemalist_type === 'left_hospital_schemas') {
          this.f_getSchemaListForJson('hospital', this.user.username, 'd_leftSchemaList');
        } else if (this.page_view_settings.side.left.schemalist_type === 'left_user_hospital_schemas') {
          if (this.page_view_settings.side.left.user.value === '') {
            this.page_view_settings.side.left.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('hospital', this.page_view_settings.side.left.schemalist_type.user.value, 'd_leftSchemaList');
        }

      } else if (screen_type === 'right') {
        if (this.page_view_settings.side.right.schemalist_type === 'right_user_schemas') {
          if (this.page_view_settings.side.right.user.value === '') {
            this.page_view_settings.side.right.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('user', this.page_view_settings.side.right.user.value, 'd_rightSchemaList');
        } else if (this.page_view_settings.side.right.schemalist_type === 'right_wisdom_schemas') {
          this.f_getSchemaListForJson('wisdom', this.user.username, 'd_rightSchemaList');
        } else if (this.page_view_settings.side.right.schemalist_type === 'right_my_schemas') {
          this.f_getSchemaListForJson('user', this.user.username, 'd_rightSchemaList');
        } else if (this.page_view_settings.side.right.schemalist_type === 'right_hospital_schemas') {
          this.f_getSchemaListForJson('hospital', this.user.username, 'd_rightSchemaList');
        } else if (this.page_view_settings.side.right.schemalist_type === 'right_user_hospital_schemas') {
          if (this.page_view_settings.side.right.user.value === '') {
            this.page_view_settings.side.right.user = { 'value': this.user.username, 'label': this.user.first_name + ' ' + this.user.last_name };
          }
          this.f_getSchemaListForJson('hospital', this.page_view_settings.side.right.user.value, 'd_rightSchemaList');
        }

      }
      this.$store.commit('Loading', { 'status': false, 'data': {} });
    },
    f_updateSchemaLists: function (username) {
      this.f_getWisdomSchemaList(username);
      this.f_getUserSchemaList(username);
      this.f_getHospitalSchemaList(username);
    },
    f_getWisdomSchemaList: function (username) {
      this.d_wdmListForFilter = [];
      SchemaService.get_all_available_schema_for_user({ 'owner_type': ['wisdom'], 'username': username })
        .then(resp => {
          this.d_wisdomSchemaList = resp.data.result;
          for (let i in Object.keys(this.d_wisdomSchemaList).sort()) {
            let wdm_type = Object.keys(this.d_wisdomSchemaList).sort()[i]
            this.d_wdmListForFilter.push({ 'label': this.d_wdmName[wdm_type], 'value': wdm_type });
          }
          // this.d_wdmListForFilter = JSON.parse(JSON.stringify(Object.keys(this.d_wisdomSchemaList)));
          this.d_fullscreenSchemaList = JSON.parse(JSON.stringify(this.d_wisdomSchemaList));
        });
    },
    f_getUserSchemaList: function (username) {
      SchemaService.get_all_available_schema_for_user({ 'owner_type': ['user'], 'username': username })
        .then(resp => {
          this.d_userSchemaList = resp.data.result;
        });
    },
    f_getHospitalSchemaList: function (username) {
      SchemaService.get_all_available_schema_for_user({ 'owner_type': ['hospital'], 'username': username })
        .then(resp => {
          this.d_hospitalSchemaList = resp.data.result;
        });
    },
    f_getSchemaListForJson: function (owner_type, username, aim_value) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şablonlar getiriliyor' } });
      SchemaService.get_all_available_schema_for_user({ 'owner_type': [owner_type], 'username': username })
        .then(resp => {
          this[aim_value] = JSON.parse(JSON.stringify(resp.data.result));
        });
    },
    f_copyAllTreatments: function (from_list, to_list) {
      if (to_list === 'public') {
        alert('public protokollere ekleme yapılamamaktadır.');
        return;
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kopyala işlemi gerçekleştiriliyor.' } });
      for (let i in this.page_view_settings.list[from_list]) {
        let new_protocol = JSON.parse(JSON.stringify(this.page_view_settings.list[from_list][i]));
        new_protocol['new'] = 1;
        new_protocol['from_list_type'] = from_list;
        new_protocol['new_protocol_group'] = this.protocol_groups_object_list[new_protocol.data.protocol_group];
        this.page_view_settings.list[to_list].push(new_protocol);
        this.$forceUpdate();
      }
      this.$store.commit('Loading', { 'status': false, 'data': {} });
    },
    get_user_favorite_protocols: function (user_list, which_users) {
      ProtocolService.get_favorite_protocols(user_list)
        .then(resp => {
          if (which_users === 'active_user' && resp.data.result) {
            this.user.settings = resp.data.result[this.user.username];
            this.$forceUpdate();
          }
        });
    },
    prepare_protocol_groups_list: function (list) {
      this.groups_list = [];
      for (let pr in list) {
        if (this.search_text) {
          if (this.search_in_protocol_name(list[pr].data.name)) {
            for (let gr in this.protocol_groups_list) {
              if (this.protocol_groups_list[gr].value === list[pr].data.protocol_group) {
                if (this.groups_list.indexOf(this.protocol_groups_list[gr]) === -1) {
                  this.groups_list.push(this.protocol_groups_list[gr]);
                }
              }
            }
          }
        } else {
          for (let gr in this.protocol_groups_list) {
            if (this.protocol_groups_list[gr].value === list[pr].data.protocol_group) {
              if (this.groups_list.indexOf(this.protocol_groups_list[gr]) === -1) {
                this.groups_list.push(this.protocol_groups_list[gr]);
              }
            }
          }
        }
      }
    },
    search_in_protocol_name: function (name) {
      if (this.search_text) {
        if (this.search_text.indexOf(',') !== -1) {
          let search_list = this.search_text.split(',');
          let x = 0;
          for (let i in search_list) {
            if (name.toLowerCase().indexOf(search_list[i].toLowerCase()) !== -1) {
              x += 1;
            }
          }
          if (x === search_list.length) {
            return true;
          }
        } else {
          if (name.toLowerCase().indexOf(this.search_text.toLowerCase()) !== -1) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    protocol_delete: function (id, protocol_ind) {
      this.d_protocolDeleteQuery = { 'id': id, 'protocol_ind': protocol_ind };
      let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'Protokol Silme İşlemine Emin Misiniz?', 'centered': true, 'title': 'Protokol Silme İşlemi Hakkında', 'function_name': 'protocol_delete_confirmed', 'confirm': false };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    protocol_delete_confirmed: function (data) {
      ProtocolService.delete(data.id)
        .then(resp => {
          if (resp.data.status === 'success') {
            alert('Protokol silme işlemi tamamlandı.');
            this.protocols_list.splice(data.protocol_ind, 1);
          }
        });
    },
    f_cleanSelectedGroup: function () {
      let using_groups = []
      using_groups.push(this.page_view_settings.side.left.schemalist_type);
      using_groups.push(this.page_view_settings.side.right.schemalist_type);
      for (let i in this.selected_group) {
        if (using_groups.indexOf(i) === -1) {
          this.selected_group[i] = '';
        }
      }
    }
  },
  components: {
    Modal,
    vSelect,
    WdmSchemaList,
    WisdomDataShow,
    WisdomDataModal
  },
  watch: {
    'page_view_settings.general': function () {
      this.f_changeScreenType(this.page_view_settings.general);
    },
    'd_showSchema.saved': function () {
      this.d_selectedSchema = { 'label': '', 'value': '', 'bucket': '', 'schema_owner_type': '', 'schema_owner': '' };
      this.d_showEditSchemaModal = false;
      this.f_changeScreenType();
    },
    'page_view_settings.side.left.schemalist_type': function () {
      this.f_changeScreenType('left');
    },
    'page_view_settings.side.left.user': function () {
      this.f_changeScreenType('left');
    },
    'page_view_settings.side.right.schemalist_type': function () {
      this.f_changeScreenType('right');
    },
    'page_view_settings.side.right.user': function () {
      this.f_changeScreenType('right');
    },
    'page_view_settings.fullscreen.schemalist_type': function () {
      this.f_changeScreenType('fullscreen');
    },
    'page_view_settings.fullscreen.user': function () {
      if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_user_schemas') {
        this.f_getSchemaListForJson('user', this.page_view_settings.fullscreen.user.value, 'd_fullscreenSchemaList');
      } else if (this.page_view_settings.fullscreen.schemalist_type === 'fullscreen_user_hospital_schemas') {
        this.f_getSchemaListForJson('hospital', this.page_view_settings.fullscreen.user.value, 'd_fullscreenSchemaList');
      }
      this.$store.commit('Loading', { 'status': false, 'data': {} });
    },
    'schema_updated.public': function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WisdomEra tedavi protokolleri getiriliyor.' } });
      ProtocolService.search({ 'group': '', 'scope': ['public'], 'favorite': false, 'detail': 'detailed' })
        .then(resp => {
          this.page_view_settings.list['public'] = resp.data.results;
          this.prepare_protocol_groups_list(this.public_protocols_list);
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    'schema_updated.shared': function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastaneme ait tedavi protokolleri getiriliyor.' } });
      ProtocolService.search({ 'group': '', 'scope': ['shared'], 'favorite': false, 'detail': 'detailed' })
        .then(resp => {
          this.page_view_settings.list['shared'] = resp.data.results;
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    'schema_updated.my_protocols': function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Bana ait tedavi protokolleri getiriliyor.' } });
      ProtocolService.get_user_protocol_list({ 'username': this.user.username })
        .then(resp => {
          this.page_view_settings.list['my_protocols'] = resp.data.result;
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    'schema_updated.left_user_protocols': function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcıya ait tedavi protokolleri getiriliyor.' } });
      ProtocolService.get_user_protocol_list({ 'username': this.page_view_settings.side.left.user.value })
        .then(resp => {
          this.page_view_settings.list['left_user_protocols'] = resp.data.result;
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    'schema_updated.right_user_protocols': function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcıya ait tedavi protokolleri getiriliyor.' } });
      ProtocolService.get_user_protocol_list({ 'username': this.page_view_settings.side.right.user.value })
        .then(resp => {
          this.page_view_settings.list['right_user_protocols'] = resp.data.result;
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    'schema_updated.fullscreen_user_schemas': function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcıya ait tedavi protokolleri getiriliyor.' } });
      ProtocolService.get_user_protocol_list({ 'username': this.page_view_settings.fullscreen.user.value })
        .then(resp => {
          this.page_view_settings.list['fullscreen_user_schemas'] = resp.data.result;
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    'selected_group': function () {
      this.$forceUpdate();
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'protocol_delete_confirmed') {
          if (this.StoreModal.data.confirm && this.d_protocolDeleteQuery) {
            this.protocol_delete_confirmed(this.d_protocolDeleteQuery);
          }
        }
      }
    },
    search_text: function () {
      this.prepare_protocol_groups_list(this.protocols_list);
    }
  }
};

</script>

