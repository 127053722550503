<template>
  <div>
    <b-card header-tag="header" footer-tag="footer" no-body key="nav-give_permission" style="margin-bottom: 0px;">
      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
        <b-dropdown class="pull-left" variant="secondary" :text="d_dropdownText" size="sm">
          <!-- <b-dropdown-item v-if="list_type !== 'public'" @click="f_showBatchEditModal('list')"><i class="fa fa-arrows-right"></i> Buraya toplu işlem yap</b-dropdown-item> -->
          <!-- <b-dropdown-item v-if="list_type !== 'public'" @click="f_showEditModal('new')"><i class="fa fa-arrows-right"></i> Yeni protokol şeması ekle</b-dropdown-item> -->
        </b-dropdown>
        <strong style="color: red;">[ TOPLAM: {{ protocol_list.length }} adet ]</strong>
        <strong v-if="f_isThereNew(protocol_list)" title="Kaydetmediğiniz sürece bu protokoller aktif olmayacaktır." style="color: red;"> | <i class="fa fa-mouse-pointer"></i> ( YENİ: {{ f_isThereNew(protocol_list) }} adet )</strong>
        <strong v-if="f_isThereDelete(protocol_list)" title="Kaydetmediğiniz sürece bu protokoller silinmeyecektir." style="color: red;"> | <i class="fa fa-mouse-pointer"></i> ( SİLİNECEK: {{ f_isThereDelete(protocol_list) }} adet )</strong>
        <b-button v-if="list_type !== 'public' && (f_isThereNew(protocol_list) || f_isThereDelete(protocol_list))" style="padding-right: 5px;" size="sm" class="pull-right" variant="success" @click="f_saveNewProtocols()"><i class="fa fa-save"></i>{{ $t('wdm16.4061') }}</b-button>
      </b-card-header>
      <!-- <b-row>
        <b-col cols="4"> Protokol Grubu: </b-col>
        <b-col cols="8">
          <v-select name="protocol_group" ref="selected_group" placeholder="Protokol Grubuyla Filtrelemek İçin Seçiniz" v-model="selected_group.value" :options="groups_list"></v-select>
          <template slot="option" slot-scope="option">
            <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
              <span> {{ d_wdmName[option] }} </span>
            </div>
          </template>
        </b-col>
      </b-row> -->
      <!-- <b-row>
        <b-col cols="4"> Arama: </b-col>
        <b-col cols="8">
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '131')">?</b-badge>
          <input type="text" name="search_in_protocol_name" class="form-control" v-model="search_text" placeholder="kar,pak şeklinde arama yapabilirsiniz.">
        </b-col>
      </b-row> -->
      <div :style="f_mainParameterSelectionStyle()">
        <b-row v-if="!selected_group.value || (selected_group.value && wdm === selected_group.value.value)" v-for="(wdm, wdm_ind) in Object.keys(d_schemaList).sort()" :key="d_componentSide + '_' + wdm + '_row'" style="border-bottom: solid 1px #bca3a3; padding: 3px;">
          <b-col cols="12">
            <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;" :key="d_componentSide + '_' + wdm + '_card'">
              <b-card-header v-b-toggle="d_componentSide + '_' + wdm + '_toggle'" header-bg-variant="success" header-text-variant="dark" class="p-1">
                {{ d_wdmName[wdm] }}
              </b-card-header>
              <b-collapse :id="d_componentSide + '_' + wdm + '_toggle'">
                <b-row v-for="(wdm_owner_type, wdm_owner_type_ind) in Object.keys(d_schemaList[wdm]).sort()" :key="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_row'" style="border-bottom: solid 1px #a7bebb; margin: 0px; min-height: 40px;">
                  <b-col cols="12">
                    <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;" :key="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card'">
                      <b-card-header v-b-toggle="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_toggle'" header-bg-variant="success" header-text-variant="dark" class="p-1">
                        {{ wdm_owner_type }}
                        <template v-for="if_check_owner_group in ['wisdom', 'hospital', 'user']">
                          <b-button v-if="f_checkAddNewSchemaGroup(if_check_owner_group)" @click="f_showManageSchemaGroupModal(if_check_owner_group, wdm, wdm_owner_type)">{{if_check_owner_group}} şablon grubu yönetim ekranı</b-button>
                        </template>
                        <!-- <b-dropdown variant="secondary" size="sm">
                          <template slot="button-content">
                            <i class="icon-settings"></i> şablon grubu ekle
                          </template>
                          <b-dropdown-item v-if="f_checkAddNewSchemaGroup('wisdom')" @click="f_addNewSchemaGroup('wisdom', wdm, wdm_owner_type)">wisdom şablon grubu ekle</b-dropdown-item>
                          <b-dropdown-item v-if="f_checkAddNewSchemaGroup('hospital')" @click="f_addNewSchemaGroup('hospital', wdm, wdm_owner_type)">hospital şablon grubu ekle</b-dropdown-item>
                          <b-dropdown-item v-if="f_checkAddNewSchemaGroup('user')" @click="f_addNewSchemaGroup('user', wdm, wdm_owner_type)">user şablon grubu ekle</b-dropdown-item>
                        </b-dropdown> -->
                      </b-card-header>
                      <b-collapse :id="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_toggle'">
                        <b-row v-for="(version, version_ind) in Object.keys(d_schemaList[wdm][wdm_owner_type]).sort()" :key="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_row'" style="border-bottom: solid 1px #a7bebb; margin: 0px; min-height: 40px;">
                          <b-col cols="12">
                            <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;" :key="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_card'">
                              <b-card-header v-b-toggle="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_toggle'" header-bg-variant="success" header-text-variant="dark" class="p-1">
                                {{ version }}
                                <b-button @click="f_showAddSchemaModal(wdm, wdm_owner_type, version)">yeni şema ekle</b-button>
                              </b-card-header>
                              <b-collapse :id="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_toggle'">
                                <b-row v-for="(schema_group_owner, schema_group_owner_ind) in Object.keys(d_schemaList[wdm][wdm_owner_type][version]).sort()" :key="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_card_' + schema_group_owner + '_schema_row'" style="border-bottom: solid 1px #a7bebb; margin: 0px; min-height: 40px;">
                                  <b-col cols="12">
                                    <template v-for="(wdm_or_anatomy, wdm_or_anatomy_ind) in Object.keys(d_schemaList[wdm][wdm_owner_type][version][schema_group_owner]).sort()">
                                      <template v-for="(schema_group, schema_group_ind) in Object.keys(d_schemaList[wdm][wdm_owner_type][version][schema_group_owner][wdm_or_anatomy]).sort()">
                                        <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;" :key="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_card_' + schema_group_owner + '_' + wdm_or_anatomy + '_' + schema_group + '_schema_card'">
                                          <b-card-header v-b-toggle="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_card_' + schema_group_owner + '_' + wdm_or_anatomy + '_' + schema_group + '_schema_toggle'" header-bg-variant="success" header-text-variant="dark" class="p-1">
                                            {{ schema_group_owner }} / {{ wdm_or_anatomy === 'wdm_anatomy_schema_group' ? 'Anatomi Şablonları' : 'WDM Şablonları' }} / {{ d_schemaList[wdm][wdm_owner_type][version][schema_group_owner][wdm_or_anatomy][schema_group].group.label }}
                                          </b-card-header>
                                          <b-collapse :id="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_card_' + schema_group_owner + '_' + wdm_or_anatomy + '_' + schema_group + '_schema_toggle'">
                                            <b-row v-for="schema_info in d_schemaList[wdm][wdm_owner_type][version][schema_group_owner][wdm_or_anatomy][schema_group].list" :key="d_componentSide + '_' + wdm + '_card_' + wdm_owner_type + '_wdm_card_' + version + '_card_' + schema_group_owner + '_' + wdm_or_anatomy + '_schema_card_' + schema_info.value + '_row'" style="border-bottom: solid 1px #a7bebb; margin: 0px; min-height: 40px;">
                                              <template v-if="['fullscreen_wisdom_schemas', 'fullscreen_my_schemas', 'fullscreen_hospital_schemas', 'fullscreen_user_schemas', 'fullscreen_user_hospital_schemas'].indexOf(list_type) !== -1">
                                                <b-col cols="12">
                                                  <span @click="f_selectSchema(schema_info, d_schemaList[wdm][wdm_owner_type][version][schema_group_owner][wdm_or_anatomy][schema_group].group, wdm_or_anatomy)" style="cursor: pointer;">{{ schema_info.label }}</span>
                                                  <b-button v-if="f_checkEditSchema()" @click="f_showEditSchemaModal(schema_info, d_schemaList[wdm][wdm_owner_type][version][schema_group_owner][wdm_or_anatomy][schema_group].group, wdm_or_anatomy)">düzenle</b-button>
                                                </b-col>
                                              </template>
                                              <template v-else>
                                                <b-col cols="12">
                                                  {{ schema_info.label }}
                                                  <b-button v-if="f_checkEditSchema()" @click="f_showEditSchemaModal(schema_info, d_schemaList[wdm][wdm_owner_type][version][schema_group_owner][wdm_or_anatomy][schema_group].group, wdm_or_anatomy)">düzenle</b-button>
                                                  <b-button v-if="f_checkCopySchema()" @click="f_showCopySchemaModal(wdm_or_anatomy, schema_info.value, wdm, wdm_owner_type, version)">kopyala</b-button>
                                                </b-col>
                                              </template>
                                            </b-row>
                                          </b-collapse>
                                        </b-card>
                                      </template>
                                    </template>
                                  </b-col>
                                </b-row>
                              </b-collapse>
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-collapse>
                    </b-card>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <modal v-if="d_showEditModal" @close="d_showEditModal = false" :width="'1500'">
      <h3 slot="header">Add-Edit Modal, {{ 'protocol_username' }}</h3>
      <div slot="body">
        <protocol-new :hide_save_button="true" :mother_children="mother_children" :from_add_edit="true" :data="d_editProtocolData" ref="confirm_form"></protocol-new>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" @click="$refs.confirm_form.show_confirm_modal('protocol_add')">Save</button>
        <button type="button" class="btn btn-danger" @click="d_showEditModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showBatchEditModal" @close="d_showBatchEditModal = false" :width="'1500'">
      <h3 slot="header">Batch Edit Modal, {{ 'protocol_username' }} Kullanıcısına Ait Toplam {{d_chemotherapyKeyListForBatchEdit.length}} Protokole İşlem Yapılmakta</h3>
      <div slot="body">
        <chemotherapy-batch-edit :d_addedDrugList="d_addedDrugList"></chemotherapy-batch-edit>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" @click="f_saveBatchEdit()">Save</button>
        <button type="button" class="btn btn-danger" @click="d_showBatchEditModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showPreviewProtocol" @close="d_showPreviewProtocol = false" :width="'1500'">
      <h3 slot="header">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '70')">?</b-badge>
        Protokol şeması
      </h3>
      <div slot="body">
        <protocol-viewer :data="d_previewProtocolData" :indication_approval="{}" :protocol_notes="[]" :insurance_report="{}" :insurance_data="{}" :diagnosis="{}" :patient_data="{}"></protocol-viewer>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showPreviewProtocol = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    :<b-modal title="$t('wdm16.13229')" centered class="modal-success" v-model="d_showNewSchemaGroup" @ok="d_showNewSchemaGroup = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="3">
          <strong>Grup Türü</strong>
        </b-col>
        <b-col cols="9">
          <select v-model="d_newSchemaGroupType" class="form-control">
            <option value="wdm">WDM Şablon Grubu</option>
            <option value="wdm_anatomy">WDM Anatomi Şablon Grubu</option>
          </select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <strong>Grup Başlığı</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newSchemaGroupTitle" v-model="d_newSchemaGroupTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newSchemaGroupTitle !== ''" variant="success" @click="f_saveNewSchemaGroup()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <modal v-if="d_showAddSchemaModal" @close="d_showAddSchemaModal = false" :width="'1000'">
      <h3 slot="header">{{ option_data[data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal :test_mode="true" :external_patient_data="test_wdm_patient" ref="wisdom_data_modal" :data="test_wdm_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
        <!--               <wisdom-data-modal-template-study :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal-template-study>
        -->
      </div>
      <div slot="footer">
        <b-button variant="success" @click="d_showAddSchemaModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showCopySchemaModal" @close="d_showCopySchemaModal = false">
      <h3 slot="header"> Şablon Kopyalama </h3>
      <div slot="body">
        <select v-model="d_copySchemaData.to.group" class="form-control">
          <option v-for="x in d_toSchemaGroupList.list" :value="x.value">{{ x.label }}</option>
        </select>
      </div>
      <div slot="footer">
        <b-button v-if="d_copySchemaData.to.group !== ''" variant="success" @click="f_copySchema()">Kopyala</b-button>
        <b-button variant="danger" @click="d_showCopySchemaModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showEditSchemaModal" @close="d_showEditSchemaModal = false" :width="'1500'">
      <h3 slot="header"> Şablon Düzenleme </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :p_schemaEdit="p_showSchema" :data="p_showSchema.data" :data_type="p_showSchema.data_type" :option_data="p_showSchema.option_data_for_modal" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showEditSchemaModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showManageSchemaGroupModal" @close="d_showManageSchemaGroupModal = false" :width="'1000'">
      <h3 slot="header"> Şablon Grubu Yönetim Ekranı </h3>
      <div slot="body">
        <wdm-schema-group-list :p_schemaGroupManagementList="d_schemaGroupManagementList" :p_schemaGroupKeyList="d_schemaGroupKeyList" :p_schema_group_owner="d_schemaGroupManageProps.schema_group_owner" :p_wdm="d_schemaGroupManageProps.wdm" :p_wdm_owner_type="d_schemaGroupManageProps.wdm_owner_type" :p_username="d_schemaGroupManageProps.username" :p_hospital_group="d_schemaGroupManageProps.hospital_group" :p_hospital_id="d_schemaGroupManageProps.hospital_id" ></wdm-schema-group-list>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="f_saveManageSchemaGroupModal()">{{ $t('wdm16.4061') }}</b-button>
        <b-button variant="danger" @click="d_showManageSchemaGroupModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import ProtocolService from '@/services/public/protocol';
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import GlobalService from '@/services/global';
import moment from 'moment';
import {
  default as ProtocolViewer
} from '@/components/widgets/ProtocolViewer';
import {
  default as ChemotherapyBatchEdit
} from '@/components/widgets/ChemotherapyBatchEdit';
import {
  default as Modal
} from '@/components/widgets/Modal';
import SchemaService from "@/services/schema";
import {
  default as ProtocolNew
} from '@/views/whsmodules/chemotherapy/protocol_new';
import {
  default as WisdomDataModal
} from "@/components/widgets/WisdomDataModal";
import {
  default as WdmSchemaGroupList
} from "@/components/widgets/WdmSchemaGroupList";
// /* global $ */

export default {
  name: 'WdmSchemaList',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang',
    help: 'help'
  }),
  props: {
    d_componentSide: {
      type: String,
      required: true
    },
    d_schemaList: {
      type: Object,
      required: true
    },
    d_selectedSchema: {
      type: Object,
      required: true
    },
    p_showSchema: {
      type: Object,
      required: true
    },
    d_wdmName: {
      type: Object,
      required: true
    },
    selected_group: {
      type: Object,
      required: true
    },
    page_view_settings: {
      type: Object,
      required: true
    },
    groups_list: {
      type: Array,
      required: true
    },
    list_type: {
      type: String,
      required: true
    },
    list_user: {
      type: String,
      required: true
    },
    page_type: {
      type: String,
      required: true
    },
    schema_updated: {
      type: Object,
      required: true
    }
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {
    this.f_calculateDropdownText();
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      search_text: '',
      d_showEditModal: false,
      d_showBatchEditModal: false,
      d_editProtocolData: {},
      mother_children: { 'new_protocol': false, 'add_edit_drug': 0, 'batch_edit': 0 },
      d_editProtocolType: '',
      d_chemotherapyKeyListForBatchEdit: [],
      d_addedDrugList: {},
      d_previewProtocolData: {},
      d_showPreviewProtocol: false,
      protocol_list: [],
      d_dropdownText: '',
      d_newSchemaGroupTitle: '',
      d_showNewSchemaGroup: false,
      d_newWdmSchemaGroup_data: {},
      test_wdm_patient: { "version": 1, "color": 1, "personel_number": "11223344550", "sex": 1, "hospitals": { "MLPCARE": { "ULS": { "data": {}, "webservice": 0 } } }, "id": "example-id", "passport_number": "", "hospital_group": "MLPCARE", "last_lab": { "crea": "1" }, "created_by": "wisdom", "date_of_birth": "1986-01-01", "record_id": "123456", "color_label": "beyaz", "chemo_list": [{ "status": "active", "name": "Example Chemo", "created_at": "2019-09-29T01:39:29.382000", "created_by": "wisdom", "last_status": "no_operation_yet", "started_at": "2019-09-29", "type": "treatment", "id": "example-treatment-id", "treatment_diagnosis": { "translation": { "tr": "C50 - Meme malign neoplazmı" }, "id": "example-diagnosis-id", "value": "C50", "label": "C50 - Meme malign neoplazmı - 2017-02-01" } }], "type": "patient", "mobile_phone": "", "firstname": "Example", "lastname": "Patient", "phone": "", "citizen_type": "citizen", "last_bmi_data": { "weight": "75", "created_at": "2019-09-28T12:05:05.266000", "created_by": "wisdom", "id": "example-bmi-id", "length": "175", "date": "2019-09-28", "adjusted_weight": "73", "type": "patient_follow_physicalexam_bmi", "bodymassindex": "24.49", "ideal_weight": "71" }, "hospital_id": "ULS", "age": 75, "diagnosis": [{ "translation": { "tr": "C50 - Meme malign neoplazmı" }, "id": "example-diagnosis-id", "value": "C50", "label": "C50 - Meme malign neoplazmı - 2017-02-01" }], "last_laboratory": { "2": { "param_group": 3000, "name": { "translation:": { "en": "Crea", "tr": "Crea" }, "value": "Crea", "label": "Crea" }, "val": "0.67", "key": "laboratory_example-lab-id", "param_group_name": { "new_value": 3000, "translation": { "en": "Biochemistry", "tr": "Biyokimya" }, "value": "biochemistry", "label": "Biyokimya" }, "date": "2018-10-05T12:00:00.000000" } }, "created_at": "2018-10-22T15:21:23.183793", "selected": true },
      test_wdm_data: {},
      d_showAddSchemaModal: false,
      option_data: {},
      data_type: '',
      user: {},
      d_newSchemaGroupType: 'wdm',
      d_schemaTypeList: { 'wisdom': ['fullscreen_wisdom_schemas', 'left_wisdom_schemas', 'right_wisdom_schemas'], 'hospital': ['fullscreen_hospital_schemas', 'fullscreen_user_hospital_schemas', 'left_hospital_schemas', 'left_user_hospital_schemas', 'right_hospital_schemas', 'right_user_hospital_schemas'], 'user': ['fullscreen_my_schemas', 'fullscreen_user_schemas', 'left_my_schemas', 'left_user_schemas', 'right_my_schemas', 'right_user_schemas'] },
      d_copySchemaData: {},
      d_showCopySchemaModal: false,
      d_toSchemaGroupList: {},
      d_showEditSchemaModal: false,
      d_showManageSchemaGroupModal: false,
      d_schemaGroupManageProps: {'schema_group_owner': '', 'wdm': '', 'wdm_owner_type': '', 'username': '', 'hospital_group': '', 'hospital_id': ''},
      d_schemaGroupManagementList: { 'wdm': {}, 'wdm_anatomy': {} },
      d_schemaGroupKeyList: {'wdm': '', 'wdm_anatomy': '', 'bucket': ''}
    };
  },
  methods: {
    f_saveManageSchemaGroupModal () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WDM Şablon Grupları Kaydediliyor' } });
      let data = {};
      data.key_list = this.d_schemaGroupKeyList;
      data.doc_data = this.d_schemaGroupManagementList;
      SchemaService.manage_schema_groups(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.d_showManageSchemaGroupModal = false;
        });
    },
    f_showManageSchemaGroupModal (if_check_owner_group, wdm, wdm_owner_type) {
      this.d_schemaGroupManagementList = { 'wdm': {}, 'wdm_anatomy': {} };
      this.d_schemaGroupKeyList = {'wdm': '', 'wdm_anatomy': '', 'bucket': ''};
      this.d_schemaGroupManageProps = {'schema_group_owner': '', 'wdm': '', 'wdm_owner_type': '', 'username': '', 'hospital_group': '', 'hospital_id': ''};
      this.d_schemaGroupManageProps.schema_group_owner = if_check_owner_group;
      this.d_schemaGroupManageProps.wdm = wdm;
      this.d_schemaGroupManageProps.wdm_owner_type = wdm_owner_type;
      if (if_check_owner_group !== 'wisdom') {
        this.d_schemaGroupManageProps.username = this.list_user;
      }
      if (if_check_owner_group === 'hospital') {
        let hospital_detail = {};
        setTimeout(function () {
          hospital_detail = this.f_getHospitalDetailFromUsername(this.list_user);
        }.bind(this), 1000)
        this.d_schemaGroupManageProps.hospital_group = hospital_detail.hospital_group;
        this.d_schemaGroupManageProps.hospital_id = hospital_detail.hospital_id;
      }
      this.d_showManageSchemaGroupModal = true;
    },
    f_showEditSchemaModal (schema_info, schema_group, wdm_or_anatomy) {
      this.f_selectSchema(schema_info, schema_group, wdm_or_anatomy, true);
    },
    f_calculateDropdownText () {
      if (this.list_type === 'fullscreen_wisdom_schemas' || this.list_type === 'left_wisdom_schemas' || this.list_type === 'right_wisdom_schemas') {
        this.d_dropdownText = 'WisdomEra Şablonları';
      } else if (this.list_type === 'fullscreen_my_schemas' || this.list_type === 'left_my_schemas' || this.list_type === 'right_my_schemas') {
        this.d_dropdownText = 'Bana Ait Şablonlar';
      } else if (this.list_type === 'fullscreen_hospital_schemas' || this.list_type === 'left_hospital_schemas' || this.list_type === 'right_hospital_schemas') {
        this.d_dropdownText = 'Hastaneme Ait Şablonlar';
      } else if (this.list_type === 'fullscreen_user_schemas' || this.list_type === 'left_user_schemas' || this.list_type === 'right_user_schemas') {
        this.d_dropdownText = 'Birine Ait Şablonlar';
      } else if (this.list_type === 'fullscreen_user_hospital_schemas' || this.list_type === 'left_user_hospital_schemas' || this.list_type === 'right_user_hospital_schemas') {
        this.d_dropdownText = 'Bir Kullanıcının Hastanesine Ait Şablonlar';
      }
    },
    f_checkEditSchema () {
      return true;
    },
    f_checkCopySchema () {
      if (this.d_componentSide === 'left') {
        if (['right_my_schemas', 'right_user_schemas'].indexOf(this.page_view_settings.side.right.schemalist_type) !== -1) {
          return true;
        } else if (['right_hospital_schemas', 'right_user_hospital_schemas'].indexOf(this.page_view_settings.side.right.schemalist_type) !== -1 && this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1)) {
          return true;
        }
      } else if (this.d_componentSide === 'right') {
        if (['left_my_schemas', 'left_user_schemas'].indexOf(this.page_view_settings.side.left.schemalist_type) !== -1) {
          return true;
        } else if (['left_hospital_schemas', 'left_user_hospital_schemas'].indexOf(this.page_view_settings.side.left.schemalist_type) !== -1 && this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1)) {
          return true;
        }
      }
      return false;
    },
    f_showCopySchemaModal (wdm_or_anatomy, schema_key, wdm, wdm_owner_type, version) {
      // wdm_or_anatomy = wdm_anatomy_schema_group , wdm_schema_group
      this.d_copySchemaData = {};
      let schema_type = wdm_or_anatomy === 'wdm_anatomy_schema_group' ? 'wdm_anatomy' : 'wdm';
      let left_bucket = '';
      let left_type = '';
      let right_bucket = '';
      let right_type = '';
      let from_data = { 'bucket': '', 'key': schema_key, 'version': version, 'wdm_owner_type': wdm_owner_type, 'wdm': wdm };
      let to_data = { 'bucket': '', 'group': '', 'owner_type': '', 'username': '', 'hospital_detail': {'hospital_group': '', 'hospital_id': ''} };
      let key_for_to_group = '';
      for (let schema_list_type in this.d_schemaTypeList) {
        if (this.d_schemaTypeList[schema_list_type].indexOf(this.page_view_settings.side.left.schemalist_type) !== -1) {
          left_type = schema_list_type;
          if (schema_list_type === 'wisdom') {
            left_bucket = 'option';
          } else {
            left_bucket = 'hospital';
          }
        }
        if (this.d_schemaTypeList[schema_list_type].indexOf(this.page_view_settings.side.right.schemalist_type) !== -1) {
          right_type = schema_list_type;
          if (schema_list_type === 'wisdom') {
            right_bucket = 'option';
          } else {
            right_bucket = 'hospital';
          }
        }
      }
      if (this.d_componentSide === 'left') {
        from_data.bucket = left_bucket;
        to_data.bucket = right_bucket;
        to_data.owner_type = right_type;
        if (right_type === 'user') {
          if (this.page_view_settings.side.right.schemalist_type === 'right_my_schemas') {
            key_for_to_group = 'schema_group_user_' + schema_type + '_' + wdm_owner_type + '_' + wdm + '_' + this.user.username;
            to_data.username = this.user.username;
            to_data.hospital_detail = {'hospital_group': this.user.hospital_group, 'hospital_id': this.user.hospital_id};
          } else if (this.page_view_settings.side.right.schemalist_type === 'right_user_schemas') {
            key_for_to_group = 'schema_group_user_' + schema_type + '_' + wdm_owner_type + '_' + wdm + '_' + this.page_view_settings.side.right.user.value;
            to_data.username = this.page_view_settings.side.right.user.value;
            to_data.hospital_detail = this.f_getHospitalDetailFromUsername(this.page_view_settings.side.right.user.value);
          }
        } else if (right_type === 'hospital') {
          if (this.page_view_settings.side.right.schemalist_type === 'right_hospital_schemas') {
            to_data.hospital_detail = {'hospital_group': this.user.hospital_group, 'hospital_id': this.user.hospital_id};
            key_for_to_group = 'schema_group_hospital_' + schema_type + '_' + wdm_owner_type + '_' + wdm + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
          } else if (this.page_view_settings.side.right.schemalist_type === 'right_user_hospital_schemas') {
            to_data.hospital_detail = this.f_getHospitalDetailFromUsername(this.page_view_settings.side.right.user.value);
            key_for_to_group = 'schema_group_hospital_' + schema_type + '_' + wdm_owner_type + '_' + wdm + '_' + to_data.hospital_detail.hospital_group + '_' + to_data.hospital_detail.hospital_id;
          }
        }
      } else if (this.d_componentSide === 'right') {
        from_data.bucket = right_bucket;
        to_data.bucket = left_bucket;
        to_data.owner_type = left_type;
        if (left_type === 'user') {
          if (this.page_view_settings.side.left.schemalist_type === 'left_my_schemas') {
            key_for_to_group = 'schema_group_user_' + schema_type + '_' + wdm_owner_type + '_' + wdm + '_' + this.user.username;
            to_data.username = this.user.username;
            to_data.hospital_detail = {'hospital_group': this.user.hospital_group, 'hospital_id': this.user.hospital_id};
          } else if (this.page_view_settings.side.left.schemalist_type === 'left_user_schemas') {
            key_for_to_group = 'schema_group_user_' + schema_type + '_' + wdm_owner_type + '_' + wdm + '_' + this.page_view_settings.side.left.user.value;
            to_data.username = this.page_view_settings.side.left.user.value;
            to_data.hospital_detail = this.f_getHospitalDetailFromUsername(this.page_view_settings.side.left.user.value);
          }
        } else if (left_type === 'hospital') {
          if (this.page_view_settings.side.left.schemalist_type === 'left_hospital_schemas') {
            to_data.hospital_detail = {'hospital_group': this.user.hospital_group, 'hospital_id': this.user.hospital_id};
            key_for_to_group = 'schema_group_hospital_' + schema_type + '_' + wdm_owner_type + '_' + wdm + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
          } else if (this.page_view_settings.side.left.schemalist_type === 'left_user_hospital_schemas') {
            to_data.hospital_detail = this.f_getHospitalDetailFromUsername(this.page_view_settings.side.left.user.value);
            key_for_to_group = 'schema_group_hospital_' + schema_type + '_' + wdm_owner_type + '_' + wdm + '_' + to_data.hospital_detail.hospital_group + '_' + to_data.hospital_detail.hospital_id;
          }
        }
      }
      this.d_copySchemaData = { 'from': from_data, 'to': to_data, 'schema_type': schema_type };
      GlobalService.get_one_cb_document({ 'key': key_for_to_group, 'bucket': to_data.bucket })
        .then(resp => {
          // console.log('data', data);
          if (resp.data.status === 'success') {
            this.d_toSchemaGroupList = resp.data.result;
            this.d_showCopySchemaModal = true;
          }
        });
    },
    f_copySchema () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'WDM Kopyalaması Tamamlanıyor' } });
      let data = {};
      data.owner_type = this.d_copySchemaData.to.owner_type;
      data.username = this.d_copySchemaData.to.username;
      if (this.d_copySchemaData.to.owner_type === 'hospital') {
        data.key_info = this.d_copySchemaData.from.wdm_owner_type + '_' + this.d_copySchemaData.from.wdm + '_' + this.d_copySchemaData.to.hospital_detail.hospital_group + '_' + this.d_copySchemaData.to.hospital_detail.hospital_id;
      } else {
        data.key_info = this.d_copySchemaData.from.wdm_owner_type + '_' + this.d_copySchemaData.from.wdm;
      }
      data.schema_name = this.d_newWdmAnatomySchemaTitle;
      data.reference_wdm = { 'data_type': this.d_copySchemaData.from.wdm, 'owner_type': this.d_copySchemaData.from.wdm_owner_type, 'version': this.d_copySchemaData.from.version, 'key': 'wdm_' + this.d_copySchemaData.from.wdm_owner_type + '_' + this.d_copySchemaData.from.wdm + '_v' + this.d_copySchemaData.from.version };
      if (this.d_copySchemaData.from.wdm_owner_type === 'hospital') {
        data.reference_wdm.key = 'wdm_' + this.d_copySchemaData.from.wdm_owner_type + '_' + this.d_copySchemaData.from.wdm + '_' + this.d_copySchemaData.to.hospital_detail.hospital_group + '_' + this.d_copySchemaData.to.hospital_detail.hospital_id + '_v' + this.d_copySchemaData.from.version;
      }
      data.schema_from_bucket = this.d_copySchemaData.from.bucket;
      data.schema_from_key = this.d_copySchemaData.from.key;
      data.schema_group = this.d_copySchemaData.to.group;
      data.wdm_version = this.d_copySchemaData.from.version;
      data.schema_type = this.d_copySchemaData.schema_type;

      SchemaService.copy_schema(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            // TODO watch eklenerek listenin yenilenmesi sağlanacak
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
          this.d_showCopySchemaModal = false;
        });
    },
    f_showAddSchemaModal (wdm_type, owner_type, version) {
      let bucket = '';
      let key = '';
      let hospital_detail = {};
      if (['fullscreen_user_schemas', 'left_user_schemas', 'right_user_schemas', ].indexOf(this.list_type) !== -1) {
        hospital_detail = this.f_getHospitalDetailFromUsername(this.list_user);
      } else if (['fullscreen_my_schemas', 'left_my_schemas', 'right_my_schemas'].indexOf(this.list_type) !== -1) {
        hospital_detail = { 'hospital_group': this.user.hospital_group, 'hospital_id': this.user.hospital_id }
      }
      if (owner_type === 'wisdom') {
        bucket = 'option';
        key = 'wdm_wisdom_' + wdm_type + '_v' + version;
      } else if (owner_type === 'hospital') {
        bucket = 'hospital';
        key = 'wdm_hospital_' + wdm_type + '_' + hospital_detail.hospital_group + '_' + hospital_detail.hospital_id + '_' + '_v' + version;
      } else if (owner_type === 'user') {
        bucket = 'hospital';
        key = 'wdm_user_' + wdm_type + '_v' + version;
      }
      this.data_type = wdm_type;
      GlobalService.get_one_cb_document({ 'key': key, 'bucket': bucket })
        .then(resp => {
          // console.log('data', data);
          if (resp.data.status === 'success') {
            this.option_data[this.data_type] = resp.data.result;
            this.test_wdm_data['data'] = {};
            this.test_wdm_data['type'] = this.data_type;
            // this.test_wdm_data['reference_wdm'] = { 'version': reference_wdm.version, 'owner_type': reference_wdm.owner_type };
            for (let i in this.option_data[this.data_type].param_group.param_order) {
              this.test_wdm_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
            }
            this.d_showAddSchemaModal = true;
          }
        });
    },
    f_getHospitalDetailFromUsername: function (username) {
      // console.log(username);
      GlobalService.get_one_cb_document({ 'key': 'user_' + username, 'bucket': 'auth' })
        .then(resp => {
          // console.log('data', resp.data);
          if (resp.data.status === 'success') {
            let result = { 'hospital_group': resp.data.result.hospital_group, 'hospital_id': resp.data.result.hospital_id };
            return result;
          } else {
            return false;
          }
        });
    },
    f_saveNewSchemaGroup: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12728') } });
      let data = {};
      data.owner_type = this.d_newWdmSchemaGroup_data.owner_type;
      data.username = this.d_newWdmSchemaGroup_data.username;
      data.wisdom_data_model = { 'wdm': this.d_newWdmSchemaGroup_data.wdm_type, 'owner_type': this.d_newWdmSchemaGroup_data.wdm_owner_type, 'hospital_group': '', 'hospital_id': '' }
      if (this.d_newWdmSchemaGroup_data.owner_type === 'user') {
        data.key = 'schema_group_user_' + this.d_newSchemaGroupType + '_' + this.d_newWdmSchemaGroup_data.wdm_owner_type + '_' + this.d_newWdmSchemaGroup_data.wdm_type + '_' + this.d_newWdmSchemaGroup_data.username;
      } else if (this.d_newWdmSchemaGroup_data.owner_type === 'wisdom') {
        data.key = 'schema_group_wisdom_' + this.d_newSchemaGroupType + '_' + this.d_newWdmSchemaGroup_data.wdm_owner_type + '_' + this.d_newWdmSchemaGroup_data.wdm_type;
      } else if (this.d_newWdmSchemaGroup_data.owner_type === 'hospital') {
        data.key = 'schema_group_hospital_' + this.d_newSchemaGroupType + '_' + this.d_newWdmSchemaGroup_data.wdm_owner_type + '_' + this.d_newWdmSchemaGroup_data.wdm_type + '_' + this.d_newWdmSchemaGroup_data.hospital_group + '_' + this.d_newWdmSchemaGroup_data.hospital_id;
      }
      if (this.d_newWdmSchemaGroup_data.wdm_owner_type === 'hospital') {
        data.wisdom_data_model.hospital_group = this.d_newWdmSchemaGroup_data.hospital_group;
        data.wisdom_data_model.hospital_id = this.d_newWdmSchemaGroup_data.hospital_id;
      }
      data.group_name = this.d_newSchemaGroupTitle;
      if (this.d_newSchemaGroupType === 'wdm') {
        SchemaService.new_wdm_schema_group(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              // TODO watch eklenerek listenin yenilenmesi sağlanacak
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            } else {
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            }
            this.d_showNewSchemaGroup = false;
          });
      } else if (this.d_newSchemaGroupType === 'wdm_anatomy') {
        SchemaService.new_wdm_anatomy_schema_group(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              // TODO watch eklenerek listenin yenilenmesi sağlanacak
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            } else {
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            }
            this.d_showNewSchemaGroup = false;
          });
      }
    },
    f_checkAddNewSchemaGroup: function (owner_type) {
      if (this.d_schemaTypeList[owner_type].indexOf(this.list_type) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    f_addNewSchemaGroup: function (owner_type, wdm_type, wdm_owner_type) {
      let username = '';
      let hospital_group = '';
      let hospital_id = '';
      let hospital_detail = {};
      let user_me = ['fullscreen_hospital_schemas', 'left_hospital_schemas', 'right_hospital_schemas', 'fullscreen_my_schemas', 'left_my_schemas', 'right_my_schemas'];
      let user_other = ['fullscreen_user_hospital_schemas', 'left_user_hospital_schemas', 'right_user_hospital_schemas', 'fullscreen_user_schemas', 'left_user_schemas', 'right_user_schemas'];
      if (user_other.indexOf(this.list_type) !== -1) {
        username = this.list_user;
        setTimeout(function () {
          hospital_detail = this.f_getHospitalDetailFromUsername(this.list_user);
        }.bind(this), 1000)
        hospital_group = hospital_detail.hospital_group;
        hospital_id = hospital_detail.hospital_id;
      } else if (user_me.indexOf(this.list_type) !== -1) {
        username = this.user.username;
        hospital_group = this.user.hospital_group;
        hospital_id = this.user.hospital_id;
      }
      this.d_newWdmSchemaGroup_data = { 'owner_type': owner_type, 'wdm_type': wdm_type, 'wdm_owner_type': wdm_owner_type, 'username': username, 'hospital_group': hospital_group, 'hospital_id': hospital_id };
      this.d_newSchemaGroupTitle = '';
      this.d_showNewSchemaGroup = true;
    },
    f_selectSchema: function (schema_info, schema_group, wdm_or_anatomy, d_showEditSchemaModal = false) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şablon detayları yükleniyor' } });
      this.d_selectedSchema.label = schema_info.label;
      this.d_selectedSchema.value = schema_info.value;
      this.d_selectedSchema.schema_group = schema_group;
      this.d_selectedSchema.wdm_or_anatomy = wdm_or_anatomy === 'wdm_anatomy_schema_group' ? 'wdm_anatomy' : 'wdm';
      if (this.d_schemaTypeList['wisdom'].indexOf(this.list_type) !== -1) {
        this.d_selectedSchema.bucket = 'option';
        this.d_selectedSchema.schema_owner_type = 'wisdom';
        this.d_selectedSchema.schema_owner = 'wisdom';
      } else if (this.d_schemaTypeList['hospital'].indexOf(this.list_type) !== -1) {
        this.d_selectedSchema.bucket = 'hospital';
        this.d_selectedSchema.schema_owner_type = 'hospital';
        if (this.list_user) {
          this.d_selectedSchema.schema_owner = this.user.hospital_group + '_' + this.user.hospital_id;
        } else {
          let hospital_detail = this.f_getHospitalDetailFromUsername(this.list_user);
          this.d_selectedSchema.schema_owner = hospital_detail.hospital_group + '_' + hospital_detail.hospital_id;
        }
      } else if (this.d_schemaTypeList['user'].indexOf(this.list_type) !== -1) {
        this.d_selectedSchema.bucket = 'hospital';
        this.d_selectedSchema.schema_owner_type = 'user';
        if (this.list_user) {
          this.d_selectedSchema.schema_owner = this.list_user;
        } else {
          this.d_selectedSchema.schema_owner = this.user.username;
        }
      }
      this.p_showSchema.show = false;
      GlobalService.get_one_cb_document({ 'key': this.d_selectedSchema.value, 'bucket': this.d_selectedSchema.bucket })
        .then(resp => {
          // console.log(resp.data.result);
          if (resp.data.status === 'success') {
            this.p_showSchema.value = this.d_selectedSchema.value;
            this.p_showSchema.label = this.d_selectedSchema.label;
            this.p_showSchema.old_schema_group = JSON.parse(JSON.stringify(this.d_selectedSchema.schema_group));
            this.p_showSchema.new_schema_group = JSON.parse(JSON.stringify(this.d_selectedSchema.schema_group));
            this.p_showSchema.schema_owner = this.d_selectedSchema.schema_owner;
            this.p_showSchema.wdm_or_anatomy = this.d_selectedSchema.wdm_or_anatomy;
            this.p_showSchema.schema_owner_type = this.d_selectedSchema.schema_owner_type;
            if (this.p_showSchema.wdm_or_anatomy === 'wdm') {
              this.p_showSchema.data = { 'data': JSON.parse(JSON.stringify(resp.data.result.schema_data)) };
            } else {
              this.p_showSchema.data = { 'data': {'anatomy': JSON.parse(JSON.stringify(resp.data.result.schema_data))} };
            }
            this.p_showSchema.data_type = JSON.parse(JSON.stringify(resp.data.result.reference_wdm.data_type));
            GlobalService.get_one_cb_document({ 'key': resp.data.result.reference_wdm.key, 'bucket': 'option' })
              .then(resp => {
                // console.log(resp.data.result);
                if (resp.data.status === 'success') {
                  this.p_showSchema.option_data = JSON.parse(JSON.stringify(resp.data.result));
                  this.p_showSchema.option_data_for_modal = {};
                  this.p_showSchema.option_data_for_modal[this.p_showSchema.data_type] = JSON.parse(JSON.stringify(resp.data.result));
                  this.p_showSchema.show = true;
                  if (d_showEditSchemaModal) {
                    this.d_showEditSchemaModal = true;
                  }
                }
                this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              });
          }
        });
    },
    f_previewProtocol: function (protocol, list_type) {
      let data = {};
      if (list_type === 'public') {
        data.key = 'option_chemotherapy_' + protocol.value;
        data.bucket = 'option';
      } else {
        data.key = protocol.value;
        data.bucket = 'hospital';
      }
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          // console.log('data', data);
          if (resp.data.status === 'success') {
            this.d_previewProtocolData = resp.data.result;
            this.d_showPreviewProtocol = true;
          }
        });
    },
    f_saveBatchEdit: function () {
      let modal_data = { 'type': 'confirm', 'text': 'Onayladığınız işlem geri alınamaz', 'centered': true, 'title': 'İşlemi onaylıyor musunuz?', 'confirm': false, 'function_name': 'f_saveBatchEditConfirm', 'arguments': [] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_saveBatchEditConfirm: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tedaviler kaydediliyor.' } });
      this.d_showBatchEditModal = false;
      let data = { 'key_list': this.d_chemotherapyKeyListForBatchEdit, 'drug_list': this.d_addedDrugList, 'username': 'this.protocol_username' };
      ProtocolService.batch_edit_protocols(data)
        .then(resp => {
          if (this.schema_updated[this.list_type]) {
            this.schema_updated[this.list_type] = 0;
          } else {
            this.schema_updated[this.list_type] = 1;
          }
        });
    },
    f_showBatchEditModal: function (type = 'one', key = null) {
      this.d_addedDrugList = {};
      this.d_chemotherapyKeyListForBatchEdit = [];
      if (type === 'one' && key) {
        this.d_chemotherapyKeyListForBatchEdit.push(key);
      } else if (type === 'list') {
        for (let i in this.protocol_list) {
          if (!this.protocol_list[i].new && !this.protocol_list[i].delete) {
            if (this.d_chemotherapyKeyListForBatchEdit.indexOf(this.protocol_list[i].value) === -1) {
              this.d_chemotherapyKeyListForBatchEdit.push(this.protocol_list[i].value);
            }
          }
        }
      }
      if (this.d_chemotherapyKeyListForBatchEdit.length > 0) {
        this.d_showBatchEditModal = true;
      } else {
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'En az 1 (bir) adet protokol seçilmesi gerekmekte. Yeni ve Silinecek ibaresine sahip protokoller seçilemez', 'centered': true, 'title': 'Toplu Düzenleme İşlemi' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_showEditModal: function (type, protocol = null) {
      if (type === 'edit' && protocol) {
        GlobalService.get_one_cb_document({ 'key': protocol.value, 'bucket': 'hospital' })
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_editProtocolData = resp.data.result;
              this.d_showEditModal = true;
            }
          });
      } else if (type === 'new') {
        let protocol_data = { 'scope': 'user', 'type': 'protocol', 'protocol_no': '', 'protocol_note': '', 'protocol_order': '', 'protocol_reference': '', 'version': '2.1', 'abbr_name': '', 'owner_type': 'user', 'name': '', 'protocol_group_list': [], 'drugs': [{ 'drug': '', 'dosage': 0, 'delay': 0, 'unit': { 'caption': 'mg/m²', 'value': 'mg_m2' }, 'frequency': { 'caption': '1x1', 'value': '1x1' }, 'type': 'drug', 'dosage_form': { 'caption': 'intra venöz', 'value': '12' }, 'fluid': '', 'fluid_value': 0, 'duration': 0, 'duration_wait': 0, 'repeat': 1, 'drug_note': '', 'drug_mix': [], 'drug_order': 1, 'day': [], 'before': [], 'during': [], 'after': [], 'period': 21, 'is_daily': 0, 'premedications': { 'laboratory': { 'list': [] }, 'note': { 'list': [] }, 'message_to_patient': { 'list': [] } } }] };
        protocol_data.owner = { 'value': 'this.protocol_username', 'label': 'this.protocol_username' };
        this.d_editProtocolData = JSON.parse(JSON.stringify(protocol_data));
        this.d_showEditModal = true;
      }
    },
    f_saveNewProtocols: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tedaviler kaydediliyor.' } });
      let owner_type = '';
      if (this.list_type === 'shared') {
        owner_type = 'hospital';
      } else {
        owner_type = 'user';
      }
      let data = { 'owner_type': owner_type, 'protocol_list': this.protocol_list, 'username': 'this.protocol_username' };
      ProtocolService.save_new_protocols(data)
        .then(resp => {
          if (this.schema_updated[this.list_type]) {
            this.schema_updated[this.list_type] = 0;
          } else {
            this.schema_updated[this.list_type] = 1;
          }
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_delete: function (protocol_ind) {
      if (this.protocol_list[protocol_ind].new) {
        this.protocol_list.splice(protocol_ind, 1);
      } else {
        if (this.protocol_list[protocol_ind].delete) {
          this.protocol_list[protocol_ind].delete = 0;
        } else {
          this.protocol_list[protocol_ind].delete = 1;
        }
      }
      this.$forceUpdate();
    },
    f_addThisTreatment: function (protocol) {
      let other_page_selected_group = '';
      let other_page_list_type = '';
      for (let i in this.selected_group) {
        if (this.list_type !== i && this.selected_group[i]) {
          other_page_selected_group = this.selected_group[i];
          other_page_list_type = i;
        }
      }
      if (other_page_list_type === 'public') {
        alert('public protokollere ekleme yapılamamaktadır.');
        return;
      }
      if (other_page_selected_group) {
        for (let p in this.page_view_settings.list[other_page_list_type]) {
          if (this.page_view_settings.list[other_page_list_type].value === protocol.value) {
            alert('Bu protokol zaten ekli görünmektedir.');
            return;
          }
        }
        let new_protocol = JSON.parse(JSON.stringify(protocol));
        new_protocol['new'] = 1;
        new_protocol['from_list_type'] = this.list_type;
        new_protocol['new_protocol_group'] = other_page_selected_group;
        new_protocol['data']['protocol_group'] = other_page_selected_group.value;
        this.page_view_settings.list[other_page_list_type].push(new_protocol);
        this.$forceUpdate();
      } else {
        alert('Lütfen taşımak istediğiniz listeden bir grup seçimi yapınız.');
      }
    },
    search_in_protocol_name: function (name) {
      if (this.search_text) {
        if (this.search_text.indexOf(',') !== -1) {
          let search_list = this.search_text.split(',');
          let x = 0;
          for (let i in search_list) {
            if (name.toLowerCase().indexOf(search_list[i].toLowerCase()) !== -1) {
              x += 1;
            }
          }
          if (x === search_list.length) {
            return true;
          }
        } else {
          if (name.toLowerCase().indexOf(this.search_text.toLowerCase()) !== -1) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    f_isThereNew: function (pList) {
      let x = 0;
      for (let p in pList) {
        if (pList[p].new) {
          x += 1;
        }
      }
      return x;
    },
    f_isThereDelete: function (pList) {
      let x = 0;
      for (let p in pList) {
        if (pList[p].delete) {
          x += 1;
        }
      }
      return x;
    },
    f_mainParameterSelectionStyle: function () {
      let x = 0;
      if (this.parameter_design_full_screen) {
        if (['wisdom_data_models', 'data_general'].indexOf(this.selected_general_tab) !== -1) {
          x = 90;
        } else { // parameter_list,
          x = 160;
        }
        let height = window.innerHeight - x;
        return 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: hidden; padding: 3px;';
      } else {
        if (['wisdom_data_models', 'data_general'].indexOf(this.selected_general_tab) !== -1) {
          x = 190;
        } else { // parameter_list,
          x = 250;
        }
        let height = window.innerHeight - x;
        return 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: hidden; padding: 3px;';
      }
    }
  },
  watch: {
    'p_showSchema.saved': function () {
      this.d_showEditSchemaModal = false;
    },
    'list_type': function () {
      this.f_calculateDropdownText();
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_saveBatchEditConfirm') {
          if (this.StoreModal.data.confirm) {
            this.f_saveBatchEditConfirm();
          }
        }
      }
    },
    'mother_children.batch_edit': function () {
      this.$forceUpdate();
    },
    'mother_children.add_edit_drug': function () {
      this.$forceUpdate();
    },
    'mother_children.new_protocol': function () {
      this.d_showEditModal = false;
      if (this.schema_updated[this.list_type]) {
        this.schema_updated[this.list_type] = 0;
      } else {
        this.schema_updated[this.list_type] = 1;
      }
    }
  },
  components: {
    Modal,
    vSelect,
    ProtocolNew,
    ProtocolViewer,
    ChemotherapyBatchEdit,
    WisdomDataModal,
    WdmSchemaGroupList
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  }
};

</script>

<style></style>

