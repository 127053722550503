var protocol_group = [
{'label': 'adrenal karsinoma adjuvan', 'value': 'adrenal-carcinoma-adjuvant', 'translation': {'tr': 'adrenal karsinoma adjuvan', 'en': 'adrenal carcinoma adjuvant'}},
{'label': 'adrenal karsinom metastatik veya ileri evre', 'value': 'adrenal-carcinoma-metastatic-or-advanced', 'translation': {'tr': 'adrenal karsinom metastatik veya ileri evre', 'en': 'adrenal carcinoma metastatic or advanced'}},
{'label': 'adrenal karsinom neoadjuvan', 'value': 'adrenal-carcinoma-neoadjuvant', 'translation': {'tr': 'adrenal karsinom neoadjuvan', 'en': 'adrenal carcinoma neoadjuvant'}},
{'label': 'akut miyeloid lösemi', 'value': 'leukemia-acute-myeloid', 'translation': {'tr': 'akut miyeloid lösemi', 'en': 'leukemia acute myeloid'}},
{'label': 'anal adjuvan', 'value': 'anal-adjuvant', 'translation': {'tr': 'anal adjuvan', 'en': 'anal adjuvant'}},
{'label': 'anal definitif kemoradyoterapi', 'value': 'anal-definitive-chemoradiotherapy', 'translation': {'tr': 'anal definitif kemoradyoterapi', 'en': 'anal definitive chemoradiotherapy'}},
{'label': 'anal metastatik veya ileri evre', 'value': 'anal-metastatic-or-advanced', 'translation': {'tr': 'anal metastatik veya ileri evre', 'en': 'anal metastatic or advanced'}},
{'label': 'anal neoadjuvan', 'value': 'anal-neoadjuvant', 'translation': {'tr': 'anal neoadjuvan', 'en': 'anal neoadjuvant'}},
{'label': 'baş ve boyun definitif kemoradyoterapi', 'value': 'head-and-neck-definitive-chemoradiotherapy', 'translation': {'tr': 'baş ve boyun definitif kemoradyoterapi', 'en': 'head and neck definitive chemoradiotherapy'}},
{'label': 'baş ve boyun adjuvan', 'value': 'head-and-neck-adjuvant', 'translation': {'tr': 'baş ve boyun adjuvan', 'en': 'head and neck adjuvant'}},
{'label': 'baş ve boyun metastatik veya ileri evre', 'value': 'head-and-neck-metastatic-or-advanced', 'translation': {'tr': 'baş ve boyun metastatik veya ileri evre', 'en': 'head and neck metastatic or advanced'}},
{'label': 'beyin adjuvan', 'value': 'brain-adjuvant', 'translation': {'tr': 'beyin adjuvan', 'en': 'brain adjuvant'}},
{'label': 'beyin metastatik veya ileri evre', 'value': 'brain-metastatic-or-advanced', 'translation': {'tr': 'beyin metastatik veya ileri evre', 'en': 'brain metastatic or advanced'}},
{'label': 'bifosfonatlar', 'value': 'bisphosphonates', 'translation': {'tr': 'bifosfonatlar', 'en': 'bisphosphonates'}},
{'label': 'diğer', 'value': 'others', 'translation': {'tr': 'diğer', 'en': 'others'}},
{'label': 'endometriyal adjuvan', 'value': 'endometrial-adjuvant', 'translation': {'tr': 'endometriyal adjuvan', 'en': 'endometrial adjuvant'}},
{'label': 'endometrial metastatik veya ileri evre', 'value': 'endometrial-metastatic-or-advanced', 'translation': {'tr': 'endometrial metastatik veya ileri evre', 'en': 'endometrial metastatic or advanced'}},
{'label': 'endometrial neoadjuvan', 'value': 'endometrial-neoadjuvant', 'translation': {'tr': 'endometrial neoadjuvan', 'en': 'endometrial neoadjuvant'}},
{'label': 'feokromasitoma adjuvan', 'value': 'pheochromocytoma-adjuvant', 'translation': {'tr': 'feokromasitoma adjuvan', 'en': 'pheochromocytoma adjuvant'}},
{'label': 'feokromositoma metastatik veya ileri evre', 'value': 'pheochromocytoma-metastatic-or-advanced', 'translation': {'tr': 'feokromositoma metastatik veya ileri evre', 'en': 'pheochromocytoma metastatic or advanced'}},
{'label': 'gastrik (mide) adjuvan', 'value': 'gastric-adjuvant', 'translation': {'tr': 'gastrik (mide) adjuvan', 'en': 'gastric adjuvant'}},
{'label': 'gastrik (mide) metastatik veya ileri evre', 'value': 'gastric-metastatic-or-advanced', 'translation': {'tr': 'gastrik (mide) metastatik veya ileri evre', 'en': 'gastric metastatic or advanced'}},
{'label': 'gastrik (mide) neoadjuvan', 'value': 'gastric-neoadjuvant', 'translation': {'tr': 'gastrik (mide) neoadjuvan', 'en': 'gastric neoadjuvant'}},
{'label': 'gastrointestinal stromal tümör', 'value': 'gastrointestinal-stromal-tumor', 'translation': {'tr': 'gastrointestinal stromal tümör', 'en': 'gastrointestinal stromal tumor'}},
{'label': 'gestasyonel trofoblastik non metastatik veya düşük riskli metastatik', 'value': 'gestational-trophoblastic-non-metastatic-or-low-risk-metastatic', 'translation': {'tr': 'gestasyonel trofoblastik non metastatik veya düşük riskli metastatik', 'en': 'gestational trophoblastic non metastatic or low risk metastatic'}},
{'label': 'gestasyonel trofoblastik metastatik yüksek risk', 'value': 'gestational-trophoblastic-metastatic-high-risk', 'translation': {'tr': 'gestasyonel trofoblastik metastatik yüksek risk', 'en': 'gestational trophoblastic metastatic high risk'}},
{'label': 'hepatoselüler karsinom metastatik veya ileri evre', 'value': 'hepatocelular-carcinoma-metastatic-or-advanced', 'translation': {'tr': 'hepatoselüler karsinom metastatik veya ileri evre', 'en': 'hepatocelular carcinoma metastatic or advanced'}},
{'label': 'hodgkin yüksek doz hazırlama rejimi (transplantasyon öncesi)', 'value': 'hodgkin-high-dose-conditioning-regimen-(pretransplantation)', 'translation': {'tr': 'hodgkin yüksek doz hazırlama rejimi (transplantasyon öncesi)', 'en': 'hodgkin high dose conditioning regimen (pretransplantation)'}},
{'label': 'hodgkin kurtarma indüksiyon rejimi', 'value': 'hodgkin-salvage-induction-regimen', 'translation': {'tr': 'hodgkin kurtarma indüksiyon rejimi', 'en': 'hodgkin salvage induction regimen'}},
{'label': 'hodgkin palyatif nüks refraktör', 'value': 'hodgkin-palliative-relapsed-refractory', 'translation': {'tr': 'hodgkin palyatif nüks refraktör', 'en': 'hodgkin palliative relapsed refractory'}},
{'label': 'hodgkin lenfoma', 'value': 'lymphoma-hodgkin', 'translation': {'tr': 'hodgkin lenfoma', 'en': 'lymphoma hodgkin'}},
{'label': 'non hodgkin lenfoma', 'value': 'lymphoma-non-hodgkin', 'translation': {'tr': 'non hodgkin lenfoma', 'en': 'lymphoma non hodgkin'}},
{'label': 'kaposi', 'value': 'kaposi', 'translation': {'tr': 'kaposi', 'en': 'kaposi'}},
{'label': 'kronik miyeloid lösemi hızlandırılmış faz ve blast krizi', 'value': 'chronic-myelogenous-leukemia-accelerated-phase-and-blast-crisis', 'translation': {'tr': 'kronik miyeloid lösemi hızlandırılmış faz ve blast krizi', 'en': 'chronic myelogenous leukemia accelerated phase and blast crisis'}},
{'label': 'kolorektal adjuvan', 'value': 'colorectal-adjuvant', 'translation': {'tr': 'kolorektal adjuvan', 'en': 'colorectal adjuvant'}},
{'label': 'kolorektal adjuvan kemoradyoterapi', 'value': 'colorectal-adjuvant-chemoradiotherapy', 'translation': {'tr': 'kolorektal adjuvan kemoradyoterapi', 'en': 'colorectal adjuvant chemoradiotherapy'}},
{'label': 'kolorektal metastatik veya ileri evre', 'value': 'colorectal-metastatic-or-advanced', 'translation': {'tr': 'kolorektal metastatik veya ileri evre', 'en': 'colorectal metastatic or advanced'}},
{'label': 'kolorektal neoadjuvan kemoradyoterapi', 'value': 'colorectal-neoadjuvant-chemoradiotherapy', 'translation': {'tr': 'kolorektal neoadjuvan kemoradyoterapi', 'en': 'colorectal neoadjuvant chemoradiotherapy'}},
{'label': 'kolorektal neoadjuvan kemoterapi', 'value': 'colorectal-neoadjuvant-chemotherapy', 'translation': {'tr': 'kolorektal neoadjuvan kemoterapi', 'en': 'colorectal neoadjuvant chemotherapy'}},
{'label': 'kranial sinir sistemi lenfoması', 'value': 'cranial-nerve-system-lymphoma', 'translation': {'tr': 'kranial sinir sistemi lenfoması', 'en': 'cranial nerve system lymphoma'}},
{'label': 'kronik lenfositik lösemi', 'value': 'leukemia-chronic-lymphocytic', 'translation': {'tr': 'kronik lenfositik lösemi', 'en': 'leukemia chronic lymphocytic'}},
{'label': 'kronik miyelojen lösemi kronik faz', 'value': 'chronic-myelogenous-leukemia-chronic-phase', 'translation': {'tr': 'kronik miyelojen lösemi kronik faz', 'en': 'chronic myelogenous leukemia chronic phase'}},
{'label': 'kronik miyelojen lösemi', 'value': 'leukemia-chronic-myelogenous', 'translation': {'tr': 'kronik miyelojen lösemi', 'en': 'leukemia chronic myelogenous'}},
{'label': 'küçük hücreli dışı akciğer adjuvan', 'value': 'non-small-cell-lung-adjuvant', 'translation': {'tr': 'küçük hücreli dışı akciğer adjuvan', 'en': 'non small cell lung adjuvant'}},
{'label': 'küçük hücreli dışı akciğer indüksiyon konsolidasyonu', 'value': 'non-small-cell-lung-induction-consolidation', 'translation': {'tr': 'küçük hücreli dışı akciğer indüksiyon konsolidasyonu', 'en': 'non small cell lung induction consolidation'}},
{'label': 'küçük hücreli dışı akciğer metastatik veya ileri evre', 'value': 'non-small-cell-lung-metastatic-or-advanced', 'translation': {'tr': 'küçük hücreli dışı akciğer metastatik veya ileri evre', 'en': 'non small cell lung metastatic or advanced'}},
{'label': 'küçük hücreli dışı akciğer neoadjuvan', 'value': 'non-small-cell-lung-neoadjuvant', 'translation': {'tr': 'küçük hücreli dışı akciğer neoadjuvan', 'en': 'non small cell lung neoadjuvant'}},
{'label': 'küçük hücreli akciğer sınırlı evre', 'value': 'small-cell-lung-limited', 'translation': {'tr': 'küçük hücreli akciğer sınırlı evre', 'en': 'small cell lung limited'}},
{'label': 'küçük hücreli akciğer metastatik yayılım yapmış', 'value': 'small-cell-lung-extensive-metastatic', 'translation': {'tr': 'küçük hücreli akciğer metastatik yayılım yapmış', 'en': 'small cell lung extensive metastatic'}},
{'label': 'lenfoma ve lösemi', 'value': 'lymphoma-and-leukemia', 'translation': {'tr': 'lenfoma ve lösemi', 'en': 'lymphoma and leukemia'}},
{'label': 'lösemi akut lenfoblastik yetişkin', 'value': 'leukemia-acute-lymphoblastic-adult', 'translation': {'tr': 'lösemi akut lenfoblastik yetişkin', 'en': 'leukemia acute lymphoblastic adult'}},
{'label': 'melanom adjuvan', 'value': 'melanoma-adjuvant', 'translation': {'tr': 'melanom adjuvan', 'en': 'melanoma adjuvant'}},
{'label': 'melanoma metastatik veya ileri evre', 'value': 'melanoma-metastatic-or-advanced', 'translation': {'tr': 'melanoma metastatik veya ileri evre', 'en': 'melanoma metastatic or advanced'}},
{'label': 'meme adjuvan kemoterapi', 'value': 'breast-adjuvant-chemotherapy', 'translation': {'tr': 'meme adjuvan kemoterapi', 'en': 'breast adjuvant chemotherapy'}},
{'label': 'meme adjuvan hormonal terapi', 'value': 'breast-adjuvant-hormonal-therapy', 'translation': {'tr': 'meme adjuvan hormonal terapi', 'en': 'breast adjuvant hormonal therapy'}},
{'label': 'meme metastatik veya ileri evre kemoterapi', 'value': 'breast-metastatic-or-advanced-chemotherapy', 'translation': {'tr': 'meme metastatik veya ileri evre kemoterapi', 'en': 'breast metastatic or advanced chemotherapy'}},
{'label': 'meme metastatik veya ileri evre hormonal', 'value': 'breast-metastatic-advanced-hormonal', 'translation': {'tr': 'meme metastatik veya ileri evre hormonal', 'en': 'breast metastatic advanced hormonal'}},
{'label': 'meme neoadjuvan kemoterapi', 'value': 'breast-neoadjuvant-chemotherapy', 'translation': {'tr': 'meme neoadjuvan kemoterapi', 'en': 'breast neoadjuvant chemotherapy'}},
{'label': 'mesane adjuvan', 'value': 'bladder-adjuvant', 'translation': {'tr': 'mesane adjuvan', 'en': 'bladder adjuvant'}},
{'label': 'mesane metastatik veya ileri evre', 'value': 'bladder-metastatic-or-advanced', 'translation': {'tr': 'mesane metastatik veya ileri evre', 'en': 'bladder metastatic or advanced'}},
{'label': 'mesane neoadjuvan', 'value': 'bladder-neoadjuvant', 'translation': {'tr': 'mesane neoadjuvan', 'en': 'bladder neoadjuvant'}},
{'label': 'mezotelyoma', 'value': 'mesothelioma', 'translation': {'tr': 'mezotelyoma', 'en': 'mesothelioma'}},
{'label': 'nöroendokrin tümör', 'value': 'neuroendocrine-tumor', 'translation': {'tr': 'nöroendokrin tümör', 'en': 'neuroendocrine tumor'}},
{'label': 'non hodgkin burkitt lenfoma', 'value': 'non-hodgkin-burkitt-s-lymphoma', 'translation': {'tr': 'non hodgkin burkitt lenfoma', 'en': 'non hodgkin burkitt s lymphoma'}},
{'label': 'non hodgkin kranial sinir sistemi lenfoması', 'value': 'non-hodgkin-cns-lymphoma', 'translation': {'tr': 'non hodgkin kranial sinir sistemi lenfoması', 'en': 'non hodgkin cns lymphoma'}},
{'label': 'non hodgkin diffüz büyük b hücresi indolent foliküler', 'value': 'non-hodgkin-diffuse-large-b-cell-indolent-follicular', 'translation': {'tr': 'non hodgkin diffüz büyük b hücresi indolent foliküler', 'en': 'non hodgkin diffuse large b cell indolent follicular'}},
{'label': 'non hodgkin indolent foliküler', 'value': 'non-hodgkin-indolent-follicular', 'translation': {'tr': 'non hodgkin indolent foliküler', 'en': 'non hodgkin indolent follicular'}},
{'label': 'non hodgkin indolent foliküler küçük lenfositik mantle hücreli', 'value': 'non-hodgkin-indolent-follicular-small-lymphocytic-mantle-cell', 'translation': {'tr': 'non hodgkin indolent foliküler küçük lenfositik mantle hücreli', 'en': 'non hodgkin indolent follicular small lymphocytic mantle cell'}},
{'label': 'non hodgkin mantle hücreli', 'value': 'non-hodgkin-mantle-cell', 'translation': {'tr': 'non hodgkin mantle hücreli', 'en': 'non hodgkin mantle cell'}},
{'label': 'non hodgkin primer refrakter relaps difüz büyük b hücreli', 'value': 'non-hodgkin-primary-refractory-relapsed-diffuse-large-b-cell', 'translation': {'tr': 'non hodgkin primer refrakter relaps difüz büyük b hücreli', 'en': 'non hodgkin primary refractory relapsed diffuse large b cell'}},
{'label': 'non hodgkin küçük lenfositik', 'value': 'non-hodgkin-small-lymphocytic', 'translation': {'tr': 'non hodgkin küçük lenfositik', 'en': 'non hodgkin small lymphocytic'}},
{'label': 'non hodgkin küçük lenfositik mantle hücreli', 'value': 'non-hodgkin-small-lymphocytic-mantle-cell', 'translation': {'tr': 'non hodgkin küçük lenfositik mantle hücreli', 'en': 'non hodgkin small lymphocytic mantle cell'}},
{'label': 'non hodgkin t hücreli', 'value': 'non-hodgkin-t-cell', 'translation': {'tr': 'non hodgkin t hücreli', 'en': 'non hodgkin t cell'}},
{'label': 'non hodgkin t hücreli refraktör relaps', 'value': 'non-hodgkin-t-cell-refractory-relapsed', 'translation': {'tr': 'non hodgkin t hücreli refraktör relaps', 'en': 'non hodgkin t cell refractory relapsed'}},
{'label': 'over adjuvan', 'value': 'ovarian-adjuvant', 'translation': {'tr': 'over adjuvan', 'en': 'ovarian adjuvant'}},
{'label': 'over metastatik veya ileri evre', 'value': 'ovarian-metastatic-or-advanced', 'translation': {'tr': 'over metastatik veya ileri evre', 'en': 'ovarian metastatic or advanced'}},
{'label': 'over neoadjuvan', 'value': 'ovarian-neoadjuvant', 'translation': {'tr': 'over neoadjuvan', 'en': 'ovarian neoadjuvant'}},
{'label': 'özofagus adjuvan', 'value': 'esophageal-adjuvant', 'translation': {'tr': 'özofagus adjuvan', 'en': 'esophageal adjuvant'}},
{'label': 'özofagus metastatik veya ileri evre', 'value': 'esophageal-metastatic-or-advanced', 'translation': {'tr': 'özofagus metastatik veya ileri evre', 'en': 'esophageal metastatic or advanced'}},
{'label': 'özofagus neoadjuvan', 'value': 'esophageal-neoadjuvant', 'translation': {'tr': 'özofagus neoadjuvan', 'en': 'esophageal neoadjuvant'}},
{'label': 'primer bilinmeyen metastatik veya ileri evre', 'value': 'unknown-primary-metastatic-or-advanced', 'translation': {'tr': 'primer bilinmeyen metastatik veya ileri evre', 'en': 'unknown primary metastatic or advanced'}},
{'label': 'pankreatik adjuvan', 'value': 'pancreatic-adjuvant', 'translation': {'tr': 'pankreatik adjuvan', 'en': 'pancreatic adjuvant'}},
{'label': 'pankreatik endokrin tümör adjuvan', 'value': 'pancreatic-endocrine-tumor-adjuvant', 'translation': {'tr': 'pankreatik endokrin tümör adjuvan', 'en': 'pancreatic endocrine tumor adjuvant'}},
{'label': 'pankreatik endokrin tümör metastatik veya ileri evre', 'value': 'pancreatic-endocrine-tumor-metastatic-or-advanced', 'translation': {'tr': 'pankreatik endokrin tümör metastatik veya ileri evre', 'en': 'pancreatic endocrine tumor metastatic or advanced'}},
{'label': 'pankreatik endokrin tümör neoadjuvan', 'value': 'pancreatic-endocrine-tumor-neoadjuvant', 'translation': {'tr': 'pankreatik endokrin tümör neoadjuvan', 'en': 'pancreatic endocrine tumor neoadjuvant'}},
{'label': 'pankreatik metastatik veya ileri evre', 'value': 'pancreatic-metastatic-or-advanced', 'translation': {'tr': 'pankreatik metastatik veya ileri evre', 'en': 'pancreatic metastatic or advanced'}},
{'label': 'pankreatik neoadjuvan', 'value': 'pancreatic-neoadjuvant', 'translation': {'tr': 'pankreatik neoadjuvan', 'en': 'pancreatic neoadjuvant'}},
{'label': 'prostat metastatik veya ileri evre rekürren androjen yoksunluğu', 'value': 'prostate-metastatic-or-advanced-recurrent-androjen-deprivation', 'translation': {'tr': 'prostat metastatik veya ileri evre rekürren androjen yoksunluğu', 'en': 'prostate metastatic or advanced recurrent androjen deprivation'}},
{'label': 'prostat adjuvan', 'value': 'prostate-adjuvant', 'translation': {'tr': 'prostat adjuvan', 'en': 'prostate adjuvant'}},
{'label': 'prostat metastatik veya ileri evre rekürren kemoterapi immünoterapi', 'value': 'prostate-metastatic-or-advanced-recurrent-chemotherapy-immunotherapy', 'translation': {'tr': 'prostat metastatik veya ileri evre rekürren kemoterapi immünoterapi', 'en': 'prostate metastatic or advanced recurrent chemotherapy immunotherapy'}},
{'label': 'prostat neoadjuvan', 'value': 'prostate-neoadjuvant', 'translation': {'tr': 'prostat neoadjuvan', 'en': 'prostate neoadjuvant'}},
{'label': 'refrakter philadelphia kromozom pozitif kml', 'value': 'refractory-philadelphia-chromosome-positive-cml', 'translation': {'tr': 'refrakter philadelphia kromozom pozitif kml', 'en': 'refractory philadelphia chromosome positive cml'}},
{'label': 'renal hücreli karsinom metastatik veya ileri evre', 'value': 'renal-cell-carcinoma-metastatic-or-advanced', 'translation': {'tr': 'renal hücreli karsinom metastatik veya ileri evre', 'en': 'renal cell carcinoma metastatic or advanced'}},
{'label': 'safra kesesi ve kolanjiyokarsinoma adjuvan', 'value': 'gallbladder-and-cholangiocarcinoma-adjuvant', 'translation': {'tr': 'safra kesesi ve kolanjiyokarsinoma adjuvan', 'en': 'gallbladder and cholangiocarcinoma adjuvant'}},
{'label': 'safra kesesi ve kolanjiyokarsinoma metastatik veya ileri evre', 'value': 'gallbladder-and-cholangiocarcinoma-metastatic-or-advanced', 'translation': {'tr': 'safra kesesi ve kolanjiyokarsinoma metastatik veya ileri evre', 'en': 'gallbladder and cholangiocarcinoma metastatic or advanced'}},
{'label': 'safra kesesi ve kolanjiokarsinom neoadjuvan', 'value': 'gallbladder-and-cholangiocarcinoma-neoadjuvant', 'translation': {'tr': 'safra kesesi ve kolanjiokarsinom neoadjuvan', 'en': 'gallbladder and cholangiocarcinoma neoadjuvant'}},
{'label': 'sarkom adjuvan', 'value': 'sarcoma-adjuvant', 'translation': {'tr': 'sarkom adjuvan', 'en': 'sarcoma adjuvant'}},
{'label': 'sarkom metastatik veya ileri evre', 'value': 'sarcoma-metastatic-or-advanced', 'translation': {'tr': 'sarkom metastatik veya ileri evre', 'en': 'sarcoma metastatic or advanced'}},
{'label': 'servikal adjuvan', 'value': 'cervical-adjuvant', 'translation': {'tr': 'servikal adjuvan', 'en': 'cervical adjuvant'}},
{'label': 'servikal definitif kemoradyoterapi', 'value': 'cervical-definitive-chemoradiotherapy', 'translation': {'tr': 'servikal definitif kemoradyoterapi', 'en': 'cervical definitive chemoradiotherapy'}},
{'label': 'servikal metastatik veya ileri evre', 'value': 'cervical-metastatic-or-advanced', 'translation': {'tr': 'servikal metastatik veya ileri evre', 'en': 'cervical metastatic or advanced'}},
{'label': 'servikal neoadjuvan', 'value': 'cervical-neoadjuvant', 'translation': {'tr': 'servikal neoadjuvan', 'en': 'cervical neoadjuvant'}},
{'label': 'testis', 'value': 'testicle', 'translation': {'tr': 'testis', 'en': 'testicle'}},
{'label': 'testis adjuvan', 'value': 'testicular-adjuvant', 'translation': {'tr': 'testis adjuvan', 'en': 'testicular adjuvant'}},
{'label': 'testis metastatik veya ileri evre', 'value': 'testicular-metastatic-or-advanced', 'translation': {'tr': 'testis metastatik veya ileri evre', 'en': 'testicular metastatic or advanced'}},
{'label': 'timik metastatik veya ileri evre', 'value': 'thymic-malignancy-metastatic-or-advanced', 'translation': {'tr': 'timik metastatik veya ileri evre', 'en': 'thymic malignancy metastatic or advanced'}},
{'label': 'tiroid metastatik veya ileri evre', 'value': 'thyroid-metastatic-or-advanced', 'translation': {'tr': 'tiroid metastatik veya ileri evre', 'en': 'thyroid metastatic or advanced'}},
{'label': 'tüylü hücreli lösemi', 'value': 'leukemia-hairy-cell', 'translation': {'tr': 'tüylü hücreli lösemi', 'en': 'leukemia hairy cell'}},
{'label': 'vajinal adjuvan', 'value': 'vaginal-adjuvant', 'translation': {'tr': 'vajinal adjuvan', 'en': 'vaginal adjuvant'}},
{'label': 'vaginal metastatik veya ileri evre', 'value': 'vaginal-metastatic-or-advanced', 'translation': {'tr': 'vaginal metastatik veya ileri evre', 'en': 'vaginal metastatic or advanced'}},
{'label': 'vajinal neoadjuvan', 'value': 'vaginal-neoadjuvant', 'translation': {'tr': 'vajinal neoadjuvan', 'en': 'vaginal neoadjuvant'}}];

export { protocol_group as options };
